import { KEY_HEIGHT_PX, PAGE_TOP_MARGIN, WIDTH_TABLET, WIDTH_XL } from "./constants";

export const slugify = str =>
    str.toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');

// Quaver triplets adding up can cause the following note to get a beat value of 0.99999999999993 or similar,
// which leads to 'almost-duplicates' in the playback map (e.g. 1.9999999999993 and 2), so round it here.
//
// This won't resolve every issue - e.g. 1/16 triplets.. vs quavers...
export const round = num => {
    let res = num
    if (Math.abs(res - Math.round(res)) < 0.00000001) {
        res = Math.round(res)
    }
    return res
}

export function wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

// Retry a function until it returns true
export const retryWithDelay = async (fn, retries = 3, interval = 50, finalErr = 'retryWithDelay failed') => {
    const res = await fn()
    if (retries <= 0) {
        return Promise.reject(finalErr)
    }
    if (!res) {
        await wait(interval)
        return retryWithDelay(fn, (retries - 1), interval, finalErr)
    }
}

export function isTouchDevice() {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
}

// Tablet and smaller        -> No margin
// From XL screen and larger -> 25% margin
// In between tablet and XL  -> amount of margin pro-rated from 0% to 25%
export function displayPageWidth(availableWidth) {
    const marginMult = Math.max(Math.min((availableWidth - WIDTH_TABLET) / (WIDTH_XL - WIDTH_TABLET), 1), 0)
    return availableWidth * (1 - (0.25 * marginMult))
}

export function pageTopMargin() {
    const marginMult = Math.max(Math.min((window.innerWidth - WIDTH_TABLET) / (WIDTH_XL - WIDTH_TABLET), 1), 0)
    return PAGE_TOP_MARGIN * marginMult
}
export function pageBottomMargin(showPiano) {
    // If tablet (use width to determine) then use top margin (else 0)
    // PLUS add enough for piano keyboard if it is showing.
    return ((window.innerWidth > WIDTH_TABLET) ? PAGE_TOP_MARGIN : 0)
        + (showPiano ? KEY_HEIGHT_PX : 0)
}
