/** @jsxImportSource @emotion/react */

import { useDispatch, useAppState, VIEW_MODE } from '../StateProvider'
import { ReactComponent as IconMusicPage } from '../../icons/documentMusicNotes.svg'
import { ReactComponent as IconMidiWaterfall } from '../../icons/midiWaterfall.svg'
import { ReactComponent as IconPiano } from '../../icons/piano.svg'
import { BaseButton, smallButtonCss } from './Button'


export function ViewModeButton({ ...props }) {
    const { viewMode } = useAppState()
    const dispatch = useDispatch()

    let icon = <IconPiano />
    if (viewMode === VIEW_MODE.PDF_PIANO) {
        icon = <IconMidiWaterfall />
    }
    else if (viewMode === VIEW_MODE.MIDI_WATERFALL) {
        icon = <IconMusicPage />
    }

    const handleClick = () => {
        dispatch({ type: 'NEXT_VIEW_MODE' })
    }

    return (
        <BaseButton
            id='view-mode-button'
            onClick={handleClick}
            additionalCss={smallButtonCss}
            {...props}
        >
            {icon}
        </BaseButton>
    )
}

