
// Replace TickSource.forEachTickBetween method
// The version in Tone was causing a performance problem in Wait mode.

export function applyToneFix(audioPlayer) {

    audioPlayer.getTone().Transport._clock._tickSource.forEachTickBetween = forEachTickBetween
    console.log(`Player: forEachTickBetween replaced`)

    function forEachTickBetween(startTime, endTime, callback) {
        let lastStateEvent = this._state.get(startTime);
        this._state.forEachBetween(startTime, endTime, (event) => {
            if (lastStateEvent && lastStateEvent.state === "started" && event.state !== "started") {
                this.forEachTickBetween(Math.max(lastStateEvent.time, startTime), event.time - this.sampleTime, callback);
            }
            lastStateEvent = event;
        });
        let error = null;

        if (lastStateEvent && lastStateEvent.state === "started") {
            const firstTick = Math.ceil(this.getTicksAtTime(startTime))
            const targetTick = Math.floor(this.getTicksAtTime(endTime))
            for (let tick = firstTick; tick <= targetTick; tick++) {
                try {
                    const tickTime = this.getTimeOfTick(tick)
                    callback(tickTime, tick);
                } catch (e) {
                    error = e;
                    break;
                }
            }
        }
        if (error) {
            throw error;
        }
        return this;
    }

}