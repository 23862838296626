/** @jsxImportSource @emotion/react */

import React, { useRef, useEffect, useCallback } from 'react'
import { css } from '@emotion/react'

import { IconPlay, IconSpinner, IconStop } from '../Icons'
import { ReactComponent as IconPiano } from '../../icons/piano.svg'
import { useAppState, useDispatch } from '../StateProvider'
import { BaseButton } from './Button'


export function PlayButton(props) {
    const { playbackState, playbackManager } = useAppState()
    const dispatch = useDispatch()
    const playButtonElt = useRef(null)
    const isEnabled = playbackState && playbackState !== 'LOADING'

    // Set focus to the play button when it is newly enabled (which should be whenever
    // a piece is newly loaded.)
    useEffect(() => {
        if (isEnabled) {
            playButtonElt.current.focus()
        }
    }, [isEnabled])

    const handlePlayClick = useCallback(
        async event => {
            if (playbackManager.status === 'STOPPED') {
                dispatch({ type: 'SET_SCORE_POPUP_OPEN', payload: false })
                playbackManager.resetScore()
                playbackManager.start()
            }
            else {
                console.log('stop!')
                playbackManager.stop()
            }
        },
        [playbackManager, dispatch])

    return (
        <BaseButton
            name='play'
            id='play-button'
            data-cy='play-button'
            ref={playButtonElt}
            onClick={handlePlayClick}
            isPressed={['PLAYING', 'AWAITING_USER_KEYS'].includes(playbackState)}
            disabled={!isEnabled}
            additionalCss={css`
                position: relative;
                color: hsla(120deg, 0%, 20%, 0.7);
    `}
            {...props}
        >
            {playbackState === 'LOADING'
                ? <IconSpinner />
                : playbackState === 'AWAITING_USER_KEYS'
                    ? <AwaitingUserKeys />
                    : playbackState === 'PLAYING'
                        ? <IconStop id="stop-icon" />
                        : <IconPlay id="play-icon" />
            }
        </BaseButton>
    )
}

function AwaitingUserKeys() {
    // Icon to show when in Wait mode, awaiting user to play the right keys
    // (In fact it's two icons overlaid)
    return (
        <React.Fragment>
            <IconPlay style={{ opacity: '35%' }} />
            <div css={css`
                position: absolute;
                bottom: 3px;
                right: 5px;
                animation: blinking 3s linear infinite;
                @keyframes blinking { 80% { opacity: 100; } 90% { opacity: 0; }}
            `}>
                <IconPiano width={22} />
            </div>
        </React.Fragment>
    )
}
