/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

import { baseControlCss, SMALL_CONTROL_H } from './ControlBase'
import { BUTTON_BORDER_CSS } from './Button'


export const groupLeftCss = css`
    height: ${SMALL_CONTROL_H};
    margin-right: 0px; border-top-right-radius: 0; border-bottom-right-radius: 0;
    ${BUTTON_BORDER_CSS}
    `
export const groupRightCss = css`
    height: ${SMALL_CONTROL_H};
    margin-left: 0px; border-top-left-radius: 0; border-bottom-left-radius: 0;
    ${BUTTON_BORDER_CSS}
    border-left: none;
    `
export const groupMiddleCss = css`
    height: ${SMALL_CONTROL_H};
    margin-left: 0px; margin-right: 0px;
    border-top-left-radius: 0; border-bottom-left-radius: 0;
    border-top-right-radius: 0; border-bottom-right-radius: 0;
    ${BUTTON_BORDER_CSS}
    border-left: none;
    `


export function ControlGroup({ additionalCss, children, ...props }) {
    return (
        <div
            css={css`
        ${baseControlCss}
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        background-color: hsla(0deg, 0%, 0%, 0);
        border: none;
        color: hsla(120deg, 0%, 20%, 0.7);
        ${additionalCss}
`}
            {...props}
        >
            {children}
        </div>
    )
}

