/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

const X_OFFSET = -10
const Y_BASELINE = -60    // pos of line for most labels
const Y_NOTE_BUFFER = 60  // but ensure space between label and note (i.e. shift label on high notes)

const NoteLabel = ({
    pitch,
    additionalCss,
    children,
    ...etc
}) => {

    const x = pitch.xPosOnPage + X_OFFSET
    const y = Math.min(pitch.staveBar.pos.y + Y_BASELINE, pitch.yPosOnPage - Y_NOTE_BUFFER)

    return (
        <div
            css={css`
                position: absolute;
                display: block;
                left: ${x}px;
                top: ${y}px;
                height: 1.4em;
                width: 1.4em;
                user-select: none;
                background: hsla(200deg, 50%, 99%, 0.8);
                /* border: 1px solid hsla(0, 0%, 20%, 0.2); */
                /* backdrop-filter: blur(1px); */
                mix-blend-mode: multiply;
                ${additionalCss}
            `}
            {...etc}
        >
            {children}
        </div>
    )
}


export default NoteLabel
