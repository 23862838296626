/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

import { Piano } from '../react-piano/index';
import { useAppState, useNotesPlaying, VIEW_MODE, PLAY_MODE } from './StateProvider';
import { Ab, As, Bb, Cs, Db, Ds, Eb, Fs, Gb, Gs } from './NoteName';
import { useEffect, useRef, useState } from 'react';
import { KEY_FROM_MIDI, KEY_TO_MIDI, WHITE_KEY_WIDTH_PX, KEY_HEIGHT_PX } from '../logic/constants';
import MidiWaterfall from './MidiWaterfall';


export function numWhiteKeysBetween(fromMidi, toMidi) {
    return Math.ceil((toMidi - fromMidi + 1) / 12 * 7)
}
const whiteKeysCount = numWhiteKeysBetween(KEY_FROM_MIDI, KEY_TO_MIDI)

const PianoKeyboard = ({ availableWidth, availableHeight }) => {
    // The on-screen piano keyboard

    const [firstRender, setFirstRender] = useState(true)
    const { playbackManager, viewMode, playMode, playStaves } = useAppState()
    const { playerKeys, userKeys, hintKeys } = useNotesPlaying()

    let highlightKeys = []
    let highlightKeysLH = []
    let lowlightKeys = []

    switch (playMode) {
        case PLAY_MODE.NORMAL:
            // highlight the keys that the audio player is playing
            highlightKeys = playStaves[0] ? playerKeys.filter(k => k.isCorrect && (k.hand === 'RH' || !k.hand)) : []
            highlightKeysLH = playStaves[1] ? playerKeys.filter(k => k.isCorrect && k.hand === 'LH') : []
            break

        case PLAY_MODE.CONSTANT:
        case PLAY_MODE.WAIT:
        default:
            // highlight the correct keys that the USER is playing (set from playerManager)
            highlightKeys = userKeys.filter(k => k.isCorrect && (k.hand === 'RH' || !k.hand))
            highlightKeysLH = userKeys.filter(k => k.isCorrect && k.hand === 'LH')
    }

    // any other keys being played by user show in grey ('lowlight')
    lowlightKeys = userKeys.filter(p => !(highlightKeys.includes(p) || highlightKeysLH.includes(p)))

    // TODO (maybe): Nice if it labelled the black notes appropriately to the current key
    // ..would need all that music-structures stuff from brian-app...
    const labelsByPitchClass = {
        C: 'C',
        Db: <span>{Cs} {Db}</span>,
        D: 'D',
        Eb: <span>{Ds} {Eb}</span>,
        E: 'E',
        F: 'F',
        Gb: <span>{Fs} {Gb}</span>,
        G: 'G',
        Ab: <span>{Gs} {Ab}</span>,
        A: 'A',
        Bb: <span>{As} {Bb}</span>,
        B: 'B',
    }
    const labelsByMidi = new Map()
    labelsByMidi.set(60, <div><div css={css`line-height: 40%;`}>mid.</div> <span css={css`font-size: 1.5em; font-weight: 600;`}>C</span></div>)

    // NB. react-piano has playNote and onPlayNoteInput props
    // - playNote is called when activeNotes are set, which is not what we want (cos our own audio player is scheduling all the audio)
    // - onPlayNoteInput seems to do what we want: i.e. it is only called when a key is actually clicked by user
    const playNoteNow = (midiNumber) => {
        playbackManager.audioPlayer.playNoteNow({ pitchSpnOrMidi: midiNumber })
    }

    // scroll container to put middle C in centre - but only on the first render (and when availableWidth is valid).
    const pianoContainerRef = useRef(null)
    useEffect(() => {
        if (firstRender && !isNaN(availableWidth)) {
            pianoContainerRef.current.scrollLeft = (numWhiteKeysBetween(KEY_FROM_MIDI, 60.5)) * WHITE_KEY_WIDTH_PX - availableWidth / 2
            setFirstRender(false)
        }
    }, [availableWidth, firstRender])

    const width = Math.max(WHITE_KEY_WIDTH_PX * whiteKeysCount, availableWidth)

    return (
        <div
            id="piano-and-waterfall-container"
            ref={pianoContainerRef}
            css={css`
        position: fixed;
        bottom: 0;
        min-height: ${viewMode === VIEW_MODE.MIDI_WATERFALL ? "100%" : `auto`};
        width: ${availableWidth}px;
        overflow-x: auto;
        box-shadow: 0px -1px 5px rgba(0,0,0,.2);
        z-index: 10;
        `}
        >
            {viewMode === VIEW_MODE.MIDI_WATERFALL &&
                <MidiWaterfall
                    noteRange={{ first: KEY_FROM_MIDI, last: KEY_TO_MIDI }}
                    width={width}
                    height={availableHeight - KEY_HEIGHT_PX}
                    bottom={KEY_HEIGHT_PX}
                    whiteKeyWidth={WHITE_KEY_WIDTH_PX}
                />
            }

            <div
                id="piano-container"
                data-cy="piano-container"
                css={css`
                ${viewMode === VIEW_MODE.MIDI_WATERFALL && `position: absolute; bottom: 0; width: ${width}px;`}
                `}
            >
                <Piano
                    noteRange={{ first: KEY_FROM_MIDI, last: KEY_TO_MIDI }}
                    width={WHITE_KEY_WIDTH_PX * whiteKeysCount}
                    keyWidthToHeight={WHITE_KEY_WIDTH_PX / KEY_HEIGHT_PX}
                    labelsByPitchClass={labelsByPitchClass}
                    labelsByMidi={labelsByMidi}
                    activeNotes={highlightKeys.map(k => k.pitchMidi)}
                    activeNotesLH={highlightKeysLH.map(k => k.pitchMidi)}
                    activeNotesLowlight={lowlightKeys.map(k => k.pitchMidi)}
                    hintNotes={hintKeys.filter(k => k.hand === 'RH' || !k.hand).map(k => k.pitchMidi)}
                    hintNotesLH={hintKeys.filter(k => k.hand === 'LH').map(k => k.pitchMidi)}
                    onPlayNoteInput={playNoteNow}
                />
            </div>

        </div >
    )
}

export default PianoKeyboard
