/** @jsxImportSource @emotion/react */

import { useCallback, useEffect, useRef } from 'react';
import { css } from '@emotion/react'
import { PLAYBACK_CONTROLS_HEIGHT } from '../logic/constants'
import { ReactComponent as IconConnect } from '../icons/connect.svg'
import { ReactComponent as IconConnectSuccess } from '../icons/connect-success.svg'
import { Button, PressButton } from './controls/Button'
import { PLAY_MODE, useAppState, useDispatch } from './StateProvider'
import { IconClose, IconRefresh, IconSpinner } from './Icons';
import { MIDI_ACCESS_STATUS } from '../playback/MidiDeviceManager';
import { RowOfButtons } from './controls/ControlBase';



export const MidiConfig = () => {
    const { playbackManager, isMidiConfigOpen, playMode } = useAppState()
    const dispatch = useDispatch()
    const midiDeviceManager = playbackManager.physicalKeyboard
    const setMidiConfigOpen = isOpen => dispatch({ type: 'SET_MIDI_CONFIG_OPEN', payload: isOpen })

    const { ref } = useComponentVisible(isMidiConfigOpen, setMidiConfigOpen)

    const MidiDeviceList = () => {

        const setMidiInputDevice = name => {
            if (midiDeviceManager.isMidiConnected && name === midiDeviceManager.inputDevice.name) {
                midiDeviceManager.disconnectAll()
                dispatch({ type: 'SET_MIDI_INPUT_DEVICE', payload: '' })
                dispatch({ type: 'SET_MIDI_CONNECTED', payload: false })
                if ([PLAY_MODE.CONSTANT, PLAY_MODE.WAIT].includes(playMode)) {
                    playbackManager.stop()
                }
            }
            else {
                midiDeviceManager.connectInputDevice(name)
                dispatch({ type: 'SET_MIDI_INPUT_DEVICE', payload: name })
                dispatch({ type: 'SET_MIDI_CONNECTED', payload: true })
            }
            setMidiConfigOpen(false)
        }

        return (
            <div>
                <h3>Choose a MIDI input device:</h3>
                <ul css={css`list-style-type: none; padding-inline-start: 0;`}>
                    {midiDeviceManager.midiInputs.map(midiDevice => (
                        <MidiDevice
                            key={midiDevice.name}
                            name={midiDevice.name}
                            isPressed={midiDeviceManager.isMidiConnected && midiDevice.name === midiDeviceManager.inputDevice.name}
                            onClick={() => setMidiInputDevice(midiDevice.name)}
                        />
                    ))}
                </ul>
            </div>
        )
    }

    const MidiDevice = (props) => {
        return (
            <li css={css`text-align: left;`}>
                <PressButton
                    additionalCss={css`
                    width: unset;
                    max-width: unset;
                    padding-left: 0.5em;
                    padding-right: 0.5em;
                    `}
                    {...props}
                >
                    {props.isPressed ? < IconConnectSuccess width={20} /> : < IconConnect width={20} />}
                    &nbsp;{props.name}
                </PressButton>
            </li>
        )
    }

    const Waiting = () =>
        <div id='midi-access-wait-spinner'
            css={css`
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding-top: 3em;
            color: hsla(0deg, 0%, 40%, 0.5);
            `}
        >
            <IconSpinner />
        </div>


    const Failure = () =>
        <div id='midi-access-failure'>
            <h3>Failed to access any MIDI input devices.</h3>
            <p>Make sure that WebMIDI is enabled in your browser, that all MIDI cables are connected properly, and that your input device is switched on.</p>
        </div>


    const RefreshButton = () => {
        return (
            <Button
                onClick={midiDeviceManager.refresh}
                disabled={midiDeviceManager.midiAccessStatus === MIDI_ACCESS_STATUS.AWAITING}
                additionalCss={css`padding-left: 10px; padding-right: 10px;`}
            >
                <IconRefresh size={20} />&nbsp;Refresh
            </Button>
        )
    }

    const CloseButton = () => {
        return (
            <Button onClick={() => setMidiConfigOpen(false)}
                additionalCss={css`padding-left: 10px; padding-right: 10px;`}
            >
                <IconClose size={25} />Close
            </Button>
        )
    }

    let Cpt
    switch (midiDeviceManager.midiAccessStatus) {
        case MIDI_ACCESS_STATUS.AWAITING:
            Cpt = Waiting
            break
        case MIDI_ACCESS_STATUS.SUCCESS:
            Cpt = () => <MidiDeviceList midiDeviceManager={midiDeviceManager} />
            break
        case MIDI_ACCESS_STATUS.FAILURE:
        default:
            Cpt = Failure
    }


    return (
        <div ref={ref}>
            {isMidiConfigOpen &&
                <div css={css`
            position: fixed;
            top: ${PLAYBACK_CONTROLS_HEIGHT}px;
            right: 50px;
            min-height: 200px;
            width: 500px;
            padding: 1em;
            background-color: hsla(0deg, 0%, 93%, 1.0);
            box-shadow: -1px 1px 8px rgba(0,0,0,.16);
            font-size: 1rem;
            color: hsla(0deg, 0%, 20%, 1);
            z-index: 100;
            border: 1px solid hsl(0deg, 0%, 83%);
            text-align: left;
            `}>
                    <Cpt />
                    <RowOfButtons>
                        <RefreshButton />
                        <CloseButton />
                    </RowOfButtons>
                </div>}
        </div>
    )
}


// utility to enable 'click outside to close'
// based on https://stackoverflow.com/a/45323523/11571725
export function useComponentVisible(isMidiConfigOpen, setVisibleCallback) {
    const ref = useRef(null)

    const handleClickOutside = useCallback(event => {
        if (ref.current && !ref.current.contains(event.target) && isMidiConfigOpen) {
            // User might click the 'midi config' button on the toolbar, which should close the window if open.
            // But that counts as a 'click outside' so the window would be closed, then the toolbar button click
            // would toggle it - i.e. open it again. So to avoid that, here we fractionally delay the closing of
            // the window: i.e. the toolbar button toggle will go first (closing the window), then this will
            // happen (closing the window - not _toggling_ it).
            setTimeout(() => setVisibleCallback(false), 1)
        }
    }, [isMidiConfigOpen, setVisibleCallback])

    useEffect(
        () => {
            document.addEventListener('click', handleClickOutside, true)
            return () => { document.removeEventListener('click', handleClickOutside, true) }
        }, [handleClickOutside])

    return { ref }
}
