import React from "react"
import LoadingIndicator from "./LoadingIndicator"
import { ConnectAudio } from "./ConnectAudio"
import PianoKeyboard from "./PianoKeyboard"
import { useAppState } from "./StateProvider"
import { PlaybackControls } from "./controls/PlaybackControls"
import { ScorePopup } from "./ScorePopup"


const Layout = ({ availableWidth, availableHeight }) => {

    const { showPiano, playbackState } = useAppState()

    return (
        <React.Fragment>
            <PlaybackControls />
            {playbackState === 'LOADING' ? <LoadingIndicator /> : null}
            {showPiano &&
                <PianoKeyboard
                    availableWidth={availableWidth}
                    availableHeight={availableHeight}
                />
            }
            <ScorePopup />
            <ConnectAudio />
        </React.Fragment>
    )
}

export default Layout
