
// 'Plain' means using e.g. the letter b for a flat sign, hash for sharp
export const plainToGlyph = str => {

    if (typeof str !== 'string') return str;

    let text = str
        .replace(/\b([A-G]?)bb\b/g, '$1𝄫')  // double flats
        .replace(/\b([A-G]?)b\b/g, '$1♭')   // flats
        .replace(/\bb([1-9])/g, '♭$1')      // number like 'b3'

        .replace(/(\W|^)([A-G])=(\W|$)/g, '$1$2♮$3')   // = -> natural - nb. require preceded by A-G, else can't use 'x = y'
        .replace(/(\W|^)=([1-9])/g, '$1♮$2')

        .replace(/(\W|^)([A-G])n(\W|$)/g, '$1$2♮$3')   // n -> natural
        .replace(/(\W|^)n([1-9])/g, '$1♮$2')

        .replace(/(\W|^)([A-G]?)##(\W|$)/g, '$1$2𝄪$3')  // double sharps
        .replace(/(\W|^)([A-G]?)#(\W|$)/g, '$1$2♯$3')  // nb. \b won't match next to a hash..
        .replace(/(\W|^)#([1-9])/g, '$1♯$2')           // ..hence (\W) instead
        ;

    return text
}
