
// A PlaybackMap (PlaybackMap.js) is made up of many PlaybackPoints.
//
// A PlaybackPoint represents a beat position in the repeat-expanded-piece (so e.g. each beat in a written
// repeated section will be represented by *two* playback points). There are playback points for all notated
// beats, plus some barlines (where necessary for correct cursor movement - e.g. end of a system). Each 
// note-playback-point contains a list of all the pitches (across all staves and all voices) which start on that
// beat, and also contains information about where the note is placed on the PDF page.

class PlaybackPointBase {
    constructor(beat, noteX, bar, type) {
        this.beat = beat          // qn in performance (i.e. accounting for repeats/jumps)
        this.noteX = noteX
        this.bar = bar
        this.type = type
        this.repeatNum = null
        this.dcOrDs = null

        // point position tick/time - possibly different notation/performance values if (e.g.) swing
        this.tickNotation = null      // tick calculated from music XML
        this.tickPerformance = null   // tick where note/chord is performed (from MIDI)
        this.timeNotationMs = null
        this.timePerformanceMs = null

        this.expectedPitchesRH = []    // array of Notes
        this.expectedPitchesLH = []
        this.expectedPitches = []

        this.continuingPitchesRH = []  // array of ints (midi pitches)
        this.continuingPitchesLH = []
        this.continuingPitches = []

        this.pressedKeys = []
    }

    get tick() {
        return this.tickPerformance
    }

    get timeMs() {
        return this.timePerformanceMs
    }

    clone() {
        const newPoint = new PlaybackPoint(this.beat, this.noteX, this.bar, this.type)
        return newPoint
    }

    str() {
        return this.type
    }

    get hasStruckPitches() {
        return false
    }
}

export class PlaybackPoint extends PlaybackPointBase {

    constructor(beat, qnIndex, noteX, bar) {
        super(beat, noteX, bar, 'note')
        this.qnIndex = qnIndex    // qn 'on paper'
        this.pitches = []
        this.midiEvents = []
        this.overlayX = null
        this.overlayW = null
    }

    addPitches(pitches) {
        this.pitches = this.pitches.concat(pitches)
    }

    clone() {
        const newPoint = new PlaybackPoint(this.beat, this.qnIndex, this.noteX, this.bar)
        newPoint.addPitches(this.pitches)
        return newPoint
    }

    str() {
        return this.pitches.map(p => p.pitchClass).join(', ')
    }

    get pitchesRH() {
        return this.pitches.filter(n => n.staveBar.id === 'P1/1')  // ???phys prob should get id smarter; not hard-code 'P1/1'
    }

    get pitchesLH() {
        return this.pitches.filter(n => n.staveBar.id === 'P1/2')
    }

    get struckPitches() {
        // returns 'new struck' pitches - i.e. excludes pitches which are just a tie continuation
        return this.pitches.filter(p => !p.tiedTo)
    }

    get hasStruckPitches() {
        return this.struckPitches.length > 0
    }

}

export class PlaybackPointBarline extends PlaybackPointBase {
    constructor(beat, noteX, bar) {
        super(beat, noteX, bar, 'barline')
    }
}

export class PlaybackPointCountIn extends PlaybackPointBase {
    constructor(beat, noteX, bar) {
        super(beat, noteX, bar, 'countin')
    }
}
