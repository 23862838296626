/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

const PageFromPdf = ({
    imageUrl,
    pageWidth,
    makeSemiTransparent,
    title,
    pageNum
}) => {


    return (
        <img
            src={imageUrl}
            alt={`'${title}' page ${pageNum} (music notation)`}
            css={css`
            position: absolute;
            display: block;
            top: 0px;
            left: 0px;
            width: ${pageWidth}px;
            ${makeSemiTransparent ? 'opacity: 30%;' : ''}
            ${makeSemiTransparent ? 'filter: invert(1) sepia(1) hue-rotate(30deg) saturate(400%) invert(1);' : ''}
            z-index: 5;
            user-drag: none;  
            user-select: none;
            -moz-user-select: none;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            -ms-user-select: none;
`}
            data-cy={`pdf-page-${pageNum}`}
        />
    )
}

export default PageFromPdf