/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'


// Invisible layer which contains the SVG component where the notes can be highlighted.
// Uses mix-blend-mode lighten so that the 'ink' of the PDF can be coloured.
// src/playback/NotationHighlighter.js does the highlighting.
// There should be one of these layers per page of the sheet music PDF.

const NotationHighlightLayer = ({
    pageNum,
    pageWidth,
    pageHeight
}) => {

    return (
        <div
            id={`notation-highlight-layer`}
            css={css`
                position: absolute;
                display: block;
                top: 0px;
                left: 0px;
                width: ${pageWidth}px;
                height: ${pageHeight}px;
                color: hsla(40deg, 50%, 50%, 1);
                z-index: 5;
                mix-blend-mode: lighten;  // SVG elements in this layer will only colour the black parts of the PDF
            `}
        >
            <svg id={`svg-notation-highlight-${pageNum}`}
                height="100%"
                width="100%"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                {/* <text x="50" y="50" fill="#26528f">HighlightNotesLayer</text> */}
                {/* <circle cx="500" cy="220" r="100" fill="blue" /> */}
            </svg>
        </div>
    )
}

export default NotationHighlightLayer

