/** @jsxImportSource @emotion/react */

import React, { useState } from 'react'
import { css } from '@emotion/react'

import Page from './Page'
import VexFlowNotation from './VexFlowNotation'
import PageFromPdf from './PageFromPdf'
import PageOneHeader from './PageOneHeader'
import CursorLayer from './CursorLayer'
import { useAppState } from './StateProvider';
import DebugPlaybackMap from './debug/PlaybackMap'

import { PLAYBACK_CONTROLS_HEIGHT } from '../logic/constants'
import SelectionOverlay from './SelectionOverlay'
import { pageBottomMargin, pageTopMargin } from '../logic/util'
import NoteNamesOverlay from './NoteNamesOverlay'
import NotationHighlightLayer from './NotationHighlightLayer'


const PieceAsPdf = ({
    piece,
    pdfImages,
    loadingStatus,
    availableWidth,
    availableHeight,
    showVexflowOrImage,
    showBeats
}) => {

    const { showNoteNames, showPiano } = useAppState()
    const [draggedFrom, setDraggedFrom] = useState(null)
    const [touchHandleDrag, setTouchHandleDrag] = useState(null)
    const [possBeats, setPossBeats] = useState([[-1], [-1]])

    const originalPageWidth = piece?.pageWidth || pdfImages[0]?.width || 1200
    const originalPageHeight = piece?.pageHeight || pdfImages[0]?.height || 1697

    return (
        <div
            id='pdf-pages-container'
            css={css`margin: ${PLAYBACK_CONTROLS_HEIGHT + pageTopMargin()}px auto ${pageBottomMargin(showPiano)}px;`}
        >
            {pdfImages.map((image, pageIndex) => (
                <Page
                    key={pageIndex}
                    originalPageWidth={originalPageWidth}
                    originalPageHeight={originalPageHeight}
                    availableWidth={availableWidth}
                >
                    {['vexflow', 'both'].includes(showVexflowOrImage) &&
                        <React.Fragment>
                            {pageIndex === 0 && (
                                <PageOneHeader
                                    title={piece.title}
                                    pageWidth={originalPageWidth}
                                />
                            )}

                            <VexFlowNotation
                                id={`${piece.id}-page-${pageIndex + 1}`}
                                bars={piece.pages[pageIndex]}
                                pageWidth={originalPageWidth}
                                pageHeight={originalPageHeight}
                                availableWidth={availableWidth}
                            />
                        </React.Fragment>
                    }
                    {['pdf', 'both'].includes(showVexflowOrImage) &&
                        <PageFromPdf
                            imageUrl={image.src}
                            pageWidth={originalPageWidth}
                            makeSemiTransparent={showVexflowOrImage === 'both'}
                            title={piece?.title}
                            pageNum={pageIndex + 1}
                        />
                    }
                    {showBeats &&
                        <DebugPlaybackMap
                            pageNum={pageIndex}
                            playbackMap={piece.playbackMap}
                        />
                    }
                    {loadingStatus.status === 'READY' &&
                        <SelectionOverlay
                            pageNum={pageIndex}
                            playbackMap={piece.playbackMap}
                            pageWidth={originalPageWidth}
                            pageHeight={originalPageHeight}
                            draggedFrom={draggedFrom}
                            setDraggedFrom={setDraggedFrom}
                            touchHandleDrag={touchHandleDrag}
                            setTouchHandleDrag={setTouchHandleDrag}
                            possBeats={possBeats}
                            setPossBeats={setPossBeats}
                        />
                    }
                    {loadingStatus.status === 'READY' && showNoteNames &&
                        <NoteNamesOverlay
                            pageNum={pageIndex}
                            playbackMap={piece.playbackMap}
                            pageWidth={originalPageWidth}
                            pageHeight={originalPageHeight}
                        />
                    }
                    <NotationHighlightLayer
                        pageNum={pageIndex}
                        pageWidth={originalPageWidth}
                        pageHeight={originalPageHeight}
                    />
                    <CursorLayer
                        pageNum={pageIndex}
                        pageWidth={originalPageWidth}
                        pageHeight={originalPageHeight}
                    />
                </Page >
            ))}
        </div>
    )
}

export default PieceAsPdf
