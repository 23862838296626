import throttle from 'lodash/throttle'
import { BLACK_KEY_WIDTH_PX, KEY_FROM_MIDI, KEY_TO_MIDI, WHITE_KEY_WIDTH_PX } from '../logic/constants'
import { retryWithDelay } from '../logic/util'
import { TimeSig } from '../music-structures/TimeSig'
import colors from '../styles/colors.scss'
import ScrollBooster from './MidiWaterfallScroller'

const {
    midiWaterfallColorRight,
    midiWaterfallColorBlackNoteRight,
    midiWaterfallColorLeft,
    midiWaterfallColorBlackNoteLeft,
    midiWaterfallColorDisabled,
    midiWaterfallColorBlackNoteDisabled,
    midiWaterfallColorLabel,
} = colors


const NOTE_MARGIN_X = 2
const NOTE_MIN_GAP_Y = 4
const NOTE_BLACK_WIDTH_RATIO = 0.75
const NOTE_CORNER_RADIUS = 4
const PX_PER_QN = 80
const END_EXTRA_BEATS = 12
const SCROLL_FRICTION = 0.03
const SCROLL_VELOCITY_THRESHOLD_WHEN_STOPPED = 0.3  // scroll inertia has a long 'tail'
const SCROLL_VELOCITY_THRESHOLD_WHEN_PLAYING = 10   // ..but not when playing

const WHITE_NOTE_INDICES = [0, 2, 4, 5, 7, 9, 11]
const SVGNS = "http://www.w3.org/2000/svg"


const pitchPositions = Object.values({  // ??? should get these from the actual keys? or share props with react-piano..?
    C: 0,
    Db: 0.61,
    D: 1,
    Eb: 1.74,
    E: 2,
    F: 3,
    Gb: 3.58,
    G: 4,
    Ab: 4.7,
    A: 5,
    Bb: 5.77,
    B: 6,
})


export class MidiWaterfall {

    constructor(playbackManager, svgContainerId) {
        this.loaded = false
        this.isReady = false
        this.playbackManager = playbackManager
        this.containerId = svgContainerId
        this.viewportHeight = 0
        this.noteRange = { first: KEY_FROM_MIDI, last: KEY_TO_MIDI }
        this.whiteKeyWidth = WHITE_KEY_WIDTH_PX
        this.blackKeyWidth = this.whiteKeyWidth * NOTE_BLACK_WIDTH_RATIO
        this.firstXpos = MidiWaterfall.getKeyPosXRelativeToC0(this.noteRange.first)
        this.containerElement = null
        this.pianoRollSvg = this.createPianoRollSvg()
        this.scroller = null
        this.scrollerOnUpdateCallback = this.scrollerOnUpdateCallback.bind(this)
        this.initPianoRoll = this.initPianoRoll.bind(this)
        this.setLoop = throttle(this._setLoop, 200)

        this.currentComposition = null
        this.timelineItems = []
        this.loopFromMarker = null
        this.loopToMarker = null
    }

    setDimensions() {
        const newHeight = this.containerElement?.offsetHeight
        const heightDiff = newHeight - this.viewportHeight
        if (!!heightDiff && this.isReady && this.pianoRollSvg && ['STOPPED', 'AWAITING_USER_KEYS'].includes(this.playbackManager.status)) {
            // adjust vertical position so that 'playhead' (i.e. bottom) stays at the same point in the piece
            this.setPosY(this.posY - heightDiff)
        }
        this.viewportHeight = newHeight
        this.scroller?.updateMetrics()
    }

    setPiece(playbackMap, midiComposition, pieceFromXml) {
        this.clearPianoRoll()
        this.midiComposition = midiComposition
        this.piece = pieceFromXml
        this.playbackMap = playbackMap
        this.ppq = this.midiComposition.ppq
        this.ticksPerPx = this.ppq / PX_PER_QN
        this.countInTicks = -this.playbackMap.points[0].tick
        const extraTicksAtEnd = END_EXTRA_BEATS * this.ppq
        this.durationTicks = this.midiComposition.durationTicks + this.countInTicks + extraTicksAtEnd
        this.pianoRollHeightPx = this.durationTicks / this.ticksPerPx

        // array of all elements to be shown - i.e. notes (all tracks) and barlines
        this.timelineItems = []
        this.midiComposition.tracks.forEach((t, i) => {
            this.timelineItems = this.timelineItems.concat(
                t.events.map(e => new PianoRollNote(this, e.ticks, e.durationTicks, e.midi, e.pitchClass, t.isLH ? 1 : 0))
            )
        })
        this.calcBarLines()
        this.drawAllOnPianoRoll()
        this.loaded = true
    }

    // Add bar lines (i.e. horizontal lines).
    calcBarLines() {
        /* 
        todo ???: Currently this doesn't take account of time sig changes! Problem is that the data from midi (i.e.
        audioPlayer.currentComposition) has the ticks of time sigs wrong (e.g. it sometimes puts a later time sig 
        at a fractional measure position), and the xml data (this.piece) is the *notation* (i.e. doesn't have repeats etc.
        expanded), and the playbackMap is 'beat-based' (we need bar-based here).
    
        Best solution IMO is to make a PlaybackMap class which has both beat- and bar-based maps, then could easily run
        this off the bar-map.
        */
        const firstBar = this.piece.bars[0]
        const firstTimeSig = new TimeSig(firstBar.timeSig.split('/'), this.ppq)

        let tick = 0
        tick += firstBar.lengthInBeats * this.ppq          // First bar is special case as it might be partial (i.e a pick-up bar)
        this.timelineItems.push(new PianoRollBarLine(this, tick))

        while (tick < this.durationTicks) {
            tick += firstTimeSig.barLengthTicks
            this.timelineItems.push(new PianoRollBarLine(this, tick))
        }

        // Also do barlines for the count-in. This is a little fiddly as tick 0 is not necessarily a barline (i.e. if there
        // is a pick-up)
        tick = 0
        if (firstBar.lengthInBeats < firstTimeSig.barLengthQn) {
            // The first bar is not as long as it 'should be' according to the time sig - i.e. it's a pickup bar.
            // So work back from the end of the bar.
            tick = this.getTickAtBeat(firstBar.lengthInBeats) - firstTimeSig.barLengthTicks
        }
        while (tick > -this.countInTicks) {
            this.timelineItems.push(new PianoRollBarLine(this, tick))
            tick -= firstTimeSig.barLengthTicks
        }
    }

    async reset(tick) {
        await retryWithDelay(this.initPianoRoll, 9, 50, `initPianoRoll failed (probably container svg wasn't mounted).`)
        await retryWithDelay(() => this.loaded, 30, 500, 'loading of piece timed out in MidiWaterfall')
        await retryWithDelay(() => !!this.scroller, 30, 500, 'loading of scroller timed out in MidiWaterfall')
        this.setScrollFriction()
        this.isReady = true
        this.goToTick(tick)
    }

    setPlayhead(tick) {
        this.reset(tick)
    }

    suspend() {
        this.stopScroll()
        this.containerElement = null
        this.isReady = false
        if (this.playbackManager.status === 'PLAYING') {
            // audio playback is paused during a scroll so make sure it's resumed
            this.playbackManager.audioPlayer.play()
        }
    }

    initPianoRoll() {
        this.containerElement = document.getElementById(this.containerId)
        if (!!this.containerElement) {
            this.setDimensions()
            this.containerElement.appendChild(this.pianoRollSvg)
            this.initScroller()
            return true
        }
    }

    initScroller() {
        this.scroller && this.scroller.destroy()
        this.scroller = new ScrollBooster({
            viewport: this.containerElement,
            scrollMode: 'native',
            direction: 'vertical',
            friction: SCROLL_FRICTION,
            velocityToRegardAsStopped: SCROLL_VELOCITY_THRESHOLD_WHEN_STOPPED,
            onUpdate: this.scrollerOnUpdateCallback,
            emulateScroll: true,
        })
    }

    async prepForPlay() {
        this.stopScroll()
        this.setScrollFriction(true)
    }

    resetAfterPlay() {
        this.setScrollFriction(false)
    }

    stopScroll() {
        this.scroller && this.scroller.stopImmediate()
    }

    setScrollFriction(isPlaying) {
        const _isPlaying = isPlaying === undefined ? this.playbackManager.status === 'PLAYING' : isPlaying
        // in fact, this just adjusts the scroll 'tail': if scroll when playing then the tail should be short
        this.scroller.props.velocityToRegardAsStopped = _isPlaying
            ? SCROLL_VELOCITY_THRESHOLD_WHEN_PLAYING
            : SCROLL_VELOCITY_THRESHOLD_WHEN_STOPPED
    }

    _setLoop(fromTick, toTick) {
        if (this.loopFromMarker) {
            this.pianoRollSvg.removeChild(this.loopFromMarker.svgElement)
            this.loopFromMarker = null
        }
        if (this.loopToMarker) {
            this.pianoRollSvg.removeChild(this.loopToMarker.svgElement)
            this.loopToMarker = null
        }

        this.timelineItems
            .filter(item => item.type === 'note')
            .forEach(item => item.enable())

        if ((fromTick || fromTick === 0) && (toTick || toTick === 0)) {
            this.loopFromMarker = new PianoRollLoopFromMarker(this, fromTick)
            this.loopToMarker = new PianoRollLoopToMarker(this, toTick)
            this.pianoRollSvg.appendChild(this.loopFromMarker.svgElement)
            this.pianoRollSvg.appendChild(this.loopToMarker.svgElement)

            this.timelineItems
                .filter(item => item.type === 'note' && item.tick >= toTick)
                .forEach(item => item.disable())
        }
    }

    clearLoop() {
        this.setLoop(null, null)
    }

    get isLooping() {
        return !!this.loopFromTick || (this.loopFromTick === 0)
    }

    get posY() {
        return this.containerElement.scrollTop
    }

    setPosY(y) {
        if (!this.isReady) {
            return
        }
        this.containerElement.scrollTop = y

        // Need to keep scroller informed about position - but set value directly cos setPosition() gets
        // us into scroller's animation loop.
        // The value needs to be negative though not sure why...
        this.scroller.position.y = -y
    }

    goToTick(tick) {
        const y = this.ticksToPx(tick) - this.viewportHeight
        this.setPosY(y)
    }

    scrollerOnUpdateCallback(scrollerState) {
        this.playbackManager.pause()

        const tick = this.getCurrentTickFromY()
        this.playbackManager.handleMidiWaterfallScroll(tick)

        if (!scrollerState.isMoving) {
            this.playbackManager.resume()
        }
    }

    // the 'playhead' tick - i.e. the tick which is currently at the bottom of the midi waterfall viewport
    getCurrentTickFromY() {
        const y = this.posY
        const offsetFromBottomPx = Math.max(this.pianoRollHeightPx - y - this.viewportHeight, 0)
        const tick = Math.trunc(offsetFromBottomPx * this.ticksPerPx - this.countInTicks)
        return tick
    }

    draw(tick) {
        if (!this.isReady) {
            // when viewmode is switched while playing need to wait till reset() has happened.
            return
        }
        if (this.containerElement === null) {
            this.initPianoRoll()
            return   // for simplicity, return now, even if we successfully set up the svg; the next draw should work
        }
        this.goToTick(tick)
    }

    drawAllOnPianoRoll() {
        this.pianoRollSvg.setAttribute('height', this.pianoRollHeightPx)
        this.drawPitchLines()
        this.timelineItems.forEach(item => {
            this.pianoRollSvg.appendChild(item.svgElement)
        })
        // Draw all labels afterwards else labels for short notes can be obscured.
        this.timelineItems.forEach(item => {
            item._svgLabel && this.pianoRollSvg.appendChild(item._svgLabel)
        })
    }

    clearPianoRoll() {
        while (this.pianoRollSvg.hasChildNodes()) {
            this.pianoRollSvg.removeChild(this.pianoRollSvg.lastChild)
        }
    }

    // convert a tick value in the piece to a Y value in pixels
    ticksToPx(tick) {
        return this.pianoRollHeightPx - (tick + this.countInTicks) / this.ticksPerPx
    }

    createPianoRollSvg() {
        const pianoRollSvg = document.createElementNS(SVGNS, "svg")
        pianoRollSvg.setAttribute("id", "piano-roll")
        pianoRollSvg.setAttribute("x", 0)
        pianoRollSvg.setAttribute("y", 0)
        pianoRollSvg.setAttribute("width", "100%")
        return pianoRollSvg
    }

    drawPitchLines() {
        let midi = 12
        while (midi <= this.noteRange.last) {
            const x = this.getKeyPosX(midi)
            this.pianoRollSvg.appendChild(this.createVerticalLine(x))
            midi += 12
        }
    }

    createVerticalLine(x) {
        const element = document.createElementNS(SVGNS, "line")
        element.setAttribute("x1", x)
        element.setAttribute("x2", x)
        element.setAttribute("y1", 0)
        element.setAttribute("y2", "100%")
        element.setAttribute("stroke", "#999")
        element.setAttribute("stroke-width", "1")
        element.setAttribute("opacity", "0.15")
        return element
    }

    static getKeyPosXRelativeToC0(midi) {
        const octaves = Math.trunc(midi / 12)
        const chromaticIndex = midi % 12
        const pitchFactor = pitchPositions[chromaticIndex]
        return octaves * 7 + pitchFactor
    }

    getKeyPosX = midi => {
        return (MidiWaterfall.getKeyPosXRelativeToC0(midi) - this.firstXpos) * this.whiteKeyWidth
    }

    getTickAtBeat(beat) {
        return Math.round(beat * this.ppq)
    }
}


class PianoRollItem {
    constructor(midiWaterfall, tick, type, label) {
        this.midiWaterfall = midiWaterfall  // the MidiWaterfall instance
        this.tick = tick
        this.type = type
        this.label = label
        this.durationTicks = 0
        this.disabled = false
        this._svgElement = null
        this._svgLabel = null
    }

    enable() {
        this.disabled = false
        this.styleSvg()
    }

    disable() {
        this.disabled = true
        this.styleSvg()
    }

    get svgElement() {
        if (this._svgElement === null) {
            this.createSvg()
            this.styleSvg()
        }
        return this._svgElement
    }

    // y coord within the piano roll svg
    get y() {
        return this.midiWaterfall.ticksToPx(this.tick)
    }

    get yEnd() {
        return this.midiWaterfall.ticksToPx(this.tick + this.durationTicks)
    }

    createSvg() { }   // define on subclass

    styleSvg() { }    // define on subclass (but it's optional)

}

class PianoRollNote extends PianoRollItem {
    constructor(midiWaterfall, tick, durationTicks, midi, pitchClass, trackIndex) {
        const _pitchClass = pitchClass.replace('♮', '')  // for naturals just show the letter as it's evident that it's a white note
        super(midiWaterfall, tick, 'note', _pitchClass)
        this.durationTicks = durationTicks
        this.midi = midi
        this.trackIndex = trackIndex
        this.isLeftHand = trackIndex === 1
        this.isWhiteNote = WHITE_NOTE_INDICES.includes(this.midi % 12)
        this._svgRect = null
    }

    createSvg() {
        this._svgElement = document.createElementNS(SVGNS, "rect")
        let x = this.midiWaterfall.getKeyPosX(this.midi) + NOTE_MARGIN_X
        if (!this.isWhiteNote) {
            x -= (this.midiWaterfall.blackKeyWidth - BLACK_KEY_WIDTH_PX) / 2
        }
        const width = (this.isWhiteNote ? this.midiWaterfall.whiteKeyWidth : this.midiWaterfall.blackKeyWidth) - NOTE_MARGIN_X * 2

        let height = this.y - this.yEnd
        // enforce a little gap between notes:
        const gap = Math.min(height / 2, NOTE_MIN_GAP_Y)
        const yTop = this.yEnd + gap
        height -= gap

        this._svgElement.setAttribute("x", x)
        this._svgElement.setAttribute("y", yTop)
        this._svgElement.setAttribute("width", width)
        this._svgElement.setAttribute("height", height)

        this._svgLabel = document.createElementNS(SVGNS, "text")
        this._svgLabel.textContent = this.label[0]
        this._svgLabel.setAttribute("x", x + width / 2)
        this._svgLabel.setAttribute("y", yTop + height - 4)
        this._svgLabel.setAttribute("fill", midiWaterfallColorLabel)
        this._svgLabel.setAttribute("class", 'midi-waterfall-note-label')

        if (this.label[1]) {
            const svgLabelAccidental = document.createElementNS(SVGNS, "tspan")
            svgLabelAccidental.textContent = this.label.slice(1)
            svgLabelAccidental.setAttribute("class", 'midi-waterfall-note-label-accidental')
            this._svgLabel.appendChild(svgLabelAccidental)
        }

        return this._svgElement
    }

    styleSvg() {
        const color = this.disabled
            ? this.isWhiteNote ? midiWaterfallColorDisabled : midiWaterfallColorBlackNoteDisabled
            : this.isLeftHand
                ? this.isWhiteNote ? midiWaterfallColorLeft : midiWaterfallColorBlackNoteLeft
                : this.isWhiteNote ? midiWaterfallColorRight : midiWaterfallColorBlackNoteRight
        this._svgElement.setAttribute("rx", NOTE_CORNER_RADIUS)
        this._svgElement.setAttribute("stroke", color)
        this._svgElement.setAttribute("fill", color)
        return this._svgElement
    }
}

class PianoRollLine extends PianoRollItem {
    constructor(midiWaterfall, tick, type) {
        super(midiWaterfall, tick, type)
        this.strokeColor = "hsla(0, 0%, 100%, 0.25)"
        this.strokeWidth = 1
    }

    createSvg() {
        this._svgElement = document.createElementNS(SVGNS, "line")
        this._svgElement.setAttribute("x1", 0)
        this._svgElement.setAttribute("x2", "100%")
        this._svgElement.setAttribute("y1", this.y)
        this._svgElement.setAttribute("y2", this.y)
        return this._svgElement
    }

    styleSvg() {
        this._svgElement.setAttribute("stroke", this.strokeColor)
        this._svgElement.setAttribute("stroke-width", this.strokeWidth)
        return this._svgElement
    }
}

class PianoRollBarLine extends PianoRollLine {
    constructor(midiWaterfall, tick) {
        super(midiWaterfall, tick, 'barline')
    }
}

class PianoRollLoopFromMarker extends PianoRollLine {
    constructor(midiWaterfall, tick) {
        super(midiWaterfall, tick, 'loop-from')
        this.strokeColor = "hsla(55, 89%, 55%, 0.3)"
        this.strokeWidth = 6
    }
    get y() {
        return super.y - this.strokeWidth / 2
    }
}

class PianoRollLoopToMarker extends PianoRollLine {
    constructor(midiWaterfall, tick) {
        super(midiWaterfall, tick, 'loop-to')
        this.strokeColor = "hsla(55, 89%, 55%, 0.3)"
        this.strokeWidth = 6
    }
    get y() {
        return this.midiWaterfall.ticksToPx(this.tick - 1) + this.strokeWidth / 2
    }
}
