import { PlaybackManager } from './PlaybackManager'
import { Player } from '../player/player.es'
import { SAMPLES_URL } from '../urls'
import { MidiDeviceManager } from './MidiDeviceManager'
import { KeyEvent } from './KeyPress'
import { applyPlayerMocks } from '../player/applyPlayerMocks'
import { applyToneFix } from '../player/applyToneFix'


const noteOffLookaheadMs = 70  // noteOffCallback is called this much early so that repeated notes don't elide on the piano keyboard

export async function setupPlayback(
    appContextDispatch,
    notesContextDispatch,
    setPlayhead,
    initialViewMode,
    initialPlayMode,
    metronomeOn,
    prevMidiInputDeviceName,
) {

    const setPlaybackState = playbackState => appContextDispatch({ type: 'SET_PLAYBACK_STATE', payload: playbackState })
    const setPlayMode = playMode => appContextDispatch({ type: 'SET_PLAY_MODE', payload: playMode })
    const setMidiAccessStatus = status => appContextDispatch({ type: 'SET_MIDI_ACCESS_STATUS', payload: status })
    const setMidiConnected = isConnected => appContextDispatch({ type: 'SET_MIDI_CONNECTED', payload: isConnected })
    const setEnableAudioOpen = isOpen => appContextDispatch({ type: 'SET_ENABLE_AUDIO_OPEN', payload: isOpen })
    const setScorePopupOpen = isOpen => appContextDispatch({ type: 'SET_SCORE_POPUP_OPEN', payload: isOpen })

    const onUserNoteOn = key => notesContextDispatch({ type: 'NOTE_ON_USER', payload: key })
    const onUserNoteOff = key => notesContextDispatch({ type: 'NOTE_OFF_USER', payload: key })
    const setHintKeys = keys => notesContextDispatch({ type: 'SET_HINT_KEYS', payload: keys })

    const audioPlayerOptions = {
        noteOnCallback: (note, trackIndex, time) => {
            notesContextDispatch({ type: 'NOTE_ON_PLAYER', payload: new KeyEvent(note.midi, { trackIndex }) })
        },
        noteOffCallback: (note, trackIndex, time) => {
            notesContextDispatch({ type: 'NOTE_OFF_PLAYER', payload: new KeyEvent(note.midi, { trackIndex }) })
        },
        noteOffLookaheadMs,
        stopCallback: () => {
            notesContextDispatch({ type: 'SET_PLAYER_KEYS', payload: [] })
        },
        metronomeInstrumentName: 'MetronomeClick'   // or Clave
    }
    const audioPlayer = new Player(
        SAMPLES_URL,
        audioPlayerOptions
    )

    applyToneFix(audioPlayer)
    if (process.env.REACT_APP_TESTING === 'test') {
        applyPlayerMocks(audioPlayer)
    }

    const midiDeviceManager = new MidiDeviceManager(
        audioPlayer,
        setMidiAccessStatus,
        setMidiConnected,
        setEnableAudioOpen,
        setPlayMode,
        prevMidiInputDeviceName,
        localStorage.getItem('playMode')
    )
    const playbackManager = new PlaybackManager(
        audioPlayer,
        midiDeviceManager,
        setPlaybackState,
        setPlayhead,
        onUserNoteOn,
        onUserNoteOff,
        setHintKeys,
        setScorePopupOpen,
        initialViewMode,
        initialPlayMode
    )
    playbackManager.metronomeOn = metronomeOn
    appContextDispatch({ type: 'SET_PLAYBACK_MANAGER', payload: playbackManager })

}
