/** @jsxImportSource @emotion/react */

import React, { useEffect, useRef } from 'react'
import { css } from '@emotion/react'
import { useDispatch, useAppState } from './StateProvider'
import NoteOverlay from './NoteOverlay'
import useLongPress from '../logic/useLongPress'
import { isTouchDevice } from '../logic/util'


const SelectionOverlayContextAsProps = ({
    pageNum,
    pageWidth,
    pageHeight,
    playbackMap,
    draggedFrom,
    setDraggedFrom,
    touchHandleDrag,
    setTouchHandleDrag,
    possBeats,
    setPossBeats,
    playbackManager,
    loopRange,
    dispatch
}) => {

    const calcLoopRange = (possBeats1, possBeats2) => {
        // Each physical (i.e. on screen) bar might be in a repeat, so could have multiple possible beat values.
        // First, figure out which of the two physical bars is 'left-most'...
        const possFromBeats = possBeats1[0] <= possBeats2[0] ? possBeats1 : possBeats2
        const possToBeats = possBeats1[0] <= possBeats2[0] ? possBeats2 : possBeats1

        // The loop selection might be:
        // - a) spanning a repeat start
        // - b) spanning a repeat end
        // - c) spanning a whole repeat
        // - d) wholly inside a repeat
        // - e) spanning a D.C. or D.S.
        // - f) spanning a 'to coda'
        //
        // ** In all cases, we will choose the from/to beats which create the shortest loop. **
        //
        // (E.g. if selection starts in middle of repeat section and ends after right repeat bar
        // then play as if from the last time in the loop - i.e. don't observe the repeat when looping.)
        //
        // This boils down to:
        // - toBeat: choose the lowest one of possToBeats
        // - fromBeat: choose the highest fromBeat which is <= toBeat
        const toBeat = possToBeats[0]
        const fromBeat = possFromBeats.reduce((res, beat) => {
            return (beat > res && beat <= toBeat) ? beat : res
        }, -1)

        setLoopRange([fromBeat, toBeat])
    }

    const setLoopRange = range => {
        dispatch({ type: 'SET_LOOP_RANGE', payload: range })
        playbackManager.setLoopRange(range)
    }

    const onMouseMove = e => {
        if (e.buttons && draggedFrom === null) {
            // Set up for a new drag
            // (do this in onMouseMove instead of onMouseDown so we can distinguish a click from a drag)
            const beats = getBeatsFromEvent(e)
            setDraggedFrom(beats)
            calcLoopRange(beats, beats)
        }
    }

    const onMouseEnter = (e) => {
        if (isTouchDevice()) {
            return
        }
        if (draggedFrom === null) {
            return
        }
        const beats = getBeatsFromEvent(e)
        calcLoopRange(beats, draggedFrom)
    }

    const onMouseUp = (e) => {
        e.stopPropagation()
        setTimeout(() => setDraggedFrom(null), 0)
    }

    const onBeatClick = (e) => {
        e.stopPropagation()
        if (draggedFrom !== null) {
            // Action is a drag (do selection), not a click (place cursor)
            return
        }
        // clear range and move playhead
        setLoopRange([-1, -1])
        setPossBeats([[-1], [-1]])
        setDraggedFrom(null)
        const beatClicked = getBeatsFromEvent(e)[0]
        playbackManager.movePlayheadToBeat(beatClicked)
    }

    const onContainerPointerUp = (e) => {
        // catch pointerUps (i.e. mouse or touch) which are not on beats..
        if (draggedFrom === null && !touchHandleDrag) {
            // ..no current drag: click outside means 'clear the range'
            setLoopRange([-1, -1])
            setPossBeats([[-1], [-1]])
        }
        else {                           // ..yes current drag..
            setDraggedFrom(null)         // just end it (and the current range will stick)
            setTouchHandleDrag(null)
        }
    }

    const onLongPress = e => {
        const beats = getBeatsFromEvent(e)
        if (isNearLoopStart(beats) || isNearLoopEnd(beats)) {
            return
        }
        // For touchscreens, always start with a whole bar selection as a single-slice selection
        // may be too fiddly.
        const bar = playbackManager.playbackMap.getPointForBeat(beats[0], { byNotationOrPerformance: 'notation' }).bar
        const firstPointInBarIndex = playbackMap.points.findIndex(p => p.bar === bar)
        const firstPointInBar = playbackMap.points[firstPointInBarIndex]
        const startOfBarEquivalentBeats = getEquivalentBeatsFromAllRepeats(playbackMap, firstPointInBar)
        const poinstInBar = playbackMap.points.filter(p => p.bar === bar && p.type !== 'barline')
        const lastPointInBar = poinstInBar[poinstInBar.length - 1]
        const endOfBarEquivalentBeats = getEquivalentBeatsFromAllRepeats(playbackMap, lastPointInBar)

        setDraggedFrom(startOfBarEquivalentBeats)
        setPossBeats([startOfBarEquivalentBeats, endOfBarEquivalentBeats])
        calcLoopRange(startOfBarEquivalentBeats, endOfBarEquivalentBeats)

        if (navigator.vibrate) {
            navigator.vibrate(5)
        }
    };

    const onTouchStart = e => {
        e.stopPropagation()
        const beats = getBeatsFromEvent(e)
        // If user touches vaguely near the start marker then interpret that they want to move it; don't
        // require them to touch precisely the first slice in the range. (And same for end.)
        if (isNearLoopStart(beats)) {
            setTouchHandleDrag('start')
            return true   // tells useLongPress that event is complete (so don't do long press stuff too)
        }
        else if (isNearLoopEnd(beats)) {
            setTouchHandleDrag('end')
            return true
        }
        return
    }

    const onTouchMove = e => {
        if (!touchHandleDrag) {
            return
        }
        const elt = document.elementFromPoint(e.touches[0].clientX, e.touches[0].clientY)
        if (!elt?.dataset.beats) {
            return
        }
        const beats = getBeatsFromElement(elt)
        if (touchHandleDrag === 'start') {
            setPossBeats([beats, possBeats[1]])
            calcLoopRange(beats, possBeats[1])
        }
        else if (touchHandleDrag === 'end') {
            setPossBeats([possBeats[0], beats])
            calcLoopRange(possBeats[0], beats)
        }
    }


    const longPressEvent = useLongPress({
        onLongPress,
        onTouchStart,
        defaultOptions: {
            shouldPreventDefault: false,
            delay: 500,
        }
    });


    return (
        <div
            id={`selection-overlay`}
            onPointerUp={onContainerPointerUp}
            css={css`
                position: absolute;
                display: block;
                top: 0px;
                left: 0px;
                width: ${pageWidth}px;
                height: ${pageHeight}px;
                z-index: 203;
                mix-blend-mode: multiply;
            `}
        >
            {playbackMap.points
                .filter(point => point.bar.page === pageNum)
                .filter(point => point.type === 'note')
                .filter(point => (point.repeatNum || 1) === 1)
                .filter(point => !point.dcOrDs)
                .map((point) => {
                    const equivBeats = getEquivalentBeatsFromAllRepeats(playbackMap, point)
                    return (
                        <NoteOverlay
                            key={equivBeats[0]}
                            beats={equivBeats}
                            top={point.bar.pos.y}
                            left={point.overlayX}
                            height={point.bar.pos.h}
                            width={point.overlayW}
                            highlight={equivBeats.some(b => b >= loopRange[0] && b <= loopRange[1])}
                            isLoopStart={equivBeats.includes(loopRange[0])}
                            isLoopEnd={equivBeats.includes(loopRange[1])}
                            onMouseMove={onMouseMove}
                            onMouseEnter={onMouseEnter}
                            onMouseUp={onMouseUp}
                            onClick={onBeatClick}
                            onTouchMove={onTouchMove}
                            // onTouchStart={onTouchStart}
                            {...longPressEvent}
                        />
                    )
                })}
        </div>
    )

    function getBeatsFromElement(elt) {
        return elt.dataset.beats.split(',').map(b => Number(b))
    }

    function getBeatsFromEvent(e) {
        return getBeatsFromElement(e.target)
    }

    function isNearLoopStart(beats) {
        // true if anywhere between one beat before and 1/4 of way in the loop range.
        const startPhysBeat = possBeats[0][0]
        const endPhysBeat = possBeats[1][0]
        if (startPhysBeat < 0) {
            return
        }
        return (beats[0] >= startPhysBeat - 1 && beats[0] <= startPhysBeat + (endPhysBeat - startPhysBeat) / 4)
    }
    function isNearLoopEnd(beats) {
        const startPhysBeat = possBeats[0][0]
        const endPhysBeat = possBeats[1][0]
        if (startPhysBeat < 0) {
            return
        }
        return beats[0] <= endPhysBeat + 1 && beats[0] >= endPhysBeat - (endPhysBeat - startPhysBeat) / 4
    }

    // Return all beats which share the same physical position on the page.
    // E.g. any beat which is inside a repeat section will have two such beats,
    // or if there is also a D.C. then maybe 3 or more.
    function getEquivalentBeatsFromAllRepeats(playbackMap, point) {
        return playbackMap.points
            .filter(p => (
                p.bar.page === point.bar.page
                && p.bar.pos.y === point.bar.pos.y
                && p.noteX === point.noteX
                && p.type !== 'barline'
                && p.beat >= 0  // don't need count-in points
            ))
            .map(p => p.beat)
    }

}


const MemoizedSelectionOverlay = React.memo(SelectionOverlayContextAsProps)


const SelectionOverlay = (props) => {

    const { playbackManager, loopRange } = useAppState()
    const dispatch = useDispatch()

    const prevPlaybackManager = useRef(null)
    const prevLoopRange = useRef(null)
    const prevDispatch = useRef(null)
    useEffect(() => {
        prevPlaybackManager.current = playbackManager
        prevLoopRange.current = loopRange
        prevDispatch.current = dispatch
    }, [playbackManager, loopRange, dispatch])

    return <MemoizedSelectionOverlay
        {...props}
        playbackManager={playbackManager}
        loopRange={loopRange}
        dispatch={dispatch}
    />
}


export default SelectionOverlay
