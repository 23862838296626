/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import NoteLabel from './NoteLabel'
import { NoteName } from './NoteName'


const NoteNamesOverlay = ({
    pageNum,
    pageWidth,
    pageHeight,
    playbackMap,
}) => {

    return (
        <div
            id={`note-names-pg${pageNum}`}
            css={css`
                position: absolute;
                display: block;
                top: 0px;
                left: 0px;
                width: ${pageWidth}px;
                height: ${pageHeight}px;
                z-index: 200;
                font-size: 1.4rem;
                font-weight: 500;
                color: hsla(210deg, 80%, 35%, 0.8);
            `}
        >
            {playbackMap.points
                .filter(point => point.bar.page === pageNum)
                .filter(point => point.type !== 'barline')
                .filter(point => (point.repeatNum || 1) === 1)
                .filter(point => !point.dcOrDs)
                .map((point) => {
                    const topStave = Object.values(point.bar.staves)[0]
                    const chords = topStave?.voices[0].chords
                    const chord = chords?.find(ch => point.bar.pos.x + ch.x === point.noteX)
                    const pitch = chord?.highestPitch
                    if (pitch && !pitch.tiedTo) {  // no note name on 'tied-to' notes
                        return (
                            <NoteLabel
                                key={`${point.beat}`}
                                id={`note-name-${point.beat}`}
                                pitch={pitch}
                                height={point.bar.pos.h}
                                width={point.overlayW}
                                data-cy={`note-name-${point.beat}`}
                            >
                                <NoteName letter={pitch.letter} alteration={pitch.alteration} />
                            </NoteLabel>
                        )
                    }
                    return null
                })}
        </div>
    )
}


export default NoteNamesOverlay
