/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useCallback } from 'react'

import { IconReset } from '../Icons'
import { useAppState, useDispatch } from '../StateProvider'
import { BaseButton } from './Button'


export function ResetToStartButton(props) {
    const { playbackState, playbackManager } = useAppState()
    const dispatch = useDispatch()
    const isEnabled = playbackState && playbackState !== 'LOADING'

    const handleClick = useCallback(
        async event => {
            playbackManager.movePlayheadToStart()
            dispatch({ type: 'SET_SCORE_POPUP_OPEN', payload: false })
            if (playbackState === 'STOPPED') {
                playbackManager.resetScore()
            }
        },
        [playbackManager, playbackState, dispatch])


    return (
        <BaseButton
            name='reset'
            id='reset-button'
            data-cy='reset-button'
            onClick={handleClick}
            disabled={!isEnabled}
            additionalCss={css`
                color: hsla(120deg, 0%, 20%, 0.7);
    `}
            {...props}
        >
            <IconReset />
        </BaseButton>
    )
}

