/** @jsxImportSource @emotion/react */

import { useState, useRef } from 'react'
import { css } from '@emotion/react'

import { IconMinus, IconPlus } from '../Icons'
import { useDispatch, useAppState } from '../StateProvider'
import { MQ } from '../../styles/media'
import { baseControlCss, disabledCss, smallControlCss, SMALL_CONTROL_H } from './ControlBase'
import { ControlGroup, groupLeftCss, groupMiddleCss, groupRightCss } from './ControlGroup'
import { SmallButton } from './Button'


export const PlaybackSpeed = () => {

    const { playbackManager, playbackState } = useAppState()
    const [displayValue, setDisplayValue] = useState(playbackManager.playbackSpeed)
    const isEnabled = playbackState && playbackState !== 'LOADING'
    const increment = 0.05

    const handleChange = e => {
        const buttonPressed = e.currentTarget.name
        const newValue = displayValue + (buttonPressed === 'increase' ? increment : -increment)
        setDisplayValue(newValue)
        playbackManager.setPlaybackSpeed(newValue)
    }

    const specificCss = css`
        ${MQ.phone} {
            flex-shrink: 1;
            min-width: 10px;
            max-width: ${SMALL_CONTROL_H};
        }
        `

    return (
        <ControlGroup>
            <SmallButton
                id='playback-speed-decrease'
                name='decrease'
                disabled={!isEnabled}
                onClick={handleChange}
                additionalCss={css`${groupLeftCss} ${specificCss}`}
            >
                <IconMinus size={13} />
            </SmallButton>
            <div
                id="playback-speed-textbox"
                css={css`${smallControlCss} ${groupMiddleCss}
                display: flex;
                align-items: center;
                font-size: 1rem;
                padding-left: 5px;
                padding-right: 5px;
                box-sizing: border-box;
                color: inherit;
                ${!isEnabled && disabledCss}
                `}
            >{parseFloat(displayValue).toFixed(2)}×</div>

            <SmallButton
                id='playback-speed-increase'
                name='increase'
                disabled={!isEnabled}
                onClick={handleChange}
                additionalCss={css`${groupRightCss} ${specificCss}`}
            >
                <IconPlus size={13} />
            </SmallButton>
        </ControlGroup>
    )
}


export const PlaybackSpeedText = () => {   // ??? obsolete - but might come back so leaving in atm...

    const dispatch = useDispatch()
    const { playbackSpeed, playbackManager, playbackState } = useAppState()
    const setPlaybackSpeed = speed => dispatch({ type: 'SET_PLAYBACK_SPEED', payload: speed })

    // Use an interim display value - only update the actual playback speed when (a) the user finishes editing and
    // leaves the field (onBlur), and (b) only if the value is valid.
    const [displayValue, setDisplayValue] = useState(parseFloat(playbackSpeed).toFixed(2))
    const inputRef = useRef(null)

    const handleChange = e => {
        const value = Number(e.currentTarget.value)
        setDisplayValue(value)
    }

    const handleFocus = e => e.target.select()

    const applyValue = e => {
        setDisplayValue(parseFloat(displayValue).toFixed(2))
        if (inputRef.current.checkValidity()) {
            setPlaybackSpeed(displayValue)
            playbackManager.setPlaybackSpeed(displayValue)
        }
    }

    const handleKey = e => {
        if (e.key === 'Enter') {
            return applyValue(e)
        }
    }

    const isEnabled = playbackState && playbackState !== 'LOADING'

    return (
        <div
            css={css`
                ${baseControlCss}
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 45px;
                height: 45px;
                text-align: center;
                `}
        >
            <input
                id='playback-speed'
                ref={inputRef}
                disabled={!isEnabled}
                css={css`
            ${!isEnabled ? disabledCss : ''}
            width: 1.9em;
            height: 25px;
            margin-right: 1px;
            border: 1px solid hsla(0deg, 0%, 0%, 0.1);
            background-color: hsla(0deg, 0%, 100%, 0.2);
            font-size: 1em;
            padding-right: 1em;
            padding-left: 5px;
            text-align: right;
            color: inherit;
            -moz-appearance: textfield;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
               -webkit-appearance: none;
               margin: 0;
            }
            &:invalid {
                background-color: #f1bfbf;
                color: #8b1616;
            }
            `}
                type="number"
                min="0.01"
                max="4"
                step="0.01"
                value={displayValue}
                onChange={handleChange}
                onFocus={handleFocus}
                onKeyUp={handleKey}
                onBlur={applyValue}
            />
            <div  // 'times' sign
                css={css`
                    ${!isEnabled ? disabledCss : ''}
                    position: absolute;
                    display: block;
                    transform: translate(0, -0.63em);
                    top: 50%;
                    right: -0px;
                    pointer-events: none;
                    font-size: 1.3em;
                    width: 1em;
                    text-align: right;
                    font-style: normal;
                    color: hsla(0deg, 0%, 0%, 0.3);
                    font-weight: 600;
                `}
            >×</div>
        </div>
    )
}

