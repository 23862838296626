/** @jsxImportSource @emotion/react */

import { useDispatch, useAppState } from '../StateProvider'
import { ReactComponent as NoteLabelIcon } from '../../icons/note-label-C.svg'
import { smallButtonCss, PressButton } from './Button'



export function ShowNoteNamesButton({ ...props }) {
    const { playbackState, showNoteNames } = useAppState()
    const dispatch = useDispatch()
    const isEnabled = playbackState && playbackState !== 'LOADING'

    const handleShowNoteNamesClick = () => {
        dispatch({ type: 'SET_SHOW_NOTE_NAMES', payload: !showNoteNames })
    }

    return (
        <PressButton
            id='note-names-button'
            data-cy='note-names-button'
            onClick={handleShowNoteNamesClick}
            isPressed={showNoteNames}
            disabled={!isEnabled}
            additionalCss={smallButtonCss}
            {...props}
        >
            {/* {showNoteNames ? <IconHide size={25} /> : <IconShow size={25} />} */}
            <NoteLabelIcon />
        </PressButton>
    )
}

