/** @jsxImportSource @emotion/react */

import React, { useEffect, useState, useRef } from 'react';
import { css } from '@emotion/react'
import { KEY_HEIGHT_PX } from '../logic/constants'
import { useAppState, useDispatch } from './StateProvider'
import Toast from 'react-bootstrap/Toast';
import { Button } from './controls/Button';


const CHECK_AUDIO_CONTEXT_INTERVAL_MS = 1000


export const ConnectAudio = () => {
    // On arrival, if midi device automatically connects and user starts playing then there will be
    // no sound because of browser/web audio limitation. We need a user gesture to enable the audio
    // context.
    //
    // So in the above situation a note-on MIDI message will display the EnableAudioPopup (below),
    // and will also set up a periodic check using setInterval for the audio context's state.
    // Once the audio context is enabled (whether by user clicking the enable audio popup, or by them
    // clicking Play, etc.) then a 'Success' popup is shown and the setInterval checks are stopped.

    const checkAudioContextIntervalId = useRef(null)
    const [isSuccessPopupOpen, setSuccessPopupOpen] = useState(false)
    const { playbackManager, showPiano, isEnableAudioOpen } = useAppState()
    const dispatch = useDispatch()

    const enableAudio = () => {
        const tone = playbackManager.audioPlayer.getTone()
        tone.start()
    }

    // when the 'enable audio' popup opens then start periodically checking whether audio is enabled
    useEffect(
        () => {

            function checkAudioContext() {
                const audioContext = playbackManager.audioPlayer.getTone().context._context
                console.log('checking audio context state:', audioContext.state)
                if (audioContext.state === 'running') {
                    dispatch({ type: 'SET_ENABLE_AUDIO_OPEN', payload: false })
                    setSuccessPopupOpen(true)
                    clearInterval(checkAudioContextIntervalId.current)  // stop checking
                }
            }

            if (isEnableAudioOpen && !checkAudioContextIntervalId.current) {
                checkAudioContextIntervalId.current = setInterval(checkAudioContext, CHECK_AUDIO_CONTEXT_INTERVAL_MS)
            }
            return () => clearInterval(checkAudioContextIntervalId.current)
        },
        [isEnableAudioOpen, checkAudioContextIntervalId, dispatch, playbackManager]
    )

    // close the 'success' popup after a few secs
    useEffect(
        () => {
            let t
            if (isSuccessPopupOpen) {
                t = setTimeout(() => setSuccessPopupOpen(false), 5000)
            }
            return () => clearTimeout(t)
        },
        [isSuccessPopupOpen]
    )

    return (
        <React.Fragment>

            <Toast
                show={isEnableAudioOpen}
                className='position-fixed end-0 mb-1 me-3 w-auto bg-secondary'

                css={css`
                bottom: ${showPiano ? KEY_HEIGHT_PX : 0}px; font-size: 1rem;
                z-index:1001;
                `}
            >
                <Button
                    onClick={enableAudio}
                    additionalCss={css`color: hsla(0deg, 0%, 24%, 1); width: 20em; 
                    padding-left: 15px; padding-right: 15px; border: none; height: 60px;`}
                >
                    Click to enable sound
                </Button>
            </Toast >

            <Toast
                show={isSuccessPopupOpen}
                className='position-fixed end-0 mb-1 me-3 px-5 py-3 bg-success'
                onClick={() => setSuccessPopupOpen(false)}

                css={css`
                bottom: ${showPiano ? KEY_HEIGHT_PX : 0}px; width: 20em;
                font-size: 1rem; color: hsla(0deg, 0%, 24%, 1); width: 20em;
                z-index: 1001;
                `}
            >
                Sound enabled!
            </Toast>

        </React.Fragment>
    )

}
