export class Piece {
    constructor(pieceJson) {
        this.filename = pieceJson.filename
        this.title = pieceJson.title
        this.pageWidth = pieceJson.pageWidth
        this.pageHeight = pieceJson.pageHeight

        this.bars = []

        // Handy check for anything not used from the json:
        // for (const k in pieceJson) {
        //     if (!(k in this) && k !== 'barId') {
        //         console.log('⚠️ Piece: key in json not used!', k)
        //     }
        // }
    }

    appendBar(bar) {
        const currLastBar = this.bars.at(-1)
        if (currLastBar) {
            currLastBar.next = bar
            bar.prev = currLastBar
        }
        else {
            bar.newPage = true
        }
        this.bars.push(bar)
    }

}
