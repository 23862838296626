import { TimeSig } from "./TimeSig"

export class Bar {
    constructor(barJson, pageIndex) {

        this.id = barJson.barId
        this.pos = barJson.pos
        this.newSystem = barJson.newSystem || this.pos.x === 0
        this.newPage = barJson.newPage || false
        this.keySig = barJson.keySig
        this.leftBarLine = barJson.leftBarLine
        this.rightBarLine = barJson.rightBarLine
        this.ending = barJson.ending
        this.daCapo = barJson.daCapo
        this.coda = barJson.coda
        this.toCoda = barJson.toCoda
        this.segno = barJson.segno
        this.dalSegno = barJson.dalSegno
        this.fine = barJson.fine
        this.page = pageIndex
        this.timeSig = barJson.timeSig
        this.staves = {}

        // Ultimately there should be only one timeSig attr which should contain the TimeSig instance.
        // But timeSig (with the string like '4/4') is used in several places, and little point in fixing all
        // those if this code is to be replaced by the improved structures from brian-app at some point..
        this.timeSig_ = this.timeSig ? new TimeSig(this.timeSig.split('/')) : null

        // Handy check for anything not used from the json:
        // for (const k in barJson) {
        //     if (!(k in this) && k !== 'barId') {
        //         console.log('⚠️ Bar: key in json not used!', k)
        //     }
        // }

        // prev and next are for bars as they appear on the page, not as they are played.
        // E.g. this.next after 'D.C.' bar will be the first bar of the coda.
        this.prev = null
        this.next = null
    }

    appendStave(staveId, staveBar) {
        this.staves[staveId] = staveBar
    }

    getNotesAtQnIndex(qn) {
        let trackNotePairs = []
        Object.values(this.staves).forEach((staveBar, i) => {
            trackNotePairs = trackNotePairs.concat(
                staveBar.getNotesAtQnIndex(qn).map(note => ([i, note.midi]))
            )
        })
        return trackNotePairs
    }

}
