/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'


const PageOneHeader = ({ title, pageWidth }) => (
    <div
        css={css`
    position: absolute;
    margin-top: 2rem;
    width: ${pageWidth}px;
    font-size: 1.35rem;
    `}>
        <h1>{title}</h1>
    </div>

)


export default PageOneHeader
