/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { PLAYBACK_CONTROLS_HEIGHT } from '../logic/constants'
import { VIEW_MODE, useAppState } from './StateProvider'


const ErrorCpt = ({ error, errorMessage, children }) => {

    const { viewMode } = useAppState()
    const textColor = viewMode === VIEW_MODE.MIDI_WATERFALL
        ? 'hsla(0deg, 0%, 85%, 0.5)'  // midi waterfall is dark background so light text
        : 'hsla(0deg, 0%, 20%, 0.8)'  // else light bg => darker text

    return (
        <div
            id='error-cpt'
            css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: ${PLAYBACK_CONTROLS_HEIGHT}px;
            height: 56vh;
            width: 100vw;
            color: hsla(0deg, 0%, 40%, 0.5);
            & a {color: #d45b04;}
            z-index: 999;
            @media (max-height: 500px) {
                height: unset;
                justify-content: flex-start;
            }
        `}
        >
            <div css={css`
            margin: 0 10vw;
            & p:first-of-type {font-size: 1.6rem; color: ${textColor};}
            `}>
                {errorMessage && <p>{errorMessage}</p>}
                {children ? children : null}
                {!errorMessage && !children && <p>Oops! Sorry &ndash; something went wrong.</p>}
            </div>
        </div>
    )
}

export default ErrorCpt
