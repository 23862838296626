// StaveBar is a stave within a bar; or 1 bar of 1 stave.
export class StaveBar {

    constructor(staveId, staveJson, bar) {
        this.id = staveId   // should be consistent throughout the piece, and usually like 'P1/1' (part 1/stave 1)
        this.bar = bar
        this.clef = staveJson.clef
        this.voices = []

        if (staveJson.pos) {
            this.pos = {
                height: staveJson.pos.height,
                offsetY: staveJson.pos.offsetY
            }
        }
        else {
            const prevStaveBar = this.bar.prev?.staves[this.id]
            this.pos = {
                height: prevStaveBar.pos.height,
                offsetY: prevStaveBar.pos.offsetY
            }
        }
        this.pos.x = this.bar.pos.x
        this.pos.y = this.bar.pos.y + this.pos.offsetY
        this.pos.middleLineY = this.pos.y + this.pos.height / 2
        this.pos.w = this.bar.pos.w

        // Handy check for anything not used from the json:
        for (const k in staveJson) {
            if (!(k in this)) {
                console.log('⚠️ Stave: key in json not used!', k)
            }
        }

    }

    appendVoice(voice) {
        this.voices.push(voice)
    }

    // Returns the notes that start at the given qn.
    // NB. works on qnIndex, which means then qn position within the piece 'on paper' not the qn position in the
    // playback map. (A single written chord has exactly one qn-on-paper but may different/multiple qn-in-playback-map 
    // if there are repeats.)
    getNotesAtQnIndex(qn) {
        return this.voices.reduce((notes, voice) => {
            notes = notes.concat(voice.getNotesAtQnIndex(qn))
            return notes
        }, [])
    }

}
