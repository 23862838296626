/** @jsxImportSource @emotion/react */

import { IconSpinner } from './Icons'
import { css } from '@emotion/react'


const LoadingIndicator = () => (
    <div
        id='loading-indicator'
        data-cy='loading-indicator'
        css={css`
        position: absolute;
        top: 0;
        height: 70vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: hsla(0deg, 0%, 40%, 0.5);
        z-index: 999;
    `}
    >
        <IconSpinner size={80} />
    </div>
)


export default LoadingIndicator
