/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

import { IconWaitMode, IconConstantMode } from '../Icons'
import { useDispatch, useAppState, PLAY_MODE } from '../StateProvider'
import { ReactComponent as IconConnectSuccess } from '../../icons/connect-success.svg'
import { ReactComponent as IconConnectFailure } from '../../icons/connect-failure.svg'
import { ControlGroup, groupLeftCss, groupMiddleCss, groupRightCss } from './ControlGroup'
import { PressButton, smallButtonCss } from './Button'


export function PlayModeControl({ ...props }) {
    const { playMode, isMidiConfigOpen, isMidiConnected, playbackState } = useAppState()
    const dispatch = useDispatch()
    const enabled = isMidiConnected && playbackState === 'STOPPED'

    const toggleMidiConfig = e => {
        dispatch({ type: 'SET_MIDI_CONFIG_OPEN', payload: !isMidiConfigOpen })
    }

    const handleClick = e => {
        const buttonPressed = e.currentTarget.name
        const newPlayMode = playMode === buttonPressed ? PLAY_MODE.NORMAL : buttonPressed
        dispatch({ type: 'SET_PLAY_MODE', payload: newPlayMode })
    }


    return (
        <ControlGroup>
            <PressButton
                isPressed={isMidiConfigOpen}
                onClick={toggleMidiConfig}
                additionalCss={css`${smallButtonCss} ${groupLeftCss}`}
            >
                {isMidiConnected ? <IconConnectSuccess /> : <IconConnectFailure />}
            </PressButton>
            <PressButton
                id='wait-mode-button'
                name={PLAY_MODE.WAIT}
                onClick={handleClick}
                additionalCss={css`${smallButtonCss} ${groupMiddleCss}`}
                isPressed={playMode === PLAY_MODE.WAIT}
                disabled={!enabled}
                {...props}
            >
                <IconWaitMode />
            </PressButton>
            <PressButton
                id='constant-mode-button'
                name={PLAY_MODE.CONSTANT}
                onClick={handleClick}
                additionalCss={css`${smallButtonCss} ${groupRightCss}`}
                isPressed={playMode === PLAY_MODE.CONSTANT}
                disabled={!enabled}
                {...props}
            >
                <IconConstantMode />
            </PressButton>
        </ControlGroup>
    )
}
