/** @jsxImportSource @emotion/react */

import React from 'react'
import { css } from '@emotion/react'

import { MQ } from '../../styles/media'
import { baseControlCss, smallControlCss, SMALL_CONTROL_H } from './ControlBase'


export const BUTTON_BORDER_CSS = css`border: 1px solid hsla(0deg, 0%, 0%, 0.1);`


export const smallButtonCss = css`${smallControlCss} width: ${SMALL_CONTROL_H};
    ${BUTTON_BORDER_CSS}
    max-width: ${SMALL_CONTROL_H};
`

const pressedCss = css`
    background-color: hsla(200deg, 90%, 15%, 0.215);
    color: hsla(0deg, 0%, 0%, 0.89);
    box-shadow: 0px 0px 2px rgba(0,0,0,0.25) inset;
    &:disabled{
        opacity: 1;
    }
    &:hover:not([disabled]){
        background-color: hsla(0deg, 0%, 0%, 0.415);
        color: hsla(0deg, 0%, 0%, 0.7);
    };
`

// ???phys _BasePlaybackButton should use this.. but forwardRef..??
export function Button({ additionalCss, children, ...otherProps }) {
    return (
        <button
            css={css`
        ${baseControlCss}
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        min-width: 45px;
        background-color: hsla(0deg, 0%, 0%, 0);
        ${BUTTON_BORDER_CSS}
        border-radius: 4px;
        font-size: 1rem;
        color: hsla(120deg, 0%, 20%, 0.7);
        ${MQ.phone} {
            width: 7vw;
            min-width: 38px;
        }
        &:hover:not([disabled]){
            background-color: hsla(0deg, 0%, 0%, 0.15);
            color: hsla(0deg, 0%, 0%, 0.7);
        };
        ${additionalCss}
`}
            {...otherProps}
        >
            {children}
        </button>
    )
}

export const BaseButton = React.forwardRef(_BasePlaybackButton)

function _BasePlaybackButton({ isPressed, additionalCss, children, ...otherProps }, ref) {
    return (
        <button
            ref={ref}
            css={css`
        ${baseControlCss}
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        width: 45px;
        background-color: hsla(0deg, 0%, 0%, 0);
        border: none;
        border-radius: 4px;
        color: hsla(120deg, 0%, 20%, 0.7);
        ${isPressed && pressedCss}
        ${MQ.phone} {
            width: 7vw;
            min-width: 38px;
        }
        &:hover:not([disabled]){
            background-color: hsla(0deg, 0%, 0%, 0.15);
            color: hsla(0deg, 0%, 0%, 0.7);
        };
        ${additionalCss}
`}
            {...otherProps}
        >
            {children}
        </button>
    )
}

export function SmallButton({ additionalCss, children, ...props }) {
    // A base component for on/off buttons which look 'pressed' when on
    const _additionalCss = css`
        ${smallButtonCss}
        ${additionalCss}
        `
    return (
        <BaseButton
            additionalCss={_additionalCss}
            {...props}
        >
            {children}
        </BaseButton>
    )
}


export function PressButton({ isPressed, additionalCss, children, ...props }) {
    // A base component for on/off buttons which look 'pressed' when on
    // ???phys just put isPressed on every button?

    const _additionalCss = css`
        ${isPressed && pressedCss}
        ${additionalCss}
        `
    return (
        <SmallButton
            additionalCss={_additionalCss}
            {...props}
        >
            {children}
        </SmallButton>
    )
}

