/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect } from 'react'
import { PAGES_VERTICAL_GAP } from '../logic/constants'
import { displayPageWidth } from '../logic/util'
import { useAppState } from './StateProvider'

const Page = ({
    originalPageWidth,
    originalPageHeight,
    availableWidth,
    children
}) => {

    const { playbackManager } = useAppState()
    const showPageWidth = displayPageWidth(availableWidth)
    const zoom = showPageWidth / originalPageWidth
    useEffect(() => playbackManager.setZoom(zoom), [zoom, playbackManager])

    return (
        <div
            id='page'
            css={css`
            position: relative;
            margin: 0 auto ${PAGES_VERTICAL_GAP}px;
            width: ${showPageWidth}px;
            height: ${originalPageHeight * zoom}px;
            box-shadow: -2px 2px 4px rgba(0,0,0,0.1), 2px -2px 4px rgba(0,0,0,0.1);
            background-color: #fff;
            `}
            data-testid='page'
        >
            <div // container for notation + overlays so that they can be scaled together
                css={css`
                width: ${showPageWidth}px;
                height: ${originalPageHeight * zoom}px;
                transform-origin: top left;
                transform: scale(${zoom});
                `}
            >
                {children}
            </div>
        </div>
    )
}

export default Page