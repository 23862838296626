/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

import { useAppState, usePlayhead } from '../StateProvider'
import { MQ } from '../../styles/media'
import { baseControlCss } from './ControlBase'



export function PlayheadSlider() {
    const { playbackManager, playbackState } = useAppState()
    const { playhead } = usePlayhead()
    const isEnabled = playbackState && playbackState !== 'LOADING'

    const handleChange = e => {
        const value = Number(e.currentTarget.value)
        if (value === 0) {
            playbackManager.movePlayheadToStart()    // handles count-in if nec.
        }
        else {
            playbackManager.movePlayheadToBeat(value)
        }
    }

    const rangeMax = playbackManager?.calcEndBeat() || 1

    return (
        <input
            id="playhead-slider"
            data-cy="playhead-slider"
            disabled={!isEnabled}
            type="range"
            max={rangeMax}
            value={playhead.toString()}
            step="any"
            onChange={handleChange}
            css={css`
                ${baseControlCss};
                flex-grow: 1;
                width: 500px;
                height: 20px;
                margin-left: 15px;
                margin-right: 15px;
                &:hover{
                    background-color: hsla(0deg, 0%, 0%, 0.0);
                    color: hsla(0deg, 0%, 0%, 0.7);
                };
                ${MQ.phonePortrait} {
                    display: none;
                };
            `}
        />
    )
}

