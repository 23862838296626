export class Voice {
    constructor(voiceJson, staveBar) {
        this.staveBar = staveBar
        this.chords = []
    }

    appendChord(chord) {
        this.chords.push(chord)
    }

    // Returns the notes that start at the given qn.
    // NB. works on qnIndex, which means then qn position within the piece 'on paper' not the qn position in the
    // playback map. (A single written chord has exactly one qn-on-paper but may different/multiple qn-in-playback-map 
    // if there are repeats.)
    getNotesAtQnIndex(qn) {
        const notes = this.chords.reduce((notes, chord) => {
            if (chord.qnIndex === qn) {
                notes = notes.concat(chord.pitches)
            }
            return notes
        }, [])
        return notes
    }
}