import { prepPiece } from '../music-structures/prepPiece'
import { PIECES_DATABASE_URL } from '../urls'
import queryString from 'query-string'


export const loadPiece = ({
    pieceId,
    setPlaybackState,
    setPdfImages,
    setPiece,
    playbackManager,
    setPlayheadMax,
    clearLoopRange,
    setLoadingStatus,
    throwError
}) => {

    let errorHandled = false

    async function loadFromDatabase(pieceId) {
        const { t, time } = queryString.parse(window.location.search)
        const pieceUrl = `${PIECES_DATABASE_URL}/piece/${pieceId}?t=${t}&time=${time}`
        const response = await fetch(pieceUrl)
        if (response.status !== 200) {
            // Failed to get piece from database. Set the loading status so Piece component can show a helpful error.
            setLoadingStatus({ status: 'ERROR', response })
            errorHandled = true
            throw new Error(`${response.status} ${response.statusText}`)
        }
        const data = await response.json()

        let images = []
        data.images.forEach((data, pg) => {
            const pageImage = new Image()
            pageImage.src = `data:image/png;base64,${data}`
            images[pg] = pageImage
        })
        setPdfImages(images)

        await playbackManager.load(data.midi)
        // need tempos to set up piece in the playback manager, and tempos are only in the midi (not the xml) - so must
        // wait until the midi is all loaded up.
        const preppedPiece = prepPiece(data.parsed_xml, playbackManager.midiLengthQn)
        setPiece(preppedPiece)
        const setPieceComplete = playbackManager.setPiece(preppedPiece)

        return setPieceComplete
    }

    async function loadFromFiles(pieceId) {
        const xmlJsonLoaded = import(`../testdata-demo/xml-json/${pieceId}.xml.json`)
        const midiLoaded = playbackManager.load(`/--bptest/${pieceId}.mid`)
        const [xmlJson] = await Promise.all([xmlJsonLoaded, midiLoaded])

        const preppedPiece = prepPiece(xmlJson, playbackManager.midiLengthQn)
        setPiece(preppedPiece)
        const loaded = playbackManager.setPiece(preppedPiece)

        const images = []
        const basename = pieceId.replace(/\.[^/.]+$/, "")
        for (let pg = 1; pg <= 999; pg++) {
            // Following chunk gets images from within src for easy testing in netlify deployment.
            const filename = `${basename}-pg${pg}`
            try {
                await import(`../testdata-demo/img/${filename}.png`)
                    .then((data) => {
                        const img = new Image()
                        img.src = data.default
                        images[pg - 1] = img
                        setPdfImages(images)
                    })
            }
            catch (e) {
                if (e.code === 'MODULE_NOT_FOUND' && pg > 1) {
                    // no image with that name - we'll interpret that we've already got all the pages and do nothing.
                    // This is quite hacky - perhaps the image file *is* missing! But this way of loading is only
                    // for test data so we'll let it slide. (The xml json needs to be fully loaded to be able to know
                    // how many pages there *should* be.)
                    break
                }
                else {
                    throw e
                }
            }
        }

        return loaded
    }

    playbackManager.stop()
    playbackManager.clearCursor()
    setPlaybackState('LOADING')
    setPdfImages([])
    clearLoopRange()
    window.scrollTo({ top: 0, behavior: 'instant' })

    let loaded
    if (pieceId.match(/^--bptest-/) || pieceId.match(/^CPDL/)) {
        loaded = loadFromFiles(pieceId)
    }
    else {
        loaded = loadFromDatabase(pieceId)
    }

    loaded.then(() => {
        // Reset cursor - but delay a tick so that React can render the cursor layer svgs first
        setTimeout(() => playbackManager.movePlayheadToStart(), 1)
        setPlayheadMax(playbackManager.calcEndBeat())
        setPlaybackState('STOPPED')
        setLoadingStatus({ status: 'READY' })
        console.log('🎖 LOADED')
    })
        .catch(e => {
            setPlaybackState(null)
            if (errorHandled) {   // e.g. piece not found in db - has been propagated in appropriate way
                console.log(e)
            }
            else {                // something broke..
                throwError(e)     // throw in a way that the error boundary will see it
            }
        })
}
