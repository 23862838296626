import { retryWithDelay } from '../logic/util';


export class SvgLayerUser {
    /*
    Base class for classes which interact with an SVG layer - namely:
    - Cursor: animates the cursor on SVG created by CursorLayer
    - NotationHighlighter: highlights noteheads on SVG created by NotationHighlightLayer
    */

    constructor(playbackManager, svgIdBase) {
        this.playbackManager = playbackManager
        this.svgIdBase = svgIdBase
        this.zoom = 1
        this.init()
        this.setupSvgElts = this.setupSvgElts.bind(this)
    }

    init(pageHeight) {
        this.svgElts = []  // there's one SVG layer per page of music
        this.currPageId = 0
        this.pageHeight = pageHeight
    }

    suspend() {
        this.svgElts = []
    }

    // Need page zoom in order to calculate whether a bar is outside the viewport and we need to scroll.
    setZoom(zoom) {
        this.zoom = zoom
    }

    async setupSvgElts() {
        function svgLayerExists() {
            const elt = document.getElementById(svgId)
            return !!elt
        }
        const svgId = this.svgIdBase + '-0'
        await retryWithDelay(svgLayerExists, 9, 50, `Timed out awaiting ${svgId} to exist`)

        this.svgElts = []
        while (true) {
            const svgElt = document.getElementById(`${this.svgIdBase}-${this.svgElts.length}`)
            if (!svgElt) {
                break
            }
            this.svgElts.push(svgElt)
        }
        return !!this.svgElts.length
    }

    clear() {
        this.svgElts = this.svgElts || []
        this.svgElts.forEach(
            svgElt => {
                while (svgElt.hasChildNodes()) {
                    svgElt.removeChild(svgElt.lastChild)
                }
            }
        )
    }

}

