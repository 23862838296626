export function applyPlayerMocks(audioPlayer) {

    // If testing then we'll turn off sample loading - partly to make more deterministic, but also to
    // speed things up and to have less logging clutter. Of course, this means the app won't make a
    // sound in testing.
    audioPlayer.loadInstruments = () => {
        console.log(`🦄 running mock loadInstruments

🔇 We're in test mode so we'll skip sample loading (so the app won't make any sound).

`
        )
    }

    audioPlayer.playNoteNow = (({ pitchSpnOrMidi }) => {
        console.log('🦄 playNoteNow mock', pitchSpnOrMidi)
    })

}
