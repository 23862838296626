/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

import React, { useState } from 'react'
import { useDispatch, useAppState } from '../StateProvider'
import { MQ } from '../../styles/media'
import { SMALL_CONTROL_H } from './ControlBase'
import { ControlGroup, groupLeftCss, groupRightCss } from './ControlGroup'
import { PressButton } from './Button'
import { Notification } from './Notification'


export function StaffMuteButtons({ ...props }) {
    const [showNotification, setShowNotification] = useState(false)
    const { playbackState, playStaves, playbackManager } = useAppState()
    const dispatch = useDispatch()
    const isEnabled = playbackState && playbackState !== 'LOADING'
    const [playRight, playLeft] = playStaves
    const linkLeftRight = playbackManager.midiComposition?.numTracks < 2  // only 1 piano track so link L/R buttons

    const handleClick = (e) => {
        const trackIndex = +e.target.dataset.trackIndex
        const newValue = [...playStaves]
        newValue[trackIndex] = !newValue[trackIndex]
        if (linkLeftRight) {
            newValue[(trackIndex + 1) % 2] = newValue[trackIndex]
        }
        if (newValue[0] || newValue[1]) {
            dispatch({ type: 'SET_PLAY_STAVES', payload: newValue })
            setShowNotification(false)
        }
        else {  // ignore if they try to un-press both buttons + show notification (below in JSX)
            setShowNotification(true)
        }
    }
    const EachButtonCss = css`

        ${MQ.phone} {
            min-width: 20px;
            flex-shrink: 1;
            max-width: ${SMALL_CONTROL_H};
        }
        `

    return (
        <React.Fragment>
            <ControlGroup>
                <PressButton
                    id='left-hand-mute-button'
                    data-track-index={1}
                    onClick={handleClick}
                    disabled={!isEnabled}
                    isPressed={playLeft}
                    additionalCss={css`${groupLeftCss} ${EachButtonCss}`}
                    {...props}
                >
                    L
                </PressButton>
                <PressButton
                    id='right-hand-mute-button'
                    data-track-index={0}
                    onClick={handleClick}
                    disabled={!isEnabled}
                    isPressed={playRight}
                    additionalCss={css`${groupRightCss} ${EachButtonCss}`}
                    {...props}
                >
                    R
                </PressButton>
            </ControlGroup>

            <Notification
                show={showNotification}
                timeMs={7000}
                setShow={setShowNotification}
            >
                <h5>At least one of L or R must be pressed.</h5>
                <p>In <strong>Normal</strong> mode these control which staves the app will play.</p>
                <p>In <strong>Wait</strong> mode these set which staves <strong>you</strong> will play.</p>
                <p>In <strong>Constant</strong> mode these set which staves <strong>you</strong> will play (if only one is selected then the app will play the other).</p>
            </Notification>

        </React.Fragment>
    )
}
