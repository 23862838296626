/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect } from 'react';

import Toast from 'react-bootstrap/Toast';
import ToastBody from 'react-bootstrap/ToastBody';
import { PLAYBACK_CONTROLS_HEIGHT } from '../../logic/constants';


const NOTIFICATION_TIME_MS = 4000

export function Notification({ show, setShow, timeMs = NOTIFICATION_TIME_MS, children }) {

    useEffect(
        () => {
            const t = setTimeout(() => { setShow(false) }, timeMs)
            return function tidyUp() { clearTimeout(t) }
        }
    )

    return (
        <Toast
            show={show}
            className='position-fixed end-0 mt-3 me-3 text-start'

            css={css`
        top: ${PLAYBACK_CONTROLS_HEIGHT}px;
        width: 430px;
        z-index: 1001;
        font-size: 1rem;
        color: hsla(61deg, 30%, 10%, 0.78);
        background-color: hsla(61deg, 36%, 80%, 1);
        backdrop-filter: blur(3px);
        `}
            onClick={() => setShow(false)}
        >
            <ToastBody>
                {children}
            </ToastBody>
        </Toast >
    )
}
