export class Note {

    constructor({
        pitch,
        forceAccidental,
        tiedFrom,
        tiedTo,
        id,
        chord,
        staveBar
    }) {
        this.forceAccidental = forceAccidental

        const [pitchClassIn, octaveIn] = pitch.split('/')
        this.pitchClass = pitchClassIn.replace('=', 'n')

        this.octave = +octaveIn
        this.tiedFrom = tiedFrom
        this.tiedTo = tiedTo
        this.id = id
        this.chord = chord
        this.staveBar = staveBar
    }

    static chromaticIndex({ pitchClass, ignoreOctave = false }) {
        // return number for pitch class - i.e. C = 0, C# or Db = 1, etc.
        // Pass ignoreOctave: true if you want return to be 0 to 11, so e.g. 
        // chromaticIndex ({'B#', ignoreOctave: false})   // returns 12
        // chromaticIndex ({'B#', ignoreOctave: true})    // returns 0

        const pitchChromIndex = {
            C: 0,
            D: 2,
            E: 4,
            F: 5,
            G: 7,
            A: 9,
            B: 11
        };

        let chromaticIndex = pitchChromIndex[pitchClass[0]];
        for (let i = 1; i < pitchClass.length; i++) {
            if (pitchClass[i] === "b") chromaticIndex--;
            else if (pitchClass[i] === "#") chromaticIndex++;
        }

        return ignoreOctave ?
            (chromaticIndex + 12) % 12   // + 12 else Cb returns -1
            : chromaticIndex;
    }

    static stepsAboveMiddleLine(pitchClassOrLetter, octave, clef, octaveShift = 0) {
        // where notehead sits on the staff, 0 = middle line, 1 = third space from bottom etc.
        const clefAdjustment = {
            alto: 0,
            treble: -6,   // e.g. C4 on treble clef appears 6 steps lower than C4 on alto clef
            tenor: 2,
            bass: 6,
        };

        return Note.pitchIndex(pitchClassOrLetter)
            + 7 * (octave - 4)      // 4 cos C4 is middle line of alto clef
            + octaveShift * -7
            + clefAdjustment[clef]
    }

    static pitchIndex(pitchClassOrLetter) {
        const pitchIndexLookup = { C: 0, D: 1, E: 2, F: 3, G: 4, A: 5, B: 6 }
        return pitchIndexLookup[pitchClassOrLetter[0]]
    }


    get letter() { return this.pitchClass[0] }

    // NB. Note.alteration is the part of pitch class after the letter.
    // todo: Note.accidental *should be* the flat/sharp/natural that is to be notated.
    // So e.g.:
    //     F# in key of C: alteration = '#', accidental = '#'
    //     F# in key of D: alteration = '#', accidental = '' (usually, unless there's a courtesy accidental)
    get alteration() { return this.pitchClass.substr(1) }
    get accidental() { return this.pitchClass.substr(1) }   // todo!

    get spn() {
        return `${this.pitchClass.replace(/n$/, '')}${this.octave}`
    }

    get chromaticIndex() { return Note.chromaticIndex({ pitchClass: this.pitchClass }) }
    get midi() { return 12 + this.octave * 12 + this.chromaticIndex }

    get stepsAboveMiddleLine() {
        return Note.stepsAboveMiddleLine(this.pitchClass, this.octave, this.chord.clef)
    }
    get stepsBelowTopLine() {
        return 4 - this.stepsAboveMiddleLine
    }

    get xPosOnPage() {
        return this.staveBar.pos.x + this.chord.x
    }

    get yPosOnPage() {
        // NB. the bar and stave positions coming from the xml-json seem to be fractionally off from where
        // the bars are actually displayed atm. So this method is a bit approximate.
        const pixelsPerStep = this.staveBar.pos.height / 8
        const y = this.staveBar.pos.middleLineY - this.stepsAboveMiddleLine * pixelsPerStep
        return y
    }

    get vfPitch() {
        return `${this.pitchClass.replace(/n$/, '')}/${this.octave}`
    }

    static noteFromSPN(spn) {
        const pitchClass = spn.replace(/^([A-Ga-g])((?:bb|##|b|#|=|n)?).*/, (m, p1, p2) => `${p1.toUpperCase()}${p2}`)
        const octaveMatch = spn.replace(/.*[^\d]/, '')
        const octave = !!octaveMatch ? parseInt(octaveMatch) : undefined
        return { pitchClass, octave }
    }
}
