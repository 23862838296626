import React, { createContext, useState, useContext, useEffect, useReducer } from 'react'
import { MIDI_ACCESS_STATUS } from '../playback/MidiDeviceManager';
import { setupPlayback } from '../playback/setupPlayback';

/*
appContext is the main global state context.
playhead is continually updated during playback so it is separated out from the main state.
setPlayhead is required by Piece so that is exposed separately from playhead value itself (hence a separate context).
notesContext: audioPlayerNotes also updated often during playback so has own context.
*/
const appContext = createContext()
const dispatchContext = createContext()
const playheadContext = createContext()
const setPlayheadContext = createContext()
const notesContext = createContext()

export const VIEW_MODE = {
    PDF: 'PDF ONLY',
    PDF_PIANO: 'PDF + PIANO',
    MIDI_WATERFALL: 'MIDI WATERFALL + PIANO'
}
const viewModes = Object.values(VIEW_MODE)

export const PLAY_MODE = {
    NORMAL: 'Normal',  // atm used for display in ScorePopup so user-friendly
    WAIT: 'Wait',
    CONSTANT: 'Constant'
}

const initialViewMode = localStorage.getItem('viewMode') || viewModes[1]
const initialMetronomeOn = localStorage.getItem('metronomeOn') === 'true' ? true : false
const initialPlayMode = PLAY_MODE.NORMAL
const initialMidiInputDeviceName = localStorage.getItem('midiInputDevice')

function showPiano(viewMode) {
    return [VIEW_MODE.PDF_PIANO, VIEW_MODE.MIDI_WATERFALL].includes(viewMode)
}


const appContextInitialState = {
    playbackManager: null,
    playbackState: null,
    playheadMax: 100,
    loopRange: [-1, -1],
    showNoteNames: localStorage.getItem('showNoteNames') === 'true' ? true : false,
    viewMode: initialViewMode,
    showPiano: showPiano(initialViewMode),  // showPiano is derived from viewMode, but provide this as a convenience
    playMode: initialPlayMode,
    playStaves: [true, true],  // not in localstorage - a new session will always revert to both staves playing
    //                            nb. based on track index, so [right, left]
    metronomeOn: initialMetronomeOn,
    midiInputDeviceName: initialMidiInputDeviceName,
    midiAccessStatus: MIDI_ACCESS_STATUS.NEW,
    isMidiConnected: false,
    isMidiConfigOpen: false,
    isEnableAudioOpen: false,
    isScorePopupOpen: false,
}

const appContextReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PLAYBACK_MANAGER':
            return { ...state, playbackManager: action.payload }
        case 'SET_PLAYBACK_STATE':
            return { ...state, playbackState: action.payload }
        case 'SET_PLAYHEAD_MAX':
            return { ...state, playheadMax: action.payload }
        case 'SET_LOOP_RANGE':
            return { ...state, loopRange: action.payload }
        case 'SET_SHOW_NOTE_NAMES':
            return { ...state, showNoteNames: action.payload }
        case 'SET_SHOW_PIANO':
            return { ...state, showPiano: action.payload }
        case 'SET_VIEW_MODE':
            state.playbackManager.setViewMode(action.payload, state.viewMode)
            return { ...state, viewMode: action.payload, showPiano: showPiano(action.payload) }
        case 'NEXT_VIEW_MODE':
            const newIndex = (viewModes.indexOf(state.viewMode) + 1) % 3
            const newMode = viewModes[newIndex]
            state.playbackManager.setViewMode(newMode, state.viewMode)
            return { ...state, viewMode: newMode, showPiano: showPiano(newMode) }
        case 'SET_PLAY_MODE':
            state.playbackManager.setPlayMode(action.payload)
            return {
                ...state,
                playMode: action.payload,
                metronomeOn: action.payload === PLAY_MODE.WAIT ? false : state.metronomeOn  // no metronome in Wait mode
            }
        case 'SET_PLAY_STAVES':
            state.playbackManager.playStaves = action.payload
            return { ...state, playStaves: action.payload }
        case 'SET_METRONOME':
            state.playbackManager.metronomeOn = action.payload
            return { ...state, metronomeOn: action.payload }
        case 'SET_MIDI_CONFIG_OPEN':
            return { ...state, isMidiConfigOpen: action.payload }
        case 'SET_MIDI_INPUT_DEVICE':
            return { ...state, midiInputDeviceName: action.payload }
        case 'SET_MIDI_ACCESS_STATUS':
            return { ...state, midiAccessStatus: action.payload }
        case 'SET_MIDI_CONNECTED':
            return { ...state, isMidiConnected: action.payload }
        case 'SET_ENABLE_AUDIO_OPEN':
            return { ...state, isEnableAudioOpen: action.payload }
        case 'SET_SCORE_POPUP_OPEN':
            return { ...state, isScorePopupOpen: action.payload }
        default:
            throw new Error(`Unrecognised action type: ${action.type}`)
    }
}

const notesContextInitialState = {
    playerKeys: [],       // keys the audio player is playing for the right-hand staff (midi numbers)
    userKeys: [],         // keys the user is playing on a physical midi keyboard
    hintKeys: [],
}

const notesContextReducer = (state, action) => {
    switch (action.type) {
        case 'NOTE_ON_PLAYER':
            return { ...state, playerKeys: [...state.playerKeys, action.payload] }
        case 'NOTE_OFF_PLAYER':
            return { ...state, playerKeys: state.playerKeys.filter(n => !(n.pitchMidi === action.payload.pitchMidi)) }
        case 'SET_PLAYER_KEYS':
            return { ...state, playerKeys: action.payload }

        case 'NOTE_ON_USER':
            return { ...state, userKeys: [...state.userKeys, action.payload] }
        case 'NOTE_OFF_USER':
            return { ...state, userKeys: state.userKeys.filter(n => !(n.pitchMidi === action.payload.pitchMidi)) }
        case 'SET_USER_KEYS':
            return { ...state, userKeys: action.payload }

        case 'SET_HINT_KEYS':
            return { ...state, hintKeys: action.payload }

        default:
            throw new Error(`Unrecognised action type: ${action.type}`)
    }
}



const StateProvider = ({ children }) => {

    const [state, dispatch] = useReducer(appContextReducer, appContextInitialState)
    const [notesContextState, notesContextDispatch] = useReducer(notesContextReducer, notesContextInitialState)
    const [playhead, setPlayhead] = useState(0)

    useEffect(
        () => {
            setupPlayback(
                dispatch,
                notesContextDispatch,
                setPlayhead,
                initialViewMode,
                initialPlayMode,
                initialMetronomeOn,
                initialMidiInputDeviceName,
            )
        }, []
    )

    useEffect(() => {
        localStorage.setItem('showNoteNames', JSON.stringify(state.showNoteNames));
    }, [state.showNoteNames])

    useEffect(() => {
        localStorage.setItem('viewMode', state.viewMode);
    }, [state.viewMode])

    useEffect(() => {
        localStorage.setItem('playMode', state.playMode);
    }, [state.playMode])

    useEffect(() => {
        localStorage.setItem('metronomeOn', state.metronomeOn);
    }, [state.metronomeOn])

    useEffect(() => {
        localStorage.setItem('midiInputDevice', state.midiInputDeviceName);
    }, [state.midiInputDeviceName])

    return (
        <appContext.Provider value={state}>
            <dispatchContext.Provider value={dispatch}>
                <playheadContext.Provider value={{ playhead }}>
                    <notesContext.Provider value={notesContextState}>
                        <setPlayheadContext.Provider value={setPlayhead}>
                            {state.playbackManager && children}
                        </setPlayheadContext.Provider>
                    </notesContext.Provider>
                </playheadContext.Provider>
            </dispatchContext.Provider>
        </appContext.Provider>
    )
}

export default StateProvider

export const useAppState = () => {
    const context = useContext(appContext)
    if (context === undefined) {
        throw new Error('useAppState must be used within a StateProvider')
    }
    return context
}

export function useDispatch() {
    const context = useContext(dispatchContext)
    if (context === undefined) {
        throw new Error('useDispatch must be used within a dispatchContext provider')
    }
    return context
}

export const usePlayhead = () => {
    const context = useContext(playheadContext)
    if (context === undefined) {
        throw new Error('usePlayhead must be used within a StateProvider')
    }
    return context
}

export const useSetPlayhead = () => {   // ???newp do I still need this? Now that it is stored inside PlaybackManager..?
    const context = useContext(setPlayheadContext)
    if (context === undefined) {
        throw new Error('useSetPlayhead must be used within a StateProvider')
    }
    return context
}

export const useNotesPlaying = () => {
    const context = useContext(notesContext)
    if (context === undefined) {
        throw new Error('useNotesPlaying must be used within a StateProvider')
    }
    return context
}
