/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useState } from 'react'
import './App.css';
import './styles/_main.scss';
import { Router } from "@reach/router"

import DebugControls from './components/debug/DebugControls'
import PiecesListFromSrc from './components/debug/PiecesListFromSrc';
import Piece from './components/Piece';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorCpt from './components/ErrorCpt';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import Layout from './components/Layout';
import StateProvider from './components/StateProvider';


const debug = false


function App() {

    const [showVexflowOrImage, setShowVexflowOrImage] = useState('pdf')
    const [showBeats, setShowBeats] = useState(false)
    const { width, height, ref } = useResizeDetector()

    return (
        <div className="App"
            ref={ref}
            css={css`min-height: 100vh;`}
        >
            <ErrorBoundary FallbackComponent={ErrorCpt} >
                <StateProvider >

                    {debug && <DebugControls
                        showVexflowOrImage={showVexflowOrImage}
                        setShowVexflowOrImage={setShowVexflowOrImage}
                        showBeats={showBeats}
                        setShowBeats={setShowBeats}
                    />}

                    <Layout availableWidth={width} availableHeight={height} />

                    <Router>
                        {(process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') &&
                            <PiecesListFromSrc path='/' />  // pieces list not available in production
                        }
                        <PieceWithErrorBoundary
                            path='/piece/:pieceId'
                            availableWidth={width}
                            availableHeight={height}
                            showVexflowOrImage={showVexflowOrImage}
                            showBeats={showBeats}
                        />
                        <ErrorCpt
                            errorMessage={'Sorry! That page doesn\u2019t exist.'}
                            default />
                    </Router>

                </StateProvider>
            </ErrorBoundary>
        </div >
    )
}

export default App


function PieceWithErrorBoundary(props) {
    return (
        <ErrorBoundary FallbackComponent={ErrorCpt} >
            <Piece {...props} />
        </ErrorBoundary>
    )
}
