/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import Vex from 'vexflow';
import { calcStemDirectionForVoice, doBeams, prepStaveNote, prepBarStave, timeSigInEffect, prepDynamic, prepLyrics, recordTies, doBarlinesForSystem, recordSlurs, recordHairpins, drawTies, drawSlurs, drawHairpins } from '../logic/vexflowHelpers';

import { css } from '@emotion/react'


const VF = Vex.Flow;


function VexFlowNotation({
    id,
    bars,
    pageWidth,
    pageHeight,
    availableWidth,
}) {

    const divId = `vf-div-${id}`

    useEffect(
        () => {

            const vfDiv = document.getElementById(divId);

            // Ensure that div is clear (else you can get the whole SVG repeated when you change instrument, for example)
            while (vfDiv.hasChildNodes()) {
                vfDiv.removeChild(vfDiv.lastChild);
            }

            const renderer = new VF.Renderer(vfDiv, VF.Renderer.Backends.SVG);
            const formatter = new VF.Formatter({ softmaxFactor: 5 })
            renderer.resize(pageWidth, pageHeight);
            const VF_context = renderer.getContext();

            let pieceContext = {
                staveOffsetsY: [],
                clefs: {},
                firstBarOfSystem: undefined
            }
            let hairpins = {}, slurs = {}, ties = {}        // record of elements for each stave (i.e. object key is stave Id)

            bars.forEach((bar, barIndex) => {

                if (bar.newSystem || barIndex === 0) {
                    console.log('xx new system', barIndex)
                    console.log('xx', bar.staves)
                    pieceContext.firstBarOfSystem = bar
                }
                const staves = Object.entries(bar.staves)
                staves.forEach(([staveId, stave], staveIndex) => {
                    stave.VF_stave = prepBarStave({ bar, staveId, stave, staveIndex, pieceContext, VF_context })
                })

                const nextBar = bars[barIndex + 1]
                doBarlinesForSystem({ bar, nextBar, VF_context })

                // Make sure the staves have the same starting point for notes
                const VF_staves = Object.values(bar.staves).map(stave => stave.VF_stave)
                const startX = VF_staves.reduce((max, VF_stave) => (Math.max(VF_stave.getNoteStartX(), max)), 0)
                VF_staves.forEach(VF_stave => { VF_stave.setNoteStartX(startX) })

                pieceContext.prevTimeSig = bar.timeSig || pieceContext.prevTimeSig
                pieceContext.prevKeySig = bar.keySig || pieceContext.keySig

            })

            bars.forEach((bar, barIndex) => {

                let VF_voicesMain = [], VF_voicesAux = []
                const timeSig = timeSigInEffect(bars, barIndex) || '4/4'
                const [num_beats, beat_value] = timeSig.split('/')

                const staves = Object.entries(bar.staves)
                staves.forEach(([staveId, stave]) => {

                    const VF_stave = stave.VF_stave
                    stave.voices.forEach((voice, voiceIndex) => {

                        let staveContext = {
                            stemDirectionForVoice: calcStemDirectionForVoice(stave.voices.length, voiceIndex),
                            clef: pieceContext.clefs[staveId]
                        }

                        const VF_newNotes = voice.map(chord => {
                            const VF_newNote = prepStaveNote({ chord, staveContext })
                            chord.setVFstaveNote(VF_newNote)
                            return VF_newNote
                        })
                        const VF_voice = new VF.Voice({ num_beats, beat_value })
                            .setStave(VF_stave)
                            .setStrict(false)                // turn off tick counter
                            .addTickables(VF_newNotes)
                        VF_voicesMain.push(VF_voice)         // keep a list of all vexflow voices..

                        recordTies({ ties, staveId, voice })
                        recordSlurs({ slurs, staveId, voice })
                        recordHairpins({ hairpins, staveId, voice })

                        const VF_newDynamics = voice.map(chord => prepDynamic({ chord }))
                        const VF_voiceForDynamicExpressions = new VF.Voice({ num_beats, beat_value })
                            .setStave(VF_stave)
                            .setStrict(false)
                            .addTickables(VF_newDynamics)
                        VF_voicesAux.push(VF_voiceForDynamicExpressions)

                        let VF_newLyrics = []
                        voice.forEach(chord => {
                            prepLyrics({ VF_newLyrics, chord, VF_context })
                        })
                        VF_newLyrics.forEach((verseOfLyrics) => {
                            const VF_voiceForLyrics = new VF.Voice({ num_beats, beat_value })
                                .setStave(VF_stave)
                                .setStrict(false)
                                .addTickables(verseOfLyrics)
                            VF_voicesAux.push(VF_voiceForLyrics)
                        })
                    })
                })


                // formatter.joinVoices(VF_voices)

                VF_voicesMain.forEach((VF_voice, voiceIndex) => {
                    // ??? should be joining voices on a per-stave basis!
                    formatter.joinVoices([VF_voice])
                    // formatter.joinVoices([VF_voice, VF_voicesAux[voiceIndex]])
                })
                formatter.formatToStave(VF_voicesMain, VF_voicesMain[0].stave)  // only need one stave as they're same width
                formatter.formatToStave(VF_voicesAux, VF_voicesMain[0].stave)


                VF_voicesMain.forEach((VF_voice, voiceIndex) => {
                    const VF_stave = VF_voice.stave
                    const numVoicesInStave = VF_voicesMain.filter(v => v.stave === VF_stave).length
                    let stemDirectionForVoice = calcStemDirectionForVoice(numVoicesInStave, voiceIndex)
                    // ??? ah: stemDirectionForVoice needs number of voices *on this stave*
                    // not the total number of voices across all staves // FIXED - but TEST!

                    // ??? todo: specified (not voice-dependent) stem direction for beamed notes

                    doBeams({ VF_context, VF_stave, timeSig, VF_voice, stemDirectionForVoice })
                    if (!VF_voice.rendered) {
                        // Seems I *have* to render voice after beams are added but before beams are rendered.
                        // But feels wrong to only have voice render within doBeams() so do it now (but don't double-
                        // render as that looks messy).
                        VF_voice.draw(VF_context, VF_stave);
                    }
                })

                VF_voicesAux.forEach(VF_voice => {
                    const VF_stave = VF_voice.stave
                    if (!VF_voice.rendered) {
                        VF_voice.draw(VF_context, VF_stave)
                    }
                })

            })   // bars-in-piece loop

            drawTies(ties, VF_context)
            drawSlurs(slurs, VF_context)
            drawHairpins(hairpins, VF_context)

        },
        [divId, bars, pageWidth, pageHeight, availableWidth]
    )


    return (
        <div
            id={divId}
            css={css`
            width: ${pageWidth}px;
            height: ${pageHeight}px;
            /* background-color: #333333; */
            `}
        />
    )
}



export default VexFlowNotation

