import { PLAY_MODE } from "../components/StateProvider"

const USER_INSTRUMENT_NAME = 'PianoBP-User'

export const MIDI_ACCESS_STATUS = {
    NEW: 'NEW',
    AWAITING: 'AWAITING_ACCESS',
    SUCCESS: 'MIDI_ACCESS_SUCCESS',
    FAILURE: 'MIDI_ACCESS_FAILURE',
}


export class MidiDeviceManager {
    constructor(
        audioPlayer,
        setMidiAccessStatus,
        setMidiConnected,
        setEnableAudioOpen,
        setPlayMode,
        prevMidiInputDeviceName,
        prevPlayMode
    ) {
        this.audioPlayer = audioPlayer
        this.currentlyPressedKeys = []
        this.noteOnCallback = null
        this.noteOffCallback = null
        this.onMIDISuccess = this.onMIDISuccess.bind(this)
        this.onMIDIFailure = this.onMIDIFailure.bind(this)
        this.getMIDIMessage = this.getMIDIMessage.bind(this)
        this.midiInputs = []
        this.inputDevice = null
        this._midiAccessStatus = MIDI_ACCESS_STATUS.NEW
        this.setMidiAccessStatus = setMidiAccessStatus
        this._isMidiConnected = false
        this.setMidiConnected = setMidiConnected
        this.setEnableAudioOpen = setEnableAudioOpen
        this.setPlayMode = setPlayMode
        this.prevMidiInputDeviceName = prevMidiInputDeviceName
        this.prevPlayMode = prevPlayMode
        this.refresh = this.refresh.bind(this)
        this.refresh()
    }

    get midiAccessStatus() {
        return this._midiAccessStatus
    }

    set midiAccessStatus(status) {
        this._midiAccessStatus = status
        this.setMidiAccessStatus(status)
    }

    get isMidiConnected() {
        return this._isMidiConnected
    }

    set isMidiConnected(status) {
        this._isMidiConnected = status
        this.setMidiConnected(status)
    }

    async refresh() {
        this.midiInputs = []
        this.midiAccessStatus = MIDI_ACCESS_STATUS.AWAITING

        // timeout for UI purposes only - i.e. ensure the spinner briefly shows
        setTimeout(
            () => { navigator.requestMIDIAccess().then(this.onMIDISuccess, this.onMIDIFailure) },
            200
        )
    }

    onMIDISuccess(midiAccess) {
        this.midiInputs = []
        for (const input of midiAccess.inputs.values()) {
            this.midiInputs.push(input)
        }
        this.midiAccessStatus = MIDI_ACCESS_STATUS.SUCCESS
        if (this.midiInputs.map(i => i.name).includes(this.prevMidiInputDeviceName)) {
            this.connectInputDevice(this.prevMidiInputDeviceName)
            // Want playMode to be persistent between sessions - but only if we automatically connect to a midi device.
            // If not then it'll revert to Normal mode.
            if (this.prevPlayMode) {
                this.setPlayMode(this.prevPlayMode)
            }
        }
        else {
            // Setting to playMode from last session is a one-time opportunity - and if we can't connect automatically
            // to a device then we should forget the previous play mode.
            this.prevPlayMode = null
        }
    }

    onMIDIFailure() {
        this.midiAccessStatus = MIDI_ACCESS_STATUS.FAILURE
        console.warn('Could not access MIDI devices.')
    }

    connectInputDevice(deviceName) {
        this.disconnectAll()
        this.inputDevice = this.midiInputs.find(i => i.name === deviceName)
        this.inputDevice.onmidimessage = this.getMIDIMessage
        this.isMidiConnected = true
    }

    disconnectAll() {
        this.inputDevice = null
        for (const input of this.midiInputs) {
            input.onmidimessage = null
        }
        this.isMidiConnected = false
        this.setPlayMode(PLAY_MODE.NORMAL)
    }

    getMIDIMessage(message) {
        const command = message.data[0];
        const pitchMidi = message.data[1];
        const velocity = (message.data.length > 2) ? message.data[2] : 0; // a velocity value might not be included with a noteOff command
        switch (command) {
            case 144: // noteOn
                if (velocity > 0) {
                    this.handleNoteOn(pitchMidi, velocity)
                }
                else {
                    this.handleNoteOff(pitchMidi)
                }
                break;
            case 128: // noteOff
                this.handleNoteOff(pitchMidi)
                break;
            default:
                ;    // otherwise do nothing
        }

    }

    handleNoteOn(pitchMidi, velocity) {
        const audioContext = this.audioPlayer.getTone().context._context
        if (audioContext.state !== 'running') {
            this.setEnableAudioOpen(true)
        }

        this.audioPlayer.startNote(pitchMidi, velocity / 127, USER_INSTRUMENT_NAME)
        this.currentlyPressedKeys.push(pitchMidi)
        this.currentlyPressedKeys = Array.from(new Set(this.currentlyPressedKeys))
        this.noteOnCallback && this.noteOnCallback(pitchMidi, velocity)
    }

    handleNoteOff(pitchMidi) {
        this.audioPlayer.stopNote(pitchMidi, USER_INSTRUMENT_NAME)
        this.currentlyPressedKeys = this.currentlyPressedKeys.filter(p => p !== pitchMidi)
        this.noteOffCallback && this.noteOffCallback(pitchMidi)
    }
}

