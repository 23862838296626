export const PAGE_HEIGHT_WIDTH_RATIO = 1.4142      // nb. this is A4 ratio; US Letter is different...
export const PAGE_TOP_MARGIN = 60
export const PAGES_VERTICAL_GAP = 5
export const PLAYBACK_CONTROLS_HEIGHT = 61
export const WIDTH_TABLET = 820
export const WIDTH_XL = 1200

// constants for the piano keyboard
export const WHITE_KEY_WIDTH_PX = 34
export const BLACK_KEY_WIDTH_PX = WHITE_KEY_WIDTH_PX * 0.65
// ...Nb. this ratio seems to be hard-coded into react-piano, so this value is purely for reference by
// MidiWaterfall.js so that it can set the width of the note-bars appropriately.

export const KEY_HEIGHT_PX = 150
export const KEY_FROM_MIDI = 21   // A0
export const KEY_TO_MIDI = 108    // C8 - i.e. a full piano keyboard
