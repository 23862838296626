
export class TimeSig {
    constructor([top, bottom], ppq = 960) {
        this.top = top
        this.bottom = bottom
        this.ppq = ppq
    }

    static default() {
        return new TimeSig([4, 4])
    }

    toString() {
        return `${this.top}/${this.bottom}`
    }

    get barLengthQn() {
        return this.top * 4 / this.bottom
    }

    get barLengthTicks() {
        return this.barLengthQn * this.ppq
    }

}

