import React, { useEffect, useState } from 'react'

import { useAppState, useDispatch, VIEW_MODE } from './StateProvider';
import { loadPiece } from '../logic/loadPiece'

import ErrorOnLoad from './ErrorOnLoad'
import PieceAsPdf from './PieceAsPdf'


const Piece = ({
    pieceId,
    availableWidth,
    availableHeight,
    showVexflowOrImage,
    showBeats
}) => {

    const [loadingStatus, setLoadingStatus] = useState({ status: 'LOADING' })
    // loadPiece uses asynchronous code, so need slightly convoluted way for any errors to propagate so that error boundary
    // (in App.js) will catch them...
    const [_, setError] = useState()    // eslint-disable-line no-unused-vars
    const throwError = (e) => setError(() => { throw e })

    const [piece, setPiece] = useState(null)
    const [pdfImages, setPdfImages] = useState([])
    const { playbackManager, viewMode } = useAppState()
    const dispatch = useDispatch()

    useEffect(
        () => {
            const setPlayheadMax = payload => dispatch({ type: 'SET_PLAYHEAD_MAX', payload })
            const setPlaybackState = payload => dispatch({ type: 'SET_PLAYBACK_STATE', payload })
            const clearLoopRange = () => {
                dispatch({ type: 'SET_LOOP_RANGE', payload: [-1, -1] })
                playbackManager.setLoopRange([-1, -1])
            }

            loadPiece({
                pieceId,
                setPlaybackState,
                setPdfImages,
                setPiece,
                playbackManager,
                setPlayheadMax,
                clearLoopRange,
                setLoadingStatus,
                throwError
            })

            return () => {
                setPlaybackState(null)
            }
        },
        [pieceId, playbackManager, dispatch]
    )

    useEffect(
        () => { document.title = piece?.title || 'Sheet music player' }, [piece]
    )

    return (
        loadingStatus.status === 'ERROR' ? <ErrorOnLoad response={loadingStatus.response} /> :
            [VIEW_MODE.PDF, VIEW_MODE.PDF_PIANO].includes(viewMode) ?
                <PieceAsPdf
                    piece={piece}
                    pdfImages={pdfImages}
                    loadingStatus={loadingStatus}
                    availableWidth={availableWidth}
                    availableHeight={availableHeight}
                    showVexflowOrImage={showVexflowOrImage}
                    showBeats={showBeats}
                />
                : null
    )
}

function areEqual(prev, next) {
    if (prev.pieceId !== next.pieceId) {
        return false
    }
    if (prev.availableWidth !== next.availableWidth) {
        return false
    }
    if (prev.availableHeight !== next.availableHeight) {
        return false
    }
    if (prev.showVexflowOrImage !== next.showVexflowOrImage) {
        return false
    }
    if (prev.showBeats !== next.showBeats) {
        return false
    }
    return true
}

// TODO: I'm not sure this memoisation is necessary - but don't have time to investigate thoroughly so
// leaving for now...
export default React.memo(Piece, areEqual)
// export default Piece