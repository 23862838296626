/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'


export const SMALL_CONTROL_H = '40px'


export const disabledCss = css`
        opacity: 0.3;
`

export const baseControlCss = css`
        transition: all 0.15s;
        &:disabled{
            ${disabledCss}
        }
`

export const smallControlCss = css`
    height: ${SMALL_CONTROL_H}; padding: 1px 0px;
    font-size: 1.1rem;
`

export function RowOfButtons({ children }) {
    return (
        <div
            css={css`
        display: flex;
        align-items: center;
        width: 100%;
        padding-top: 2em;
        font-size: 1rem;
        color: hsla(0deg, 0%, 40%, 1);
        justify-content: flex-start;
        &>* {
            margin-right: 0.5em;
        }`
            }>
            {children}
        </div>
    )
}

