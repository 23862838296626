import { KEY_HEIGHT_PX, PAGES_VERTICAL_GAP, PLAYBACK_CONTROLS_HEIGHT } from '../logic/constants'
import { pageTopMargin } from '../logic/util';
import { SvgLayerUser } from './SvgLayerUser';


const CURSOR_WIDTH = 14
const CURSOR_OVERHANG = 14   // how much the cursor sticks up above the top staff line (and below bottom)
const CURSOR_CORNER_RADIUS = 4
const CURSOR_OFFSET_X = -CURSOR_WIDTH * 0.9

const SCROLL_TOP_MARGIN = 60
const SCROLL_BOTTOM_MARGIN = 150
const SHORT_SCREEN_HEIGHT_PX = 480

class Cursor extends SvgLayerUser {

    static COLOUR = {
        NORMAL: "hsla(120deg,70%,50%,0.5)",
        INACTIVE: "hsla(0deg,70%,50%,0.5)"
    }

    constructor(playbackManager) {
        super(playbackManager, 'svg-cursor')
    }

    init(pageHeight) {
        super.init(pageHeight)
        this.cursorElt = null
        this._colour = Cursor.COLOUR.NORMAL
    }

    async reset(point) {
        await this.setupSvgElts()
        if (point) {
            this.createCursorElt(point)
        }
    }

    get isReady() {
        return this.svgElts.length && !!this.cursorElt
    }

    async createCursorElt(point) {
        this.currPageId = point.bar.page
        if (!this.svgElts.length) {
            await this.setupSvgElts()
        }
        const svgns = "http://www.w3.org/2000/svg"
        this.clear()
        this.cursorElt = document.createElementNS(svgns, "rect")
        this.cursorElt.setAttribute("x", point.noteX + CURSOR_OFFSET_X)
        this.cursorElt.setAttribute("y", point.bar.pos.y - CURSOR_OVERHANG)
        this.cursorElt.setAttribute("width", CURSOR_WIDTH)
        this.cursorElt.setAttribute("height", point.bar.pos.h + 2 * CURSOR_OVERHANG)
        this.cursorElt.setAttribute("rx", CURSOR_CORNER_RADIUS)
        this.cursorElt.setAttribute("fill", this._colour)
        this.cursorElt.setAttribute("data-cy", "playback-cursor")
        this.svgElts[this.currPageId].appendChild(this.cursorElt)
    }

    get colour() {
        return this._colour
    }

    set colour(colour) {
        this._colour = colour
        if (this.cursorElt) {
            this.cursorElt.setAttribute("fill", this._colour)
        }
    }

    setYFromPoint(point) {
        if (!this.cursorElt) {
            return
        }
        if (point.bar.page !== this.currPageId) {
            this.currPageId = point.bar.page
            this.createCursorElt(point)
        }
        else {
            this.cursorElt.setAttribute('y', point.bar.pos.y - CURSOR_OVERHANG)
            this.cursorElt.setAttribute('height', point.bar.pos.h + 2 * CURSOR_OVERHANG)
        }
    }

    setX(x) {
        if (!this.isReady) {
            return
        }
        this.cursorElt.setAttribute('x', x + CURSOR_OFFSET_X)
    }

    scrollIntoView(lookaheadPoint, behavior = 'smooth') {
        if (!this.isReady) {
            return
        }
        if (!lookaheadPoint) {           // can happen at end of piece
            return
        }
        const bar = lookaheadPoint.bar
        const bottomOfViewPort = window.innerHeight + window.scrollY - (this.playbackManager.isPianoVisible ? KEY_HEIGHT_PX : 0)
        const topOfViewPort = window.scrollY
        const isShortScreen = window.innerHeight <= SHORT_SCREEN_HEIGHT_PX

        const scrollDownAmount = Math.round(
            PLAYBACK_CONTROLS_HEIGHT + pageTopMargin() + (
                (this.pageHeight + PAGES_VERTICAL_GAP) * bar.page
                + bar.pos.y + bar.pos.h
                + (isShortScreen ? 50 : SCROLL_BOTTOM_MARGIN)   // see comment below
            ) * this.zoom
            - bottomOfViewPort
        )
        const scrollUpAmount = Math.round(
            topOfViewPort - (
                (this.pageHeight + PAGES_VERTICAL_GAP) * bar.page
                + bar.pos.y
                - (isShortScreen ? 20 : SCROLL_TOP_MARGIN)   // see comment below
            ) * this.zoom
        )
        // ...Can potentially get a scroll up/down 'flicker' on a landscape phone - so avoid this by reducing the
        // SCROLL_BOTTOM/TOP_MARGINs on short screens. (Could refine the presentation with cleverer maths but just
        // doing enough to avoid the flicker for now.)

        if (scrollDownAmount > 0) {
            window.scrollBy({ top: scrollDownAmount, behavior })
        }
        else if (scrollUpAmount > 0) {
            window.scrollBy({ top: -scrollUpAmount, behavior })
        }
    }

}


export { Cursor, CURSOR_WIDTH, CURSOR_OVERHANG, CURSOR_OFFSET_X }
