/** @jsxImportSource @emotion/react */

import { useDispatch, useAppState, PLAY_MODE } from '../StateProvider'
import { ReactComponent as MetronomeIcon } from '../../icons/metronome.svg'
import { PressButton } from './Button'


export function MetronomeButton({ ...props }) {
    const { playbackState, metronomeOn, playMode } = useAppState()
    const dispatch = useDispatch()
    const isEnabled = playbackState && playbackState !== 'LOADING' && playMode !== PLAY_MODE.WAIT
    const handleMetronomeClick = () => {
        dispatch({ type: 'SET_METRONOME', payload: !metronomeOn })
    }
    return (
        <PressButton
            id='metronome-button'
            onClick={handleMetronomeClick}
            disabled={!isEnabled}
            isPressed={metronomeOn}
            {...props}
        >
            <MetronomeIcon />
        </PressButton>
    )
}

