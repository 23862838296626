import { plainToGlyph } from "../music-util/glyphs"
import { KeyEvent } from "./KeyPress"

const MAX_MATCH_ERROR_BEATS = 0.5  // leeway when looking for matches between notation notes and midi notes
//                                 // won't always be exact because of (e.g.) swing


export class MidiEvent {
    constructor({ ticks, durationTicks, midi, pitch }) {
        this.ticks = ticks
        this.beat = null
        this.durationTicks = durationTicks
        this.midi = midi
        this.pitchClass = pitch
        this.playbackPoint = null
    }
}

export class MidiTrack {
    constructor(MidiComposition, track, durationTicks) {
        this.MidiComposition = MidiComposition
        this.events = track.map(e => new MidiEvent(e))
        this.durationTicks = durationTicks
    }

    get isRH() {
        return this === this.MidiComposition.trackRH
    }

    get isLH() {
        return this === this.MidiComposition.trackLH
    }

    findEventIndexByTick(tick, { exact = true } = {}) {
        let i = 0
        let j = this.events.length - 1
        let best = { index: -1, difference: Infinity }
        while (i <= j) {
            const index = Math.floor((i + j) / 2)
            const e = this.events[index]
            const difference = e.ticks - tick
            if (Math.abs(difference) < best.difference) {
                best = { index, difference: Math.abs(difference) }
            }
            if (difference === 0) {
                break
            }
            if (difference >= 0) {
                j = index - 1
            }
            else {
                i = index + 1
            }
        }

        if (exact && best.difference !== 0) {
            return -1
        }
        return best.index
    }

    // Find midi event which:
    // - has the given pitch
    // - is 'close to' the given tick-for-notation position (allow for error caused by e.g. swing)
    // - is not already matched up
    findMatch(tickNotation, pitchMidi) {
        const searchInDirection = (fromIndex, { direction }) => {
            const eClosest = this.events[fromIndex]
            let j = fromIndex
            while (true) {
                const event = this.events[j]
                if (!event || (Math.abs(event.beat - eClosest.beat) > MAX_MATCH_ERROR_BEATS)) {
                    return { event: null, tickError: Infinity }  // ran out of events, or out of range to be a match
                }
                if (event.midi === pitchMidi && !event.playbackPoint) {
                    return { event, tickError: Math.abs(event.ticks - eClosest.ticks) }
                }
                j += direction
            }
        }

        let iClosest = this.findEventIndexByTick(tickNotation, { exact: false })
        const matchOnOrAfter = searchInDirection(iClosest, { direction: 1 })
        const matchBefore = searchInDirection(iClosest - 1, { direction: -1 })
        const matchBest = matchBefore.tickError < matchOnOrAfter.tickError ? matchBefore : matchOnOrAfter
        return matchBest.event  // will be null if no match
    }

    // set pitch classes for events which were not matched with playback points
    // (e.g. events in a trill)
    findPitchesForUnmatchedEvents(playbackMap) {
        this.events
            .filter(e => !e.playbackPoint)
            .forEach(e => {
                const pitchClass = playbackMap.findPitchClass(e.ticks, e.midi) || this.pitch
                e.pitchClass = pitchClass ? plainToGlyph(pitchClass) : ''
            })
    }

    setBeats(ppq) {
        this.events.forEach(e => {
            e.beat = e.ticks / ppq
        })
    }

    clearPlaybackPoints() {
        this.events.forEach(e => e.playbackPoint = null)
    }
}


export class MidiComposition {
    constructor(midiHeader, midiTracks) {
        // console.log(midiHeader, midiTracks)
        this.ppq = midiHeader.ppq
        this.tracks = midiTracks.map(t => new MidiTrack(this, t.notes, t.durationTicks))
        this.numTracks = this.tracks.length
        while (this.tracks.length < 2) {
            this.tracks.push(new MidiTrack(this, [], 0))  // add a dummy track (but leave this.numTracks as the 'true' value)
        }
        this.setBeats()
        this.indexRH = 0
        this.indexLH = 1
        KeyEvent.trackIndexRH = this.indexRH
        KeyEvent.trackIndexLH = this.indexLH
    }

    setBeats() {
        this.tracks.forEach(t => t.setBeats(this.ppq))
    }

    findPitchesForUnmatchedEvents(playbackMap) {
        this.tracks.forEach(t => t.findPitchesForUnmatchedEvents(playbackMap))
    }

    clearPlaybackPoints() {
        this.tracks.forEach(t => t.clearPlaybackPoints())
    }

    get trackRH() {
        return this.tracks[this.indexRH]
    }

    get trackLH() {
        return this.tracks[this.indexLH]
    }

    get durationTicks() {
        return Math.max(...this.tracks.map(t => t.durationTicks))
    }

    flipTracks() {
        [this.indexRH, this.indexLH] = [this.indexLH, this.indexRH]
        KeyEvent.trackIndexRH = this.indexRH
        KeyEvent.trackIndexLH = this.indexLH
    }

}
