/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useEffect } from 'react';
import { useAppState } from './StateProvider';


const MidiWaterfall = ({
    noteRange, // ={{ first: KEY_FROM_MIDI, last: KEY_TO_MIDI }}
    width,
    height,
    bottom,
}) => {

    const { playbackManager } = useAppState()

    useEffect(
        () => { playbackManager.midiWaterfall.setDimensions() },
        [playbackManager.midiWaterfall, height, width]
    )
    // ??? also set note range from here? (*only* from here..?)

    return (
        width > 0 && height > 0 ? (
            <div
                id='midi-waterfall-container'
                css={css`
            position: absolute;
            bottom: ${bottom}px;
            left: 0;
            width: ${width}px;
            height: ${height}px;
            background-color: #202020;
            cursor: grab;
            overflow-y: scroll;
            ::-webkit-scrollbar {display: none;}
            `}
            >
            </div>
        )
            : null
    )
}

export default MidiWaterfall
