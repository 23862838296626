import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // Strict mode causes Piece cpt to mount, unmount, and mount again, which leads to a situation where the first
  // loadPiece() call has completed so the Play button is enabled, but then you may get a glitch if you play as the
  // second loadPiece() call is in progress. These double mounts should only happen in development, not production, 
  // but still it's very confusing, and one might waste time hunting for the bug...
  //  
  // Didn't find a way to fix this in the code yet, so I'm just disabling strict mode for now.

  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
