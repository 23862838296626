import Vex from 'vexflow';

const VF = Vex.Flow;


export const ARTICULATION = {
  staccato: 'a.',
  staccatissimo: 'av',
  accent: 'a>',
  tenuto: 'a-',
  marcato: 'a^',
  left_hand_pizz: 'a+',
  snap_pizz: 'ao',
  harmonic_natural: 'ah',
  fermata_above: 'a@a',
  fermata_below: 'a@u',
  up_bow: 'a|',
  down_box: 'am',
  choked: 'a,',
};

export const CONNECTORS = {
  'single-right': VF.StaveConnector.type.SINGLE_RIGHT,
  'double': VF.StaveConnector.type.THIN_DOUBLE,
  'end': VF.StaveConnector.type.BOLD_DOUBLE_RIGHT,
  'invisible': VF.StaveConnector.type.NONE,
}

export const BARLINES = {
  single: VF.Barline.type.SINGLE,
  double: VF.Barline.type.DOUBLE,
  end: VF.Barline.type.END,
  repeatBegin: VF.Barline.type.REPEAT_BEGIN,
  repeatEnd: VF.Barline.type.REPEAT_END,
  repeatBoth: VF.Barline.type.REPEAT_BOTH,
  none: VF.Barline.type.NONE,
}
