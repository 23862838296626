/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'


// Invisible layer which contains the SVG component where the playback cursor will be animated.
// src/playback/Cursor.js does the animation.
// There should be one of these layers per page of the sheet music PDF.

const CursorLayer = ({
    pageNum,
    pageWidth,
    pageHeight
}) => {

    return (
        <div
            id={`cursor-overlay`}
            css={css`
                position: absolute;
                display: block;
                top: 0px;
                left: 0px;
                width: ${pageWidth}px;
                height: ${pageHeight}px;
                color: hsla(40deg, 50%, 50%, 1);
                z-index: 12;
                mix-blend-mode: multiply;
            `}
        >
            <svg id={`svg-cursor-${pageNum}`}
                height="100%"
                width="100%"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                {/* <text x="50" y="20" fill="#508f26">PlaybackCursorLayer</text> */}
            </svg>
        </div>
    )
}

export default CursorLayer
