/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'


const DebugPlaybackMap = ({ pageNum, playbackMap }) => {
    const fromIndex = playbackMap.points.findIndex(point => point.bar.page === pageNum)
    const toIndex = playbackMap.points.findIndex(point => point.bar.page === pageNum + 1)
    const playbackPointsOnPage = playbackMap.points.slice(fromIndex, toIndex)

    return (
        playbackPointsOnPage.map(point => {
            return (
                <Point
                    key={`${point.beat}-${point.noteX}`}
                    point={point}
                />
            )
        })
    )
}

export default DebugPlaybackMap



const Point = ({
    point
}) => {

    const isOnBeat = Number.isInteger(point.beat)
    let iteration = point.repeatNum ? point.repeatNum - 1 : 0
    iteration = point.dcOrDs ? 2 : iteration

    return (
        <div
            css={css`
                position: absolute;
                display: block;
                top: ${point.bar.pos.y}px;
                left: ${point.noteX}px;
                height: ${point.bar.pos.h}px;
                width: 1px;
                z-index: 11;
                background-color: #ff00007a;
                color: red;
                /* opacity: 0.5; */
            `}
        >
            <div
                css={css`
            position: absolute;
            display: block;
            top: ${(isOnBeat ? 50 : 70) + (iteration * 22)}px;
            font-size: 12px;
            ${isOnBeat ? 'font-weight: 500;' : 'font-size: 12px;'}
            background-color: hsla(0deg,0%,100%,1);
            backdrop-filter: blur(10px);
            /* opacity: 0.6; */
            border: 1px solid #ccc;
            `}
            >
                {Math.round(point.beat * 100) / 100}
                {point.repeatNum &&
                    <span css={css`
                font-size: 80%;
                color: #843ebd;
                `}>
                        [{point.repeatNum}]
                    </span>
                }
                {point.dcOrDs &&
                    <span css={css`
                /* font-size: 80%; */
                color: #88460f;
                `}>
                        {point.dcOrDs}
                    </span>
                }
            </div>
        </div>
    )
}
