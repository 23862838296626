import Vex from 'vexflow';
import { ARTICULATION, BARLINES, CONNECTORS } from '../logic/vexflowTables';


const DYNAMIC_BELOW_DEFAULT_LINE = 11
const HAIRPIN_X_OFFSET_IF_DYNAMIC = 30
const VF_TOP_LINE_NUMBER = 3
const LYRIC_FONT = { family: 'Times New Roman', size: 16, weight: '' }

const VF = Vex.Flow;



export function prepBarStave({ bar, staveId, stave, staveIndex, pieceContext: p, VF_context }) {

    p.staveOffsetsY = bar.staveOffsetsY || p.staveOffsetsY
    const stavePos = p.firstBarOfSystem.staves[staveId].pos

    let VF_stave = new VF.Stave(
        bar.pos.x,
        bar.pos.y + (stavePos.offsetY || 0),
        bar.pos.w,
        {
            left_bar: false,
            right_bar: false,
            space_above_staff_ln: 0,      // in staff lines
            space_below_staff_ln: 0,
        }
    )

    if (bar.timeSig && (bar.timeSig !== p.prevTimeSig)) {
        VF_stave.addTimeSignature(bar.timeSig)
    }

    const clef = stave.clef || p.clefs[staveId] || 'treble'
    if (bar.newSystem || clef !== p.clefs[staveId]) {
        VF_stave.addClef(clef)
        p.clefs[staveId] = clef
    }

    const keySig = bar.keySig || p.prevKeySig || 'C'
    if (bar.newSystem || keySig !== p.prevKeySig) {
        VF_stave.addKeySignature(keySig)
    }

    VF_stave.setContext(VF_context).draw();

    return VF_stave
}

/* ??? TODO:
    o handle connectors as per xml data - currently this fun just does all
    o handle 'both ways' repeat bar
    o handle bracket (as well as braces)
*/
export function doBarlinesForSystem({ bar, nextBar = {}, VF_context }) {

    const staves = Object.values(bar.staves)   // array is handy

    staves.forEach(stave => {
        const VF_stave = stave.VF_stave

        if (bar.leftBarLine === 'repeat') {
            VF_stave.setBegBarType(VF.Barline.type.REPEAT_BEGIN)
        }
        if (bar.rightBarLine === 'repeat') {
            VF_stave.setEndBarType(VF.Barline.type.REPEAT_END)
        }
        VF_stave.setContext(VF_context).draw()
    })

    const numStaves = staves.length
    const VF_topStave = staves[0].VF_stave
    const VF_bottomStave = staves[numStaves - 1].VF_stave

    if (numStaves > 1) {

        if (bar.newSystem) {
            new VF.StaveConnector(VF_topStave, VF_bottomStave)
                .setType(VF.StaveConnector.type.SINGLE_LEFT)
                .setContext(VF_context)
                .draw()
            new VF.StaveConnector(VF_topStave, VF_bottomStave)
                .setType(VF.StaveConnector.type.BRACE)
                .setContext(VF_context)
                .draw()
        }

        if (bar.leftBarLine === 'repeat') {
            new VF.StaveConnector(VF_topStave, VF_bottomStave)
                .setType(VF.StaveConnector.type.BOLD_DOUBLE_LEFT)
                .setContext(VF_context)
                .draw()
        }
        if (bar.rightBarLine === 'repeat') {
            new VF.StaveConnector(VF_topStave, VF_bottomStave)
                .setType(VF.StaveConnector.type.BOLD_DOUBLE_RIGHT)
                .setContext(VF_context)
                .draw()
        }
        else if (!nextBar.leftBarLine) {
            // atm doesn't handle barline types: dashed, tick, short, ..
            const connectorType = CONNECTORS[bar.rightBarLine] || VF.StaveConnector.type.SINGLE_RIGHT
            new VF.StaveConnector(VF_topStave, VF_bottomStave)
                .setType(connectorType)
                .setContext(VF_context)
                .draw()
        }
    }
    else if (numStaves === 1) {
        VF_topStave.setEndBarType(BARLINES[bar.rightBarLine] || VF.Barline.type.SINGLE)
            .draw()
    }
}

export function prepStaveNote({ chord, staveContext: s }) {

    const { stem } = chord

    const stem_direction = stem || s.stemDirectionForVoice
    const auto_stem = stem_direction === undefined ? true : false
    const VF_staveNote = new VF.StaveNote({ ...chord.vfNoteProps, clef: s.clef, auto_stem, stem_direction })

    const dotsCount = chord.dotsCount
    let dotsAdded = 0
    while (dotsAdded < dotsCount) {
        if (dotsCount) VF_staveNote.addDotToAll()
        dotsAdded++
    }

    for (let articulation of chord.articulations || []) {
        const position = s.stemDirectionForVoice ? 3 : 4  // it seems 3 is above note, 4 below
        VF_staveNote.addArticulation(0, new VF.Articulation(ARTICULATION[articulation])
            .setPosition(position))
    }

    // Do processing for each pitch in the chord..
    chord.pitches.forEach((note) => {
        note.accidental && VF_staveNote.addAccidental(0, new VF.Accidental(note.accidental));
    })

    return VF_staveNote
}


export function recordTies({ ties, staveId, voice }) {

    ties[staveId] = ties[staveId] || []
    let tiesForStave = ties[staveId]

    voice.forEach(chord => {
        chord.pitches.forEach((note, noteIndexInChord) => {  // ??? assumption: index matches vf index..?

            // NB. now note.hasTie renamed to tiedFrom
            // and now we have note.tiedTo too.
            // So might be able to improve this code (if it is ever needed at all...)

            // Try to complete any 'open' ties
            // ??? nb. this will complete ties at any distance later in piece, not just from next chord!
            for (let tie of tiesForStave) {
                const { to_VF_staveNote, fromNote } = tie
                if (to_VF_staveNote) {   // tie 'to' already identified, skip it
                    continue
                }
                if (note.pitchClass === fromNote.pitchClass
                    && note.octave === fromNote.octave) {
                    tie.to_VF_staveNote = chord.VF_staveNote
                    tie.toIndex = noteIndexInChord
                    // tie.toNote = note       // don't actually need this
                }
            }
            // Record if note is tied from..
            if (note.hasTie) {
                tiesForStave.push({ from_VF_staveNote: chord.VF_staveNote, fromNote: note, fromIndex: noteIndexInChord })
            }
        })
    })
}


export function recordSlurs({ slurs, staveId, voice }) {
    // ??? assumes no overlapping slurs within a stave (which theoretically could happen in different voices)

    slurs[staveId] = slurs[staveId] || []
    let slursForStave = slurs[staveId]

    voice.forEach(chord => {
        if (chord.slurTo) {
            console.log('slurTo')
            let lastSlur = slursForStave[slursForStave.length - 1]
            if (!lastSlur?.from) {
                console.error(`Note labeled 'slurTo' but can't find corresponding slurFrom`)
            }
            else {
                lastSlur.to = chord
            }
            // console.log(slursForStave)
        }
        if (chord.slurFrom) {
            console.log('slurFrom')
            slursForStave.push({ from: chord })
        }
    })
}

export function recordHairpins({ hairpins, staveId, voice }) {

    hairpins[staveId] = hairpins[staveId] || []
    let hairpinsForStave = hairpins[staveId]

    voice.forEach(chord => {
        if (chord.hairpinTo) {
            let lastHairpin = hairpinsForStave?.pop()
            if (!lastHairpin?.from) {
                console.error(`Note labeled 'hairpinTo' but can't find corresponding hairpinFrom`)
            }
            else {
                lastHairpin.to = chord
            }
            lastHairpin && hairpinsForStave.push(lastHairpin)  // put it back in any case
        }
        if (chord.hairpinFrom) {
            hairpinsForStave.push({ from: chord, ...chord.hairpinFrom })
        }
    })
}


export function prepDynamic({ chord }) {
    // Record dynamic expressions (p, mf etc) in a new VF voice.
    // (If put in same voice as notes they throw off the alignment across voices/staves.)
    const VF_textDynamic = new VF.TextDynamics({
        duration: chord.duration,
        text: chord.dynamic || '',         // need a blank so that subsequent dynamics are aligned correctly
        line: DYNAMIC_BELOW_DEFAULT_LINE,
    })
    return VF_textDynamic

    // Alternative plan for dynamics: use a regular annotation as these don't have duration
    // but (a) would need to bundle a font, or do work to use vexflow's glyphs
    //     (b) not sure if we can finely control the vertical position (e.g. to align dynamics vertically across bars)

    // const dynamicAsAnnotation = new VF.Annotation(chord.dynamic)
    //     .setVerticalJustification(VF.Annotation.VerticalJustify.BOTTOM)
    //     .setFont(DYNAMICS_FONT_FAMILY, DYNAMICS_FONT_SIZE, DYNAMICS_FONT_WEIGHT)
    // VF_staveNote.addAnnotation(0, dynamicAsAnnotation)

}


export function prepLyrics({ VF_newLyrics, chord, VF_context }) {
    // chord.lyrics is an array where each element is a lyric-for-verse-x for that chord - i.e. it's 'vertical'
    // VF_newLyrics will be an array of lyric voices in the bar - i.e. horizontal
    chord.lyrics.forEach((lyric, verseIndex) => {
        const VF_lyric = new VF.TextNote({
            duration: chord.duration,
            text: lyric.text,
            line: VF_TOP_LINE_NUMBER - lyric.y / 10,       // ÷ 10 because y unit is tenths-of-stave-line
            font: LYRIC_FONT,
        })
            .setContext(VF_context)
        VF_newLyrics[verseIndex] = VF_newLyrics[verseIndex] || []
        VF_newLyrics[verseIndex].push(VF_lyric)
    })
}



export function calcStemDirectionForVoice(numVoices, voiceIndex) {
    let stemDirection
    if (numVoices > 1) {
        if (voiceIndex === 0) {
            stemDirection = VF.Stem.UP
        }
        else if (voiceIndex === numVoices - 1) {
            stemDirection = VF.Stem.DOWN
        }
    }
    return stemDirection
}


export function doBeams({ VF_context, VF_stave, timeSig, VF_voice, stemDirection }) {

    let beams = []

    // Vexflow's generateBeams seems to do beaming ok on the whole, though it doesn't beam
    // 4 quavers in 4/4.
    // My attempt (below, commented) does beam 4 quavers in 4/4, but not 2!
    // (Nb. used to be applyAndGetBeams but that changed stem direction indiscriminately.)
    beams = VF.Beam.generateBeams(
        VF_voice.getTickables(),
        {
            maintain_stem_directions: true
        }
    );

    // const groupsLookup = {
    //     '6/8': [beam3],    // for some reason this isn't defaulted in vexflow - or it doesn't work..?
    //     '4/4': [beam4],    // 4/4 beam: in 4s - but ??? if 6 quavers + crotchet, it won't beam the beat 3 quavers..
    //     // Think this is really a vexflow issue. https://github.com/0xfe/vextab/pull/124 - ? though it's vextab...?
    //     // ..anyway, think I should not try to resolve this (unless I do so by fixing vexflow..)
    // }
    // const groups =  groupsLookup[tune.timeSig]
    // const beams = VF.Beam.generateBeams(voice.tickables, { groups });

    VF_voice.draw(VF_context, VF_stave);
    beams.forEach(function (beam) {
        return beam.setContext(VF_context).draw();
    })

}

export function drawTies(ties, VF_context) {
    Object.values(ties).forEach(staveTies => {
        staveTies.forEach(tie => {
            const { from_VF_staveNote, to_VF_staveNote, fromIndex, toIndex } = tie
            if (to_VF_staveNote) {
                drawTie({ VF_context, from: from_VF_staveNote, to: to_VF_staveNote, fromIndex, toIndex })
            }
        })
    })

    function drawTie({ VF_context, from, to, fromIndex, toIndex }) {
        const tie = new VF.StaveTie({
            first_note: from,
            last_note: to,
            first_indices: [fromIndex],
            last_indices: [toIndex],
        })
        tie.setContext(VF_context)
            .draw()
    }
}


export function drawSlurs(slurs, VF_context) {
    Object.values(slurs).forEach(staveSlurs => {
        staveSlurs.forEach(slur => {
            const { from, to } = slur
            drawSlur({ from, to, VF_context, type: 1, position: 1 })
        })
    })

    function drawSlur({ from, to, VF_context, options }) {
        // it seems that vexflow does nothing clever to avoid slur collisions
        // ??? but fix stem direction and then see how bad the problem is
        const curve = new VF.Curve(from.VF_staveNote, to.VF_staveNote, options)
        curve.setContext(VF_context)
        curve.draw()
    }

}

export function drawHairpins(hairpins, VF_context) {
    // hairpins is a map of staves to their list of hairpins
    Object.values(hairpins).forEach(staveHairpins => {
        staveHairpins.forEach(hairpin => {
            // ignore hairpins where we never found the 'to' note
            if (hairpin.to) {
                drawHairpin({ ...hairpin, VF_context })
            }
        })
    })

    function drawHairpin({ from, to, VF_context, type, position = 1, options: optionsIn = {} }) {
        //     options: {
        //         height: 10,
        //         vo: 20, // vertical offset
        //         left_ho: 20, // left horizontal offset
        //         right_ho: -20, // right horizontal offset
        //     }

        let options = {
            ...optionsIn,
            height: 11,              // options don't work if this is undefined..!
            y_shift: 0,
            left_shift_px: 0,
            right_shift_px: 0,
        }

        if (!to) {
            console.warn(`hairpin with no 'to' note`)
        }

        const VF_type = {
            'cresc': 1,
            'dim': 2,
        }[type]

        const hairpin = new VF.StaveHairpin({ first_note: from.VF_staveNote, last_note: to.VF_staveNote }, VF_type)
            .setContext(VF_context)
            .setPosition(position)

        if (from.dynamic) {
            options.left_shift_px = HAIRPIN_X_OFFSET_IF_DYNAMIC
        }
        if (to.dynamic) {
            options.right_shift_px = -HAIRPIN_X_OFFSET_IF_DYNAMIC
        }

        hairpin.setRenderOptions(options);
        hairpin.draw();
    }

}




export function timeSigInEffect(bars, barIndex) {
    if (barIndex < 0) {
        return undefined
    }
    return bars[barIndex].timeSig || timeSigInEffect(bars, barIndex - 1)
}

export function clefInEffect(bars, barIndex) {
    if (barIndex < 0) {
        return undefined
    }
    return bars[barIndex].clef || clefInEffect(bars, barIndex - 1)
}