/** @jsxImportSource @emotion/react */

import React from 'react'
import { css } from '@emotion/react'

import { PLAYBACK_CONTROLS_HEIGHT } from '../../logic/constants'
import { ResetToStartButton } from './ResetToStartButton'
import { PlayButton } from './PlayButton'
import { PlayheadSlider } from './PlayheadSlider'
import { PlayModeControl } from './PlayModeControl'
import { StaffMuteButtons } from './StaffMuteButtons'
import { MetronomeButton } from './MetronomeButton'
import { PlaybackSpeed } from './PlaybackSpeedControl'
import { ShowNoteNamesButton } from './ShowNoteNamesButton'
import { ViewModeButton } from './ViewModeButton'
import { MidiConfig } from '../MidiConfig'


export const PlaybackControls = () => {

    return (
        <React.Fragment>
            <div css={css`
            position: fixed;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            top: 0px;
            left: 0px;
            height: ${PLAYBACK_CONTROLS_HEIGHT}px;
            width: 100%;
            padding: 0 1vw;
            background-color: hsla(0deg, 0%, 60%, 0.7);
            backdrop-filter: blur(3px);
            font-size: 1rem;
            color: hsla(0deg, 0%, 40%, 1);
            z-index: 100;
            justify-content: space-between;
            `}>
                <ControlBarSegment>
                    <ResetToStartButton />
                    <PlayButton />
                </ControlBarSegment>
                <PlayheadSlider />
                <ControlBarSegment>
                    <PlayModeControl />
                    <StaffMuteButtons />
                    <MetronomeButton />
                    <PlaybackSpeed />
                    <ShowNoteNamesButton />
                    <ViewModeButton />
                </ControlBarSegment>
                <MidiConfig />
            </div >
        </React.Fragment>
    )
}

function ControlBarSegment({ children, ...props }) {
    // used to split bar into main 'left' (reset/play) and 'right' (metronome, labels, etc.) parts.

    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                &>*{
                    margin: 0 0.4vw;
                }
            `}
            {...props}
        >
            {children}
        </div >
    )
}

