import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import difference from 'lodash/difference';
import Keyboard from './Keyboard';
import MidiNumbers from './MidiNumbers'

/*
Changes from https://github.com/kevinsqi/react-piano v3.1.3:

activeNotesLH      : added, so can highlight left hand notes differently
activeNotesLowlight: added (grey notes)
hintNotes (+LH)    : little circles
labelsByPitchClass : to label all C keys with 'C', etc. Pass in object like {C: 'C', 'C#': 'C♯/D♭' } (etc.) Values can be JSX.
labelsByMidi       : to label specific keys. Pass in Map like { 60 -> 'middle C' }
playNote           : made optional (cos we're handling audio externally to react-piano)
stopNote           : same - optional
renderNoteLabel    : optional

Possibly playNote, stopNote, renderNoteLabel could be removed altogether...?

*/

class ControlledPiano extends React.Component {
  static propTypes = {
    noteRange: PropTypes.object.isRequired,
    activeNotes: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    activeNotesLH: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    activeNotesLowlight: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    hintNotes: PropTypes.arrayOf(PropTypes.number.isRequired),
    hintNotesLH: PropTypes.arrayOf(PropTypes.number.isRequired),
    labelsByPitchClass: PropTypes.object,
    labelsByMidi: PropTypes.object,
    playNote: PropTypes.func,
    stopNote: PropTypes.func,
    onPlayNoteInput: PropTypes.func.isRequired,
    onStopNoteInput: PropTypes.func.isRequired,
    renderNoteLabel: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    width: PropTypes.number,
    keyWidthToHeight: PropTypes.number,
    keyboardShortcuts: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        midiNumber: PropTypes.number.isRequired,
      }),
    ),
  };

  constructor(props) {
    super(props)
    // if labelsByPitchClass passed in then convert to a Map from pitch indexes (0 to 11) to labels
    // I.e. we want keys to be standardised (e.g. without C#/Db doubt), and pitch index is a good way.
    // I.e. instead of { C -> 'C', 'C#' -> 'Db/C#', etc. } we want { 0 -> 'C', 1 -> 'Db/C#', etc. } 
    if (this.props.labelsByPitchClass) {
      this.labelsByPitchClassIndex = new Map()
      for (const pitchClass in this.props.labelsByPitchClass) {
        this.labelsByPitchClassIndex.set(MidiNumbers.PITCH_INDEXES[pitchClass], this.props.labelsByPitchClass[pitchClass])
      }
    }
  }

  state = {
    isMouseDown: false,
    useTouchEvents: false,
  };

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('keyup', this.onKeyUp);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('keyup', this.onKeyUp);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.activeNotes !== prevProps.activeNotes) {
      this.handleNoteChanges({
        prevActiveNotes: prevProps.activeNotes || [],
        nextActiveNotes: this.props.activeNotes || [],
      });
    }
    if (this.props.activeNotesLH !== prevProps.activeNotesLH) {
      this.handleNoteChanges({
        prevActiveNotesLH: prevProps.activeNotesLH || [],
        nextActiveNotesLH: this.props.activeNotesLH || [],
      });
    }
    if (this.props.activeNotesLowlight !== prevProps.activeNotesLowlight) {
      this.handleNoteChanges({
        prevActiveNotesLowlight: prevProps.activeNotesLowlight || [],
        nextActiveNotesLowlight: this.props.activeNotesLowlight || [],
      });
    }
    if (this.props.hintNotes !== prevProps.hintNotes) {
      this.handleNoteChanges({
        prevHintNotes: prevProps.hintNotes || [],
        nextHintNotes: this.props.hintNotes || [],
      });
    }
    if (this.props.hintNotesLH !== prevProps.hintNotesLH) {
      this.handleNoteChanges({
        prevHintNotesLH: prevProps.hintNotesLH || [],
        nextHintNotesLH: this.props.hintNotesLH || [],
      });
    }
  }

  // This function is responsible for diff'ing activeNotes
  // and playing or stopping notes accordingly.
  handleNoteChanges = ({ prevActiveNotes, nextActiveNotes }) => {
    if (this.props.disabled) {
      return;
    }
    const notesStopped = difference(prevActiveNotes, nextActiveNotes);
    const notesStarted = difference(nextActiveNotes, prevActiveNotes);
    notesStarted.forEach((midiNumber) => {
      this.props.playNote && this.props.playNote(midiNumber);
    });
    notesStopped.forEach((midiNumber) => {
      this.props.stopNote && this.props.stopNote(midiNumber);
    });
  };

  getMidiNumberForKey = (key) => {
    if (!this.props.keyboardShortcuts) {
      return null;
    }
    const shortcut = this.props.keyboardShortcuts.find((sh) => sh.key === key);
    return shortcut && shortcut.midiNumber;
  };

  getKeyForMidiNumber = (midiNumber) => {
    if (!this.props.keyboardShortcuts) {
      return null;
    }
    const shortcut = this.props.keyboardShortcuts.find((sh) => sh.midiNumber === midiNumber);
    return shortcut && shortcut.key;
  };

  onKeyDown = (event) => {
    // Don't conflict with existing combinations like ctrl + t
    if (event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }
    const midiNumber = this.getMidiNumberForKey(event.key);
    if (midiNumber) {
      this.onPlayNoteInput(midiNumber);
    }
  };

  onKeyUp = (event) => {
    // This *should* also check for event.ctrlKey || event.metaKey || event.ShiftKey like onKeyDown does,
    // but at least on Mac Chrome, when mashing down many alphanumeric keystrokes at once,
    // ctrlKey is fired unexpectedly, which would cause onStopNote to NOT be fired, which causes problematic
    // lingering notes. Since it's fairly safe to call onStopNote even when not necessary,
    // the ctrl/meta/shift check is removed to fix that issue.
    const midiNumber = this.getMidiNumberForKey(event.key);
    if (midiNumber) {
      this.onStopNoteInput(midiNumber);
    }
  };

  onPlayNoteInput = (midiNumber) => {
    if (this.props.disabled) {
      return;
    }
    // Pass in previous activeNotes for recording functionality
    this.props.onPlayNoteInput(midiNumber, this.props.activeNotes);
  };

  onStopNoteInput = (midiNumber) => {
    if (this.props.disabled) {
      return;
    }
    // Pass in previous activeNotes for recording functionality
    this.props.onStopNoteInput(midiNumber, this.props.activeNotes);
  };

  onMouseDown = () => {
    this.setState({
      isMouseDown: true,
    });
  };

  onMouseUp = () => {
    this.setState({
      isMouseDown: false,
    });
  };

  onTouchStart = () => {
    this.setState({
      useTouchEvents: true,
    });
  };

  defaultRenderNoteLabel({ midiNumber, isAccidental }) {
    const pitchClassIndex = midiNumber % 12
    const labelByMidi = this.props.labelsByMidi?.get(midiNumber)
    const label = labelByMidi || this.labelsByPitchClassIndex?.get(pitchClassIndex)
    return label ? (
      <div
        className={classNames('ReactPiano__NoteLabel', {
          'ReactPiano__NoteLabel--accidental': isAccidental,
          'ReactPiano__NoteLabel--natural': !isAccidental,
        })}
      >
        {label}
      </div>
    ) : null
  }


  renderNoteLabel = ({ midiNumber, isActive, isAccidental }) => {
    return this.props.renderNoteLabel
      ? this.props.renderNoteLabel({ midiNumber, isActive, isAccidental })
      : this.defaultRenderNoteLabel({ midiNumber, isAccidental })
  };

  render() {
    return (
      <div
        className='ReactPiano__Container'
        style={{ width: '100%', height: '100%' }}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onTouchStart={this.onTouchStart}
        data-testid="container"
      >
        <Keyboard
          noteRange={this.props.noteRange}
          onPlayNoteInput={this.onPlayNoteInput}
          onStopNoteInput={this.onStopNoteInput}
          activeNotes={this.props.activeNotes}
          activeNotesLH={this.props.activeNotesLH}
          activeNotesLowlight={this.props.activeNotesLowlight}
          hintNotes={this.props.hintNotes}
          hintNotesLH={this.props.hintNotesLH}
          className={this.props.className}
          disabled={this.props.disabled}
          width={this.props.width}
          keyWidthToHeight={this.props.keyWidthToHeight}
          gliss={this.state.isMouseDown}
          useTouchEvents={this.state.useTouchEvents}
          renderNoteLabel={this.renderNoteLabel}
        />
      </div>
    );
  }
}

export default ControlledPiano;
