import { PLAY_MODE } from '../components/StateProvider'


export class Scorer {
    constructor(playMode) {
        this.playMode = playMode
        this.numNotesEncountered = 0
        this.numNotesCorrect = 0
        this.isActive = false
        this.reset()
    }

    activate() {
        this.isActive = true
    }

    reset() {
        this.numNotesEncountered = 0
        this.numNotesCorrect = 0
    }

    asPercentage() {
        return this.numNotesCorrect / (this.numNotesEncountered || 1) * 100
    }

    static isScorableMode(playMode) {
        return [PLAY_MODE.CONSTANT].includes(playMode)
    }

}
