import ErrorCpt from './ErrorCpt'


const ErrorOnLoad = ({ response }) => {

    let errorMessage =
        <span>
            Something went wrong loading the piece. Please <a href="https://bollypiano.com/contact" target="_blank" rel="noreferrer">contact us</a> about the problem, or go back to <a href="https://bollypiano.com" target="_blank" rel="noreferrer">Bollypiano.com</a>
        </span>

    let responseDetail = null
    if (response.status === 403) {
        // NB. 403 is an authorisation failure, but at present the backend also returns 403 when the token/timestamp
        // is ok but the piece id is not correct.
        errorMessage =
            <span>Member access through <a href="https://bollypiano.com" target="_blank" rel="noreferrer">Bollypiano.com</a> required.</span>
    }
    else if (response.status === 404) {
        errorMessage =
            <span>Sorry, unable to find a piece with that url. Back to <a href="https://bollypiano.com" target="_blank" rel="noreferrer">Bollypiano.com</a></span>
    }
    else {
        responseDetail = `${response.status} ${response.statusText}`
    }
    const pieceUrl = response.url.split('/').pop()

    return (
        <ErrorCpt>
            <p>{errorMessage}</p>
            <p>{pieceUrl}</p>
            <p>{responseDetail}</p>
        </ErrorCpt>
    )
}

export default ErrorOnLoad
