import Vex from 'vexflow'

import { Note } from './Note'
import { barDurationFromTimeSig } from '../logic/utilsMusic'

const vfPitchOfMiddleStaffLine = {
    'treble': 'B/4',
    'bass': 'D/3',
    'alto': 'C/4',
    'tenor': 'A/3',
}


export class Chord {

    constructor({
        pitches = [],
        clef = 'treble',
        timeSig,
        duration = 'q',
        isRest = false,
        grace = false,
        articulations,
        slurFrom,
        slurTo,
        stem,                      // 1 or -1
        hairpinFrom,               // { type: 'cresc' } or 'dim'
        hairpinTo,                 // true
        dynamic,
        startsOnBeat,
        qnIndex,
        x,
        tuplet,
        lyrics,
        staveBar,
        id,  // ??? need?
    }) {
        this.id = id
        this.staveBar = staveBar
        this.pitches = pitches.map(pitch => {
            return new Note({ ...pitch, chord: this, staveBar })
        })
        this.pitches.sort((a, b) => a.midi - b.midi)  // ensure pitches sorted bottom up
        this.type = isRest ? 'r' : 'n'
        this.clef = clef
        this.timeSig = timeSig
        this.grace = grace
        this.duration = duration.replace(/\./g, 'd')
        this.tuplet = tuplet
        this.durationInBeats = Chord.calcDurationInBeats(this)
        this.startsOnBeat = startsOnBeat   // position in bar
        this.qnIndex = qnIndex             // beat position in whole piece 'on paper' (as opposed to in playback map)
        this.articulations = articulations
        this.slurFrom = slurFrom
        this.slurTo = slurTo
        this.hairpinFrom = hairpinFrom
        this.hairpinTo = hairpinTo
        this.dynamic = dynamic
        this.stem = stem
        this.x = x
        this.lyrics = lyrics || []    // lyrics for this chord, one element per verse
    }

    get isRest() { return this.type === 'r' }

    get noteType() {
        return this.duration.replace(/d*$/, '')
    }

    get dotsCount() {
        // Dot indicated by either a 'd' or a '.'
        const res = this.duration.replace(/.*?([d.]*)$/, (m, p1) => p1).length
        return res
    }

    get highestPitch() {
        // pitches sorted bottom-up in constructor
        return this.pitches.at(-1)
    }

    static calcDurationInBeats({ noteType, isRest, dotsCount, grace, tuplet, timeSig }) {   // noteType is w/h/q/8/etc.
        if (grace) {
            // treat as zero-length for purposes of playback cursor
            // (might need to refine for audio..? There are further musicxml values, but does 
            // sibelius set them??)
            return 0
        }

        // The duration of a whole bar rest is equal to the bar duration from the time sig
        if (isRest && noteType === 'w') {
            const barDuration = barDurationFromTimeSig(timeSig)
            if (barDuration) {
                return barDuration
            }
        }

        const baseDuration = noteType.replace(/.*?(w|h|q|8|16|32|64|128).*/, '$1')
        let baseBeats = 4 / parseInt(Vex.Flow.sanitizeDuration(baseDuration))
        if (tuplet) {
            const [actualNum, normalNum] = tuplet            // tuplet.type is also available
            baseBeats = baseBeats * normalNum / actualNum
        }
        const beats = baseBeats * (1 - 0.5 ** (dotsCount + 1)) / 0.5
        return beats
    }

    get vfNoteProps() {
        const { isRest, clef } = this
        return {
            // clef,  // ??? now I'm sorting this in the VexFlowNotation cpt, so can probably drop clef from this class altogether..
            // ...oh but what about this pitchofmiddlestaffline ting...? vv
            keys: isRest ? [vfPitchOfMiddleStaffLine[clef]] : this.pitches.map(p => p.vfPitch),
            duration: `${this.duration}${this.isRest ? 'r' : ''}`
        }
    }

    get spn() {
        return `${this.pitchClass.replace(/n$/, '')}${this.octave}`
    }

    setVFstaveNote(VF_staveNote) {
        this.VF_staveNote = VF_staveNote
    }

}
