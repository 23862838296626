import React from 'react';
import PropTypes from 'prop-types';
import ControlledPiano from './ControlledPiano';


/*
Changes from https://github.com/kevinsqi/react-piano v3.1.3:

activeNotesLH      : added, so can highlight left hand notes differently
activeNotesLowlight: added (grey notes)
hintNotes (+LH)    : little circles
labelsByPitchClass : to label all C keys with 'C', etc. Pass in object like {C: 'C', 'C#': 'C♯/D♭' } (etc.) Values can be JSX.
labelsByMidi       : to label specific keys. Pass in Map like { 60 -> 'middle C' }
playNote           : made optional (cos we're handling audio externally to react-piano)
stopNote           : same - optional
renderNoteLabel    : optional

Possibly playNote, stopNote, renderNoteLabel could be removed altogether...?

*/

class Piano extends React.Component {
  static propTypes = {
    noteRange: PropTypes.object.isRequired,
    activeNotes: PropTypes.arrayOf(PropTypes.number.isRequired),
    activeNotesLH: PropTypes.arrayOf(PropTypes.number.isRequired),
    activeNotesLowlight: PropTypes.arrayOf(PropTypes.number.isRequired),
    hintNotes: PropTypes.arrayOf(PropTypes.number.isRequired),
    hintNotesLH: PropTypes.arrayOf(PropTypes.number.isRequired),
    labelsByPitchClass: PropTypes.object,
    labelsByMidi: PropTypes.object,
    playNote: PropTypes.func,
    stopNote: PropTypes.func,
    onPlayNoteInput: PropTypes.func,
    onStopNoteInput: PropTypes.func,
    renderNoteLabel: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    width: PropTypes.number,
    keyWidthToHeight: PropTypes.number,
    keyboardShortcuts: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        midiNumber: PropTypes.number.isRequired,
      }),
    ),
  };

  state = {
    activeNotes: this.props.activeNotes || [],
    activeNotesLH: this.props.activeNotesLH || [],
    activeNotesLowlight: this.props.activeNotesLowlight || [],
    hintNotes: this.props.hintNotes || [],
    hintNotesLH: this.props.hintNotesLH || [],
  };

  componentDidUpdate(prevProps) {
    // Make activeNotes "controllable" by using internal
    // state by default, but allowing prop overrides.
    if (
      prevProps.activeNotes !== this.props.activeNotes &&
      this.state.activeNotes !== this.props.activeNotes
    ) {
      this.setState({
        activeNotes: this.props.activeNotes || [],
      });
    }
    if (
      prevProps.activeNotesLH !== this.props.activeNotesLH &&
      this.state.activeNotesLH !== this.props.activeNotesLH
    ) {
      this.setState({
        activeNotesLH: this.props.activeNotesLH || [],
      });
    }
    if (
      prevProps.activeNotesLowlight !== this.props.activeNotesLowlight &&
      this.state.activeNotesLowlight !== this.props.activeNotesLowlight
    ) {
      this.setState({
        activeNotesLowlight: this.props.activeNotesLowlight || [],
      });
    }
    if (
      prevProps.hintNotes !== this.props.hintNotes &&
      this.state.hintNotes !== this.props.hintNotes
    ) {
      this.setState({
        hintNotes: this.props.hintNotes || [],
      });
    }
    if (
      prevProps.hintNotesLH !== this.props.hintNotesLH &&
      this.state.hintNotesLH !== this.props.hintNotesLH
    ) {
      this.setState({
        hintNotesLH: this.props.hintNotesLH || [],
      });
    }
  }

  handlePlayNoteInput = (midiNumber) => {
    this.setState((prevState) => {
      // Need to be handled inside setState in order to set prevActiveNotes without
      // race conditions.
      if (this.props.onPlayNoteInput) {
        this.props.onPlayNoteInput(midiNumber, { prevActiveNotes: prevState.activeNotes });
      }

      // Don't append note to activeNotes if it's already present
      if (prevState.activeNotes.includes(midiNumber)) {
        return null;
      }
      return {
        activeNotes: prevState.activeNotes.concat(midiNumber),
      };
    });
  };

  handleStopNoteInput = (midiNumber) => {
    this.setState((prevState) => {
      // Need to be handled inside setState in order to set prevActiveNotes without
      // race conditions.
      if (this.props.onStopNoteInput) {
        this.props.onStopNoteInput(midiNumber, { prevActiveNotes: this.state.activeNotes });
      }
      return {
        activeNotes: prevState.activeNotes.filter((note) => midiNumber !== note),
        // activeNotesLH: prevState.activeNotesLH.filter((note) => midiNumber !== note),
      };
    });
  };

  render() {
    const { activeNotes, onPlayNoteInput, onStopNoteInput, ...otherProps } = this.props;
    return (
      <ControlledPiano
        activeNotes={this.state.activeNotes}
        activeNotesLH={this.state.activeNotesLH}
        activeNotesLowlight={this.state.activeNotesLowlight}
        hintNotes={this.state.hintNotes}
        hintNotesLH={this.state.hintNotesLH}
        onPlayNoteInput={this.handlePlayNoteInput}
        onStopNoteInput={this.handleStopNoteInput}
        {...otherProps}
      />
    );
  }
}

export default Piano;
