import throttle from 'lodash/throttle'
import { Cursor } from './Cursor'
import { MidiWaterfall } from './MidiWaterfall'
import { VIEW_MODE, PLAY_MODE } from '../components/StateProvider'
import { retryWithDelay } from '../logic/util'
import { PlaybackPoint } from './PlaybackPoint'
import { NotationHighlighter } from './NotationHighlighter'
import { Scorer } from './Scorer'
import { KeyEvent } from './KeyPress'
import { MidiComposition } from './MidiComposition'


const DRAW_CALLBACK_THROTTLE_MS = 200
const SCROLL_LOOKAHEAD_MS = 1500
const SCROLL_THROTTLE_MS = 250

const MIN_COUNT_IN_MS = 1800          // minimum count-in when playing from start (midi waterfall mode)
const CONSTANT_MIN_COUNTIN_MS = 1800  // minimum count-in when playing from arbitrary place in constant mode
const CONSTANT_WINDOW_MS = 300        // milliseconds before and after each playback point when played notes are scored

// NB. all these playback-related millisecond values effectively (but not literally) 'scale' with
// the playback speed. I.e. they are all relative to the 'ms position in the piece' _at 100% tempo_.
// Or to put it another way, the values above are all fixed, but it's like the duration of an 
// 'in-app-millisecond' is stretched.
//
// So at 100% speed the constant window will be 300ms before and after each playback point
//    at 50% speed CONSTANT_WINDOW_MS = 300 still, but will _actually_ be 450 real milliseconds long



// First, let's shim the requestAnimationFrame API, with a setTimeout fallback
// (from https://github.com/cwilso/metronome)
if (typeof window !== 'undefined') {
    window.requestAnimFrame = (function () {
        return window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.oRequestAnimationFrame ||
            window.msRequestAnimationFrame ||
            function (callback) {
                window.setTimeout(callback, 1000 / 60);
            };
    })();
}

export class PlaybackManager {
    constructor(
        audioPlayer,
        midiDeviceManager,
        setPlaybackStateInApp,
        setPlayheadSlider,
        onUserNoteOn,
        onUserNoteOff,
        setHintKeys,
        setScorePopupOpen,
        viewMode,
        playMode
    ) {
        this.audioPlayer = audioPlayer
        this.setPlaybackStateInApp = setPlaybackStateInApp
        this.setPlayheadSlider = setPlayheadSlider
        this.onUserNoteOn = onUserNoteOn
        this.onUserNoteOff = onUserNoteOff
        this.awaitingKeysRH = []
        this.awaitingKeysLH = []
        this.awaitingKeys = []
        this.setHintKeys = setHintKeys
        this.setScorePopupOpen = setScorePopupOpen

        this.rafId = null
        this.drawLoopCallbackThrottled = throttle(this._drawLoopCallback, DRAW_CALLBACK_THROTTLE_MS)
        this.scrollCursorIntoViewThrottled = throttle(this._scrollCursorIntoView, SCROLL_THROTTLE_MS)
        this.movePlayheadToBeat = throttle(this._movePlayheadToBeat, 200)
        this.setPlaybackSpeed = throttle(this._setPlaybackSpeed, 200)
        this.isPieceLoaded = this.isPieceLoaded.bind(this)
        this.cursor = new Cursor(this)
        this.notationHighlighter = new NotationHighlighter(this)
        this.midiWaterfall = new MidiWaterfall(this, 'midi-waterfall-container')
        this.viewMode = viewMode
        this.playMode = playMode
        this.physicalKeyboard = midiDeviceManager
        this.physicalKeyboard.noteOnCallback = this.onNoteOn.bind(this)
        this.physicalKeyboard.noteOffCallback = this.onNoteOff.bind(this)
        this._metronomeOn = null
        this._playStaves = [true, true]  // [right, left]

        this.playbackMap = null
        this.midiComposition = null
        this.beat = 0      // *exact* position in piece we're at - straight conversion from audio player's current ticks
        this.lastTickReceived = null
        this.progress = 0
        this.playbackSpeed = 1
        this.status = 'STOPPED'
        this.prePauseStatus = null

        this.countInPoint = null  // point for the count-in at the start; before first note of piece
        this.listenPoint = null   // in Constant and Wait mode, the playback point 'of interest' 
        this.scorer = null
        this.prevScore = null
        this.scoringActiveFromTick = null
    }

    async setPiece(piece) {
        const midiData = this.audioPlayer.currentComposition
        this.ppq = midiData.header.ppq
        this.playbackMap = piece.playbackMap
        this.playbackMap.applyMidiHeaderData(midiData.header)
        this.countInPoint = this.playbackMap.addCountInPointAtStart(MIN_COUNT_IN_MS)
        this.midiComposition = new MidiComposition(
            midiData.header,
            midiData.tracks.filter(t => t.name !== 'METRONOME')
        )
        this.playbackMap.calcTimesAndTicks(this.midiComposition)
        // console.log(this.midiComposition)

        // Player's loading can complete now that we know where first metronome tick should be.
        await this.audioPlayer.completeLoading(this.countInPoint.tick)

        this.currPointIndex = 0
        this.midiWaterfall.setPiece(this.playbackMap, this.midiComposition, piece)
        this.cursor.init(piece.pageHeight)
        this.notationHighlighter.init(piece.pageHeight)
        this.enablePlaybackOfStaves()

        // console.log(this.playbackMap)
    }

    async setViewMode(viewMode, previousMode) {
        this.viewMode = viewMode
        await retryWithDelay(this.isPieceLoaded, 30, 1000, 'loading of playbackMap timed out')

        // MIDI waterfall uses a 'countin' (i.e. space before first note) but PDF does not. If change mode when
        // we're stopped at start then set beat accordingly.
        if (this.status === 'STOPPED') {
            if (viewMode === VIEW_MODE.MIDI_WATERFALL && this.currPlaybackPoint.beat === 0) {
                this.movePlayheadToBeat(this.countInPoint.beat)
            }
            else if (previousMode === VIEW_MODE.MIDI_WATERFALL && this.currPlaybackPoint === this.countInPoint) {
                this.movePlayheadToBeat(0)
            }
        }

        if (viewMode === VIEW_MODE.MIDI_WATERFALL) {
            this.cursor.suspend()
            const tick = this.status === 'PLAYING'
                ? this.audioPlayer.getCurrentTicks()
                : this.currPlaybackPoint.tick
            this.midiWaterfall.reset(tick)
        }
        if (this.isPdfVisible && (previousMode === VIEW_MODE.MIDI_WATERFALL || !previousMode)) {
            this.midiWaterfall.suspend()
            await this.cursor.reset(this.currPlaybackPoint)
            this.cursor.scrollIntoView(this.currPlaybackPoint, 'instant')
        }
    }

    setPlayMode(playMode) {
        this.playMode = playMode
        this.enablePlaybackOfStaves()
    }

    isPieceLoaded() {
        return !!this.playbackMap
    }

    get isReady() {
        if (this.viewMode === VIEW_MODE.MIDI_WATERFALL) {
            return this.midiWaterfall.isReady
        }
        else {
            return this.cursor.isReady
        }
    }

    // NB. we also need a separate 'local' status (this.status) because audio player status does not immediately
    // go to 'started', and in draw loop we need to differentiate between 'stopped' meaning 'the audio player hasn't
    // started *yet*' and 'the audio player has stopped (e.g. end of piece)'.
    get audioPlayerStatus() {
        return this.audioPlayer.getPlayerStatus()
    }

    async load(midi) {
        return await this.audioPlayer.load(midi)
    }

    get midiLengthQn() {
        return this.audioPlayer.compositionLengthTicks / this.ppq
    }

    async start() {
        this.viewMode === VIEW_MODE.MIDI_WATERFALL && await this.midiWaterfall.prepForPlay()
        const tick = await this._setPlayFrom()

        if (Scorer.isScorableMode(this.playMode)) {
            this.scorer = new Scorer(this.playMode)
        }
        else {
            this.scorer = null
        }

        // if in Wait mode then shouldn't actually start if we are sitting at a place where we need to wait
        const shouldWaitHere = this._shouldWaitHere(tick)
        const shouldActuallyStart = !shouldWaitHere || (shouldWaitHere && this.startWaitingForUserKeys())
        if (shouldActuallyStart) {
            console.log('start')
            this.audioPlayer.play()
            this.status = 'AWAITING_AUDIO_PLAYER'
            this.setPlaybackStateInApp('PLAYING')
            this.lastTickReceived = null
            this.initDraw()
            console.log('▶️ PLAYING')
        }
    }

    stop() {
        if (['PLAYING', 'AWAITING_AUDIO_PLAYER', 'AWAITING_USER_KEYS'].includes(this.status)) {
            this.stopDrawLoop()
            this.audioPlayer.stop()
            this.status = 'STOPPED'
            this.setPlaybackStateInApp('STOPPED')
            this.viewMode === VIEW_MODE.MIDI_WATERFALL && this.midiWaterfall.resetAfterPlay()
            this.snapCursorToBeat()
            this.cursor.colour = Cursor.COLOUR.NORMAL
            this.setHintKeys([])
            this.listenPoint = null
            console.log('🛑 STOPPED')
        }
        if (!!this.scorer) {
            this.prevScore = this.scorer
            this.setScorePopupOpen(true)
        }
    }

    // Called when user initiates actions which interrupt the flow, such as sliding the position slider, clicking 
    // a beat to move the cursor, etc. Pauses playback, stops the draw loop and takes certain mechanisms 'offline'
    // (for example, it clears any keyboard hints).
    // Should be followed by a call to resume() (when the user action is completed).
    pause() {
        if (this.status === 'STOPPED') {
            return  // no need for any pausing if stopped
        }
        else if (this.prePauseStatus) {
            return  // already paused
        }

        this.prePauseStatus = this.status
        if (this.status === 'PLAYING' || this.status === 'AWAITING_AUDIO_PLAYER') {
            this.stopDrawLoop()
            this.audioPlayer.stop()
            this.status = 'STOPPED'
            this.setPlaybackStateInApp('STOPPED')
        }
        if (this.status === 'AWAITING_USER_KEYS') {
            this.setHintKeys([])
            this.listenPoint = null
        }
    }

    // After a pause (see above) - restarts the audio player, the drawing loop, etc.
    resume() {
        this.lastTickReceived = null
        if (['PLAYING', 'AWAITING_AUDIO_PLAYER', 'AWAITING_USER_KEYS'].includes(this.prePauseStatus)) {
            const shouldWaitHere = this._shouldWaitHere(this.audioPlayer.getCurrentTicks())
            const shouldActuallyStart = !shouldWaitHere || (shouldWaitHere && this.startWaitingForUserKeys())
            console.log(this.audioPlayer.getCurrentTicks(), shouldWaitHere, 'shouldActuallyStart:', shouldActuallyStart)
            if (shouldActuallyStart) {
                this.audioPlayer.play()
                this.status = 'AWAITING_AUDIO_PLAYER'
                this.setPlaybackStateInApp('PLAYING')
                this.initDraw()
            }
        }
        this.prePauseStatus = null
    }

    // set up everything for waiting (i.e. in Wait mode)
    // returns true if user is already playing the correct notes
    startWaitingForUserKeys() {
        this.listenPoint = this.currPlaybackPoint
        if (this._checkKeysInWaitMode()) {
            return true  // user is 'ahead of the game' - don't stop, breeze through...!
        }

        this.stopDrawLoop()
        this.audioPlayer.pause()
        this.status = 'AWAITING_USER_KEYS'
        this.setPlaybackStateInApp('AWAITING_USER_KEYS')
        this.doHintKeys()
    }

    resumeAfterWait() {
        this.status = 'AWAITING_AUDIO_PLAYER'
        this.setPlaybackStateInApp('PLAYING')
        this.doHintKeys()
        this.lastTickReceived = null
        this.audioPlayer.play()
        this.startDrawLoop()
    }

    doHintKeys() {
        if (this.status === 'AWAITING_USER_KEYS') {
            let hintKeys = []
            if (this.playStaves[0]) {
                hintKeys = hintKeys.concat(this.currPlaybackPoint.expectedPitchesRH.map(p => new KeyEvent(p.midi, { hand: 'RH' })))
            }
            if (this.playStaves[1]) {
                hintKeys = hintKeys.concat(this.currPlaybackPoint.expectedPitchesLH.map(p => new KeyEvent(p.midi, { hand: 'LH' })))
            }
            this.setHintKeys(hintKeys)
        }
        else {
            this.setHintKeys([])
        }
    }

    // Called from start(): decide where to play from and set it up
    // (it's not always the current point if e.g. there is a loop selected...)
    async _setPlayFrom() {
        let playFromBeat = null

        if (this.loopFromPoint) {
            playFromBeat = this.loopFromPoint.beat
        }
        else if (this.isAtEnd) {
            playFromBeat = this.fromBeginningBeat()
        }
        else if (this.viewMode === VIEW_MODE.MIDI_WATERFALL) {
            playFromBeat = this.playbackMap.getBeatAtTick(this.midiWaterfall.getCurrentTickFromY())
        }
        else {
            playFromBeat = this.currPlaybackPoint.beat
        }

        if (this.playMode === PLAY_MODE.CONSTANT) {
            const playFromPoint = this.playbackMap.getPointForBeat(playFromBeat)
            if (playFromPoint.type === 'countin') {
                this.scoringActiveFromTick = this.playbackMap.getTickAtTimeMs(-CONSTANT_WINDOW_MS)
            }
            else {
                playFromBeat = this.playbackMap.calcCountInBeat(playFromPoint, CONSTANT_MIN_COUNTIN_MS)
                this.scoringActiveFromTick = this.playbackMap.getTickAtTimeMs(playFromPoint.timeMs - CONSTANT_WINDOW_MS)
            }
            this.cursor.colour = Cursor.COLOUR.INACTIVE
        }

        return this.movePlayheadToBeat(playFromBeat)  // returns the tick
    }

    // Returns the beat to be used when we are going 'from the beginning'
    // It will either be the count-in beat or beat zero, depending on view/play modes.
    fromBeginningBeat() {
        if (this.viewMode === VIEW_MODE.MIDI_WATERFALL) {
            return this.countInPoint.beat
        }
        else if (this.playMode === PLAY_MODE.CONSTANT) {
            return this.countInPoint.beat
        }
        else {
            return 0
        }
    }

    movePlayheadToStart() {
        return this._movePlayheadToBeat(this.fromBeginningBeat())
    }

    // move to the given "beat in notation"
    // "beat in notation" means it's based on what's written, not where notes sound
    // but this does set to the *audio* playhead to the appropriate *sounding* point
    _movePlayheadToBeat(beatInNotation) {
        this.midiWaterfall.stopScroll()
        this.pause()
        this.listenPoint = null  // forget any existing listen point  

        this.currPointIndex = this.playbackMap.getPointIndexForBeat(beatInNotation, { byNotationOrPerformance: 'notation' })
        let tickForAudio = this.playbackMap.getTickAtBeat(beatInNotation)

        // In PDF + normal or constant mode, put the audio player fractionally earlier, else the player may not
        // actually play the clicked note (or in constant mode, it won't be scored).
        if (
            this.isPdfVisible
            && [PLAY_MODE.CONSTANT, PLAY_MODE.NORMAL].includes(this.playMode)
            && beatInNotation >= 0   // but don't if we're in the count-in!
        ) {
            tickForAudio = this.playbackMap.getTickAtTimeMs(this.playbackMap.getTimeAtTick(tickForAudio) - 50)
        }

        this.audioPlayer.setCurrentTicks(tickForAudio)

        if (this.isPdfVisible) {
            this.drawCursor()
            if (beatInNotation <= 0) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
            }
            else {
                this.scrollCursorIntoViewThrottled()
            }
        }
        else if (this.viewMode === VIEW_MODE.MIDI_WATERFALL) {
            this.midiWaterfall.setPlayhead(tickForAudio)
        }

        // If cursor moved in constant mode then activate scoring immediately (even if we were in countin)
        if (this.playMode === PLAY_MODE.CONSTANT && !!this.scorer && !this.scorer.isActive) {
            this.scorer.activate()
            this.cursor.colour = Cursor.COLOUR.NORMAL
        }
        if (this.playMode === PLAY_MODE.WAIT && ['PLAYING', 'AWAITING_USER_KEYS'].includes(this.status)) {
            this.startWaitingForUserKeys()
        }

        this.setPlayheadSlider(beatInNotation)
        this.resume()

        return tickForAudio
    }

    snapCursorToBeat() {
        // If not at end then snap cursor to a playback point, but weighted towards previous one.
        // Intended to be called when playback is stopped.
        if (this.isAtEnd) {
            // Make sure we're at final point (sometimes doesn't happen as ticks are received in the animation loop
            // which fires unpredictably).
            this.currPointIndex = this.playbackMap.length - 1
        }
        else if (this.progress > 0.8) {
            if (this.isLooping) {
                this.currPointIndex = this.loopFromIndex
            }
            else {
                this.currPointIndex++
                this.progress = 0
            }
        }
        this.drawCursor()
    }

    handleMidiWaterfallScroll(tick) {
        this.listenPoint = null
        const beat = this.playbackMap.getBeatAtTick(tick)
        this.audioPlayer.setCurrentTicks(tick)
        this.currPointIndex = this.playbackMap.getPointIndexForTick(tick, { byNotationOrPerformance: 'performance' })
        this.setPlayheadSlider(beat)
    }

    // Throttled version is this.setPlaybackSpeed
    _setPlaybackSpeed(playbackSpeed) {
        this.playbackSpeed = playbackSpeed
        this.audioPlayer.setPlaybackMultiplier(this.playbackSpeed)
    }

    calcEndBeat() {
        const playbackPoints = this.playbackMap?.points || []
        const lastNote = playbackPoints[playbackPoints.length - 1]
        const endBeat = lastNote ? lastNote.beat : 1
        return endBeat
    }

    setLoopRange([fromBeat, toBeat]) {
        // nb. toBeat is the start beat of the last rhythm slice which is included in the loop.
        // I.e. a loop of a single minim (with no additional shorter notes in other voices) would
        // be [2, 2], not [2, 4].
        // ???newp nasty that audio player uses other (better ) system..
        if (fromBeat > -1 && toBeat > -1) {
            this.loopFromIndex = this.playbackMap.getPointIndexForBeat(fromBeat, { byNotationOrPerformance: 'notation' })
            this.loopToIndex = this.playbackMap.getPointIndexForBeat(toBeat, { byNotationOrPerformance: 'notation' })
            const fromTick = this.playbackMap.getTickAtBeat(fromBeat)
            const toTick = this.playbackMap.points[this.loopToIndex + 1].tick
            this.audioPlayer.setLoop(fromTick, toTick)
            this.audioPlayer.startLoop()
            this.midiWaterfall.setLoop(fromTick, toTick)
        }
        else {
            this.loopFromIndex = null
            this.loopToIndex = null
            this.audioPlayer.stopLoop()
            this.midiWaterfall.clearLoop()
        }
    }

    get loopFromPoint() {
        return this.playbackMap.points[this.loopFromIndex]
    }

    get isLooping() {
        return this.loopFromIndex !== null && this.loopToIndex >= this.loopFromIndex
    }

    get isAtEnd() {
        const leewayTicks = this.ppq  // 1 qn
        if (this.lastTickReceived > this.audioPlayer.compositionLengthTicks - leewayTicks) {
            // During playback we don't always receive the final tick (because of asynchronous nature of animation frames?)
            // so treat 'very close to end' as being at the end.
            return true
        }
        // Can't *only* rely on tick received as could be at end without ever playing back (i.e. if user navigates there)..
        return this.currPointIndex >= this.playbackMap.length - 1
    }

    get currPlaybackPoint() {
        return isNaN(this.currPointIndex) ? null : this.playbackMap.points[this.currPointIndex]
    }
    get nextPlaybackPoint() {
        return this.playbackMap.points[this.currPointIndex + 1]
    }
    get nextNotesPlaybackPoint() {
        // The next 'normal' playback point - e.g. ignores 'barline' points
        for (let i = this.currPointIndex + 1; i < this.playbackMap.length; i++) {
            const point = this.playbackMap.points[i]
            if (point instanceof PlaybackPoint) {
                return point
            }
        }
        return null
    }

    clearCursor() {
        this.cursor.clear()
    }

    drawCursor() {
        if (this.isPdfVisible) {
            this.cursor.createCursorElt(this.currPlaybackPoint)
        }
    }

    initDraw() {
        if (this.isPdfVisible) {
            this.cursor.reset()
        }
        this.startDrawLoop()
    }

    startDrawLoop() {
        if (typeof window !== 'undefined') {
            this.rafId = window.requestAnimFrame((t) => this.draw(t))
        }
    }

    stopDrawLoop() {
        cancelAnimationFrame(this.rafId)
    }

    // Main drawing loop
    draw() {
        // Follow audio player's status - but it doesn't start instantaneously so need to use 'AWAITING_AUDIO_PLAYER'
        // status to distinguish between 'stopped' meaning it's about to start and 'stopped' meaning it has actually
        // stopped.
        if (this.audioPlayerStatus === 'stopped' && this.status !== 'AWAITING_AUDIO_PLAYER') {
            this.stop()
            return
        }
        else if (this.audioPlayerStatus === 'started') {
            this.status = 'PLAYING'
        }

        const tick = this.audioPlayer.getCurrentTicks()
        this.beat = this.playbackMap.getBeatAtTick(tick)
        this.currPointIndex = this.playbackMap.getPointIndexForTick(tick, { byNotationOrPerformance: 'performance' })
        const shouldWaitHere = this._shouldWaitHere(tick)

        if (this.playMode === PLAY_MODE.CONSTANT) {
            // once scoring is active, it stays active, even if user clicks to a point before the original start point
            // (hence can't just compare tick with this.scoringActiveFromTick to determine if active.)
            if (!this.scorer.isActive && tick >= this.scoringActiveFromTick) {
                this.cursor.colour = Cursor.COLOUR.NORMAL
                this.scorer.activate()
            }
            if (this.scorer.isActive) {
                this._handleConstantModeListenWindow()
            }
        }

        if (this.viewMode === VIEW_MODE.MIDI_WATERFALL) {
            const _tick = shouldWaitHere ? this.currPlaybackPoint.tick : tick
            this.midiWaterfall.draw(_tick)
        }
        else {
            this.cursor.setYFromPoint(this.currPlaybackPoint)

            if (this.nextPlaybackPoint && !shouldWaitHere) {
                this.progress = (this.beat - this.currPlaybackPoint.beat) / (this.nextPlaybackPoint.beat - this.currPlaybackPoint.beat)
                this.cursor.setX(this.currPlaybackPoint.noteX + (this.nextPlaybackPoint.noteX - this.currPlaybackPoint.noteX) * this.progress)
            }
            else {
                // no nextPlaybackPoint, which means currPlaybackPoint was already the last point in the piece.
                this.progress = 0
                this.cursor.setX(this.currPlaybackPoint.noteX)
            }

            this.scrollCursorIntoViewThrottled()
        }

        if (this.drawLoopCallbackThrottled) {
            this.drawLoopCallbackThrottled(this.beat)
        }

        // keep going if not a wait point, or if it is but user already playing correct keys
        const shouldKeepGoing = !shouldWaitHere || (shouldWaitHere && this.startWaitingForUserKeys())
        if (shouldKeepGoing) {
            this.rafId = window.requestAnimationFrame((t) => this.draw(t))
        }

        this.lastTickReceived = tick
    }

    _drawLoopCallback(beat) {
        this.setPlayheadSlider(beat)
    }

    get physicalKeysPressed() {
        return this.physicalKeyboard.currentlyPressedKeys
    }

    onNoteOn(pitchMidi) {
        if (this.listenPoint && this.listenPoint.expectedPitchesRH.map(p => p.midi).includes(pitchMidi)) {
            this.onUserNoteOn(new KeyEvent(pitchMidi, { hand: 'RH', isCorrect: true }))
        }
        else if (this.listenPoint && this.listenPoint.expectedPitchesLH.map(p => p.midi).includes(pitchMidi)) {
            this.onUserNoteOn(new KeyEvent(pitchMidi, { hand: 'LH', isCorrect: true }))
        }
        else {
            this.onUserNoteOn(new KeyEvent(pitchMidi, { isCorrect: false }))
        }
        if (this.listenPoint) {
            this.listenPoint.pressedKeys = this.physicalKeysPressed
        }
        if (this.playMode === PLAY_MODE.WAIT) {
            this._checkKeysInWaitMode()
        }
    }

    onNoteOff(pitchMidi) {
        this.onUserNoteOff(new KeyEvent(pitchMidi))
        if (this.playMode === PLAY_MODE.WAIT && this.status === 'AWAITING_USER_KEYS') {
            this._checkKeysInWaitMode()
        }
    }

    keysAwaited(point) {
        if (!point) {
            return []  // not a playback point so not awaiting any keys
        }
        const awaitingKeys = [
            ...this.playStaves[0] ? point.expectedPitchesRH : [],
            ...this.playStaves[1] ? point.expectedPitchesLH : [],
        ]
        return awaitingKeys.map(p => p.midi)
    }

    _shouldWaitHere(tick) {
        if (this.playMode !== PLAY_MODE.WAIT) {
            return false
        }

        // might need to wait when starting 'at a point'
        // 'at a point' meaning:
        // - midi waterfall: tick position exactly on start of note (if not then will progress to next one)
        // - not midi waterfall: always wait on current point when starting
        const isStartingAtAPoint =
            this.lastTickReceived === null              // starting
            && (this.viewMode === VIEW_MODE.MIDI_WATERFALL
                ? tick === this.currPlaybackPoint.tick      // midi waterfall: only 'at a point' if at it exactly
                : true)                                     // pdf (not MW): always wait at starting point

        // might need to wait when already running and arrive at a new point
        const hasArrivedAtNewPoint =
            this.lastTickReceived !== null                          // was already running
            && this.lastTickReceived < this.currPlaybackPoint.tick  // it's a new point
            && tick > this.lastTickReceived                         // ensure don't use same tick twice

        // in either case, only wait if the point in question actually has notes to be played                
        const pointHasKeysToWaitFor = this.keysAwaited(this.currPlaybackPoint).length > 0
        const shouldWaitHere = (isStartingAtAPoint || hasArrivedAtNewPoint) && pointHasKeysToWaitFor

        return shouldWaitHere
    }

    _checkKeysInWaitMode() {
        if (!this.listenPoint) {
            return true  // no listen point so return success
        }

        const awaitingKeys = this.keysAwaited(this.listenPoint)

        // ???phys don't actually need this.keysAwaited I think - just use currPP
        // Proceed when:
        // - all the new notes at this point are pressed
        // - and no wrong keys are pressed
        // - but 'continuing' notes are optional
        const excessKeys = this.physicalKeysPressed.filter(p => !(awaitingKeys.includes(p)))
        const wrongKeys = excessKeys.filter(p => !(this.listenPoint.continuingPitches.includes(p)))

        const areAllAwaitedKeysPressed = awaitingKeys.every(p => this.physicalKeysPressed.includes(p))
        if (areAllAwaitedKeysPressed && wrongKeys.length === 0) {
            if (this.status === 'AWAITING_USER_KEYS') {
                this.resumeAfterWait()
            }
            this.listenPoint = null
            return true
        }
    }

    // Handles the opening/closing of a 'time-window' around each playback point with notes in constant mode.
    // While this time-window is open then midi keyboard key-presses are recorded (see _recordKeysInConstantMode).
    // When the window is closed then scoring happens and correct/missing notes are coloured on the PDF notation.
    _handleConstantModeListenWindow() {

        const currTimeMs = this.playbackMap.getTimeAtBeatMs(this.beat)
        const nextPoint = this.nextNotesPlaybackPoint

        // If notes are close together then the 'post' window of one might overlap with the 'pre' window of the next.
        // In that case, set the end of the window to be mid-way between the two notes.
        const windowWidthMs = nextPoint
            ? Math.min(CONSTANT_WINDOW_MS, (nextPoint.timeMs - this.listenPoint?.timeMs) / 2)
            : CONSTANT_WINDOW_MS

        // handle an open listen window which has just expired
        if (!!this.listenPoint && currTimeMs - this.listenPoint.timeMs > windowWidthMs) {
            let correctKeysRH = [], missingKeysRH = []
            let correctKeysLH = [], missingKeysLH = []

            if (this.playStaves[0]) {
                correctKeysRH = this.listenPoint.expectedPitchesRH.filter(x => this.listenPoint.pressedKeys.includes(x.midi))
                missingKeysRH = this.listenPoint.expectedPitchesRH.filter(x => !this.listenPoint.pressedKeys.includes(x.midi))
            }
            if (this.playStaves[1]) {
                correctKeysLH = this.listenPoint.expectedPitchesLH.filter(x => this.listenPoint.pressedKeys.includes(x.midi))
                missingKeysLH = this.listenPoint.expectedPitchesLH.filter(x => !this.listenPoint.pressedKeys.includes(x.midi))
            }

            if (this.isPdfVisible) {
                this.notationHighlighter.highlightNotes(this.listenPoint, 0, correctKeysRH, missingKeysRH)
                this.notationHighlighter.highlightNotes(this.listenPoint, 1, correctKeysLH, missingKeysLH)
            }
            this.scorer.numNotesEncountered += this.playStaves[0] && this.listenPoint.expectedPitchesRH.length
            this.scorer.numNotesEncountered += this.playStaves[1] && this.listenPoint.expectedPitchesLH.length
            this.scorer.numNotesCorrect += correctKeysRH.length + correctKeysLH.length
            this.listenPoint = null  // close the time-window
        }

        // see if we need to open a new listen window
        if (
            !this.listenPoint
            && !!nextPoint
            && nextPoint.timeMs > currTimeMs  // avoid getting muddled if user moves cursor backwards
            && nextPoint.timeMs - currTimeMs <= CONSTANT_WINDOW_MS
            && nextPoint.hasStruckPitches
        ) {
            this.listenPoint = nextPoint      // open the window
            this.listenPoint.pressedKeys = []
        }
    }

    resetScore() {
        // don't actually need to reset scorer as a new one is created each play
        // but clear the red/green noteheads
        this.notationHighlighter.clear()
    }

    // Scroll window up/down if necessary so that cursor is visible.
    // With a 'lookahead' to do the scrolling slightly in advance (like a page turner would)
    // - but no lookahead in Wait mode.
    // Throttled version (to be called from draw()) is this.scrollCursorInToViewThrottled
    _scrollCursorIntoView(behavior) {
        let lookaheadPoint = this.currPlaybackPoint
        if (this.playMode !== PLAY_MODE.WAIT && this.status === 'PLAYING') {
            lookaheadPoint = this.findLookaheadPoint()
        }
        this.cursor.scrollIntoView(lookaheadPoint, behavior)
    }

    findLookaheadPoint() {
        let lookaheadPoint
        let i = this.currPointIndex + 1
        let lookaheadSpansLoopEnd = 0
        let safety = 0
        while (safety < 500) {
            safety++
            if (this.isLooping && i > this.loopToIndex) {
                lookaheadSpansLoopEnd++
                if (lookaheadSpansLoopEnd > 1) {
                    // Loops which are shorter than SCROLL_LOOKAHEAD_MS could cause infinite loop
                    // - so only allow lookahead to 'go around' once.
                    break
                }
                i = this.loopFromIndex
            }
            if (i >= this.playbackMap.length) {
                break
            }

            lookaheadPoint = this.playbackMap.points[i]
            let timeAheadMs
            if (lookaheadSpansLoopEnd) {
                const timeAtLoopEndMs = this.playbackMap.points[this.loopToIndex + 1].timeMs
                const nextLoopTimeTillPointMs = lookaheadPoint.timeMs - this.loopFromPoint.timeMs
                timeAheadMs = timeAtLoopEndMs + nextLoopTimeTillPointMs - this.audioPlayer.getCurrentSeconds() * 1000
            }
            else {
                timeAheadMs = lookaheadPoint.timeMs - this.audioPlayer.getCurrentSeconds() * 1000
            }
            if (timeAheadMs >= SCROLL_LOOKAHEAD_MS * this.playbackSpeed) {
                break
            }
            i++
        }

        return lookaheadPoint
    }

    setZoom(zoom) {
        return this.cursor.setZoom(zoom)
    }

    get playStaves() { return this._playStaves }

    set playStaves(value) {
        this._playStaves = value
        this.enablePlaybackOfStaves()
        this.doHintKeys()
        if (this.status === 'AWAITING_USER_KEYS') {
            this._checkKeysInWaitMode()
        }
    }

    enablePlaybackOfStaves() {
        if (!this.midiComposition) {  // not ready to do this yet
            return
        }

        if (this.playMode === PLAY_MODE.NORMAL) {
            this._enableTrackPlayback(this.midiComposition.indexRH, this._playStaves[0])
            this._enableTrackPlayback(this.midiComposition.indexLH, this._playStaves[1])
            this._enableTrackPlayback('METRONOME', this._metronomeOn)
        }

        // in Constant mode meaning of the L/R buttons are inverted (compared to normal mode):
        // - now R button pressed means the USER will play the R stave (and app should not)
        else if (this.playMode === PLAY_MODE.CONSTANT) {
            this._enableTrackPlayback(this.midiComposition.indexRH, !this._playStaves[0])
            this._enableTrackPlayback(this.midiComposition.indexLH, !this._playStaves[1])
            this._enableTrackPlayback('METRONOME', this._metronomeOn)
        }

        // in Wait mode only user plays - the app should never play, even if only one of L/R is pressed.
        else if (this.playMode === PLAY_MODE.WAIT) {
            this._enableTrackPlayback(this.midiComposition.indexRH, false)
            this._enableTrackPlayback(this.midiComposition.indexLH, false)
            this._enableTrackPlayback('METRONOME', false)
        }
    }

    // turns on or off the audio coming from the player for the specified track
    // (no effect on audio caused by user-played keys)
    _enableTrackPlayback(trackIndexOrName, enable) {
        // TODO: this ought to exist in the player
        const comp = this.audioPlayer.currentComposition
        if (!comp?.tracks[0]) {
            return
        }
        const track = typeof trackIndexOrName === 'string'
            ? comp.tracks.find(t => t.name === trackIndexOrName)
            : comp.tracks[trackIndexOrName]
        const inst = this.audioPlayer.instrumentBank.get(track?.instrument.name)
        if (!inst) {
            console.log(`No instrument found for track ${trackIndexOrName}`)
            return
        }
        const outputGain = inst.effectChain[0].output.gain
        outputGain.setValueAtTime(enable ? 1 : 0, '+0.01')
    }

    get metronomeOn() { return this._metronomeOn }

    set metronomeOn(isOn) {
        this._metronomeOn = isOn
        this._enableTrackPlayback('METRONOME', this._metronomeOn)
    }

    get isPdfVisible() {
        return [VIEW_MODE.PDF, VIEW_MODE.PDF_PIANO].includes(this.viewMode)
    }

    get isPianoVisible() {
        return [VIEW_MODE.PDF_PIANO, VIEW_MODE.MIDI_WATERFALL].includes(this.viewMode)
    }

}
