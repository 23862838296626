/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

/*
Components to compose a note name (like 'B♭') correctly in <span> - i.e. to position the flat/sharp etc.
glyphs neatly. <NoteLabel> is then used to position each <NoteName> on the page.
*/


const alterationBaseCss = css`
display: inline-block;
font-family: bravura-text;
font-size: 0.8em;
line-height: 0;
margin-left: 0.08em;
margin-right: 0.08em;
`

export const NoteName = ({ letter, alteration }) => {
    return (
        <span css={css`white-space: nowrap;`}>{letter}<Alteration alteration={alteration} /></span>
    )
}

export function Alteration({ alteration }) {
    if (alteration === 'bb') {
        return (
            <span css={css`${alterationBaseCss}
            font-size: 1.4em;
            vertical-align: 0.14em;
            `}>
                𝄫
            </span>
        )
    }
    else if (alteration === 'b') {
        return (
            <span css={css`${alterationBaseCss}
            /* vertical-align: -0.02em; */
            `}>
                ♭
            </span>
        )
    }
    else if (alteration === 'n') {
        return (
            <span css={css`${alterationBaseCss}
            font-size: 0.87em;
            vertical-align: 0.03em;`}>
                ♮
            </span>
        )
    }
    else if (alteration === '#') {
        return (
            <span css={css`${alterationBaseCss}
            vertical-align: 0.1em;`}>
                ♯
            </span>
        )
    }
    else if (alteration === '##') {
        return (
            <span css={css`${alterationBaseCss}
            font-size: 1.6em;
            vertical-align: 0.17em;`}>
                𝄪
            </span>
        )
    }
    return null
}

export const Fs = NoteName({ letter: 'F', alteration: '#' })
export const Cs = NoteName({ letter: 'C', alteration: '#' })
export const Gs = NoteName({ letter: 'G', alteration: '#' })
export const Ds = NoteName({ letter: 'D', alteration: '#' })
export const As = NoteName({ letter: 'A', alteration: '#' })

export const Bb = NoteName({ letter: 'B', alteration: 'b' })
export const Eb = NoteName({ letter: 'E', alteration: 'b' })
export const Ab = NoteName({ letter: 'A', alteration: 'b' })
export const Db = NoteName({ letter: 'D', alteration: 'b' })
export const Gb = NoteName({ letter: 'G', alteration: 'b' })
