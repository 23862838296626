/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'

const HIGHLIGHT_COLOR = 'hsla(40deg, 96%, 61%, 0.3)'
const END_MARKER_COLOR = 'hsla(30deg, 96%, 45%, 0.50)'
const MARKER_WIDTH = 9
const VERTICAL_OVERHANG = 20  // extra clickable area above/below the staff lines

const highlightCss = css`
    background-color: ${HIGHLIGHT_COLOR};
    /* border-top: ${VERTICAL_OVERHANG}px solid #fff;     // put back if want highlight to be tight to staff lines, but clickable area taller
    border-bottom: ${VERTICAL_OVERHANG}px solid #fff; */
`

const NoteOverlay = ({
    beats,
    top,
    left,
    height,
    width,
    highlight,
    isLoopStart,
    isLoopEnd,
    ...etc
}) => {

    const endMarkerCss = css`
        content: "";
        position: absolute;
        top: ${-MARKER_WIDTH}px;
        width: 15px;
        height: ${height + 2 * VERTICAL_OVERHANG}px;
        z-index: -1;
        border: ${MARKER_WIDTH}px solid ${END_MARKER_COLOR};
        border-radius: 5px;
    `

    const isLoopStartCss = css`
    &::before{
        ${endMarkerCss}
        left: -${MARKER_WIDTH}px;
        border-right: none;
        box-sizing: content-box;
    }
`

    const isLoopEndCss = css`
    &::after{
        ${endMarkerCss}
        right: -${MARKER_WIDTH}px;
        border-left: none;
        box-sizing: content-box;
    }
`

    return (
        <div
            css={css`
                touch-action: none;
                position: absolute;
                display: block;
                top: ${top - VERTICAL_OVERHANG}px;
                left: ${left}px;
                height: ${height + VERTICAL_OVERHANG * 2}px;
                width: ${width}px;
                user-select: none;
                z-index: 15;
                box-sizing: border-box;
                /* border-top: ${VERTICAL_OVERHANG}px solid transparent;      // put back if want highlight to be tight to staff lines, but clickable area taller
                border-bottom: ${VERTICAL_OVERHANG}px solid transparent; */
                /* &:hover {        // handy for debug
                    background-color: hsla(0deg, 40%, 50%, 0.2 );
                } */
                ${highlight && highlightCss}
                ${isLoopStart && isLoopStartCss}
                ${isLoopEnd && isLoopEndCss}
            `}
            data-beats={beats}
            data-handle={isLoopStart ? 'start' : isLoopEnd ? 'end' : null}
            {...etc}
        >
        </div>
    )
}


export default NoteOverlay
