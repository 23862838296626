/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { PLAYBACK_CONTROLS_HEIGHT } from '../logic/constants'
import { PLAY_MODE, useAppState, useDispatch } from './StateProvider'
import Toast from 'react-bootstrap/Toast';
import ToastBody from 'react-bootstrap/ToastBody';
import ToastHeader from 'react-bootstrap/ToastHeader';
import { IconWaitMode, IconConstantMode } from './Icons'


export const ScorePopup = () => {
    const { playbackManager, isScorePopupOpen } = useAppState()
    const dispatch = useDispatch()

    const score = playbackManager.prevScore

    function handleClose() {
        dispatch({ type: 'SET_SCORE_POPUP_OPEN', payload: false })
        playbackManager.resetScore()
    }

    return (
        <Toast
            id='score-popup'
            show={isScorePopupOpen}
            onClose={handleClose}
            className='position-fixed end-0 mt-3 me-3 text-start'

            css={css`
            top: ${PLAYBACK_CONTROLS_HEIGHT}px;
            width: 400px;
            z-index: 1001;
            font-size: 1rem;
            background-color: hsla(61deg, 36%, 80%, 0.8);
            backdrop-filter: blur(3px);
            `}
        >
            <ToastHeader
                className="d-flex justify-content-between"
                css={css`
                background: None;
                color: hsla(61deg, 30%, 20%, 0.78);
            `}
            >
                <div className='d-flex flex-nowrap align-items-center'>
                    <div className='me-2'
                        css={css`
                        background: hsla(61deg, 30%, 99%, 0.35); 
                        color: hsla(61deg, 30%, 20%, 0.65);
                        padding: 2px; 
                        border: 1px solid hsla(61deg, 60%, 30%, 0.25);
                        border-radius: 3px;
                        `}
                    >
                        {score?.playMode === PLAY_MODE.WAIT ? <IconWaitMode /> : <IconConstantMode />}
                    </div>
                    <strong>Score - {score?.playMode} mode</strong>
                </div>
            </ToastHeader>
            <ToastBody id='score-message'>
                <h2>{Math.round(score?.asPercentage())}%</h2>
                <p><strong>{score?.numNotesCorrect}</strong> notes correct out of <strong>{score?.numNotesEncountered}</strong> encountered.</p>
            </ToastBody>
        </Toast >
    )

}
