/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { IconContext } from "react-icons";
import {
    CgArrowLongRight as ArrowRight,
} from 'react-icons/cg'
import {
    IoPlaySharp as Play,
    IoStopSharp as Stop,
    IoPlaySkipBackSharp as Reset,
    IoCloseOutline as Close,
    IoSyncOutline as Refresh
} from 'react-icons/io5'
import {
    FaCircleNotch as Spinner,
    FaMinus as Minus,
    FaPlus as Plus,
} from 'react-icons/fa';
import {
    BsEyeFill as Eye,
    BsEyeSlashFill as EyeSlash,
    BsHourglass as Hourglass,
} from 'react-icons/bs'


const Wrapper = ({ size, style, children, ...otherProps }) => (
    <IconContext.Provider
        value={{
            size: size || 30,
            style: style
        }}>
        <div
            css={css`
            display: flex;
            align-items: center;
    `}
            {...otherProps}
        >
            {children}
        </div>
    </IconContext.Provider>
);

export const IconClose = (props) => <Wrapper {...props}><Close /></Wrapper>
export const IconHide = (props) => <Wrapper {...props}><EyeSlash /></Wrapper>
export const IconMinus = (props) => <Wrapper {...props}> <Minus /></Wrapper >
export const IconPlay = (props) => <Wrapper {...props}><Play /></Wrapper>
export const IconPlus = (props) => <Wrapper {...props}><Plus /></Wrapper>
export const IconRefresh = (props) => <Wrapper {...props}><Refresh /></Wrapper>
export const IconReset = (props) => <Wrapper {...props}><Reset /></Wrapper>
export const IconShow = (props) => <Wrapper {...props}><Eye /></Wrapper>
export const IconStop = (props) => <Wrapper {...props}><Stop /></Wrapper>

export const IconWaitMode = (props) => <Wrapper {...props}><Hourglass /></Wrapper>
export const IconConstantMode = (props) => <Wrapper {...props}><ArrowRight /></Wrapper>

export const IconSpinner = (props) => (
    <Wrapper {...props}>
        <Spinner
            css={css`
        @keyframes icon-spin {
            0% {transform: rotate(0deg) scale(0.72);}
            100% {transform: rotate(359deg) scale(0.72);}
        }
        animation: icon-spin 3s infinite linear;
    `}
        />
    </Wrapper>
)
