// a press (or release) of a piano key (or a 'virtual press' for keys played by the audio player)
// used for both note-on and note-off events
// also used for hint keys

export class KeyEvent {

    static trackIndexRH = 0
    static trackIndexLH = 1

    constructor(pitchMidi, { hand = '', trackIndex = null, isCorrect = true } = {}) {
        this.pitchMidi = pitchMidi
        this._hand = hand  // LH or RH (or blank)
        this.trackIndex = trackIndex
        this.isCorrect = isCorrect
    }

    get hand() {
        if (!!this._hand) {
            return this._hand
        }
        else if (this.trackIndex !== null) {
            return this.trackIndex === KeyEvent.trackIndexLH ? 'LH' : 'RH'
        }
        return ''
    }

}
