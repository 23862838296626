import { plainToGlyph } from "../music-util/glyphs"
import { PlaybackPointCountIn } from "./PlaybackPoint"


// A PlaybackMap is a representation of a notated piece but with repeats and jumps (dal segno, etc.) expanded.
// It's not quite like a performance of a piece (in the way that midi is) because trills, tremolos, etc. are
// still in their notated form (i.e. not the many trill notes which are actually played). It's made up of many
// PlaybackPoints.

export class PlaybackMap {

    constructor() {
        this.points = []
        this.ppq = null
        this.tempoMap = []
    }

    // all 'note' playback points - excluding barline ones, countins, etc.
    get notePoints() {
        return this.points.filter(p => p.type === 'note')
    }

    addPoints(points) {
        const all = this.points.concat(points)
        const sorted = all.sort((a, b) => a.beat - b.beat)
        this.points = sorted
    }

    applyMidiHeaderData(midiHeader) {
        // store ppq and tempos from the midi header data
        this.ppq = midiHeader.ppq
        this.tempoMap = midiHeader.tempos.map(
            ({ bpm, ticks }) => ({ bpm, beat: ticks / this.ppq })
        )
    }

    matchWithMidiNotes(midiComposition) {

        const doMatching = () => {
            let noteCount = 0, unmatched = []
            for (let point of this.notePoints) {
                for (let note of point.pitches) {
                    if (note.tiedTo) {
                        continue
                    }
                    noteCount++
                    const track = note.staveBar.id === 'P1/2' ? midiComposition.trackLH : midiComposition.trackRH  // ???phys rely on this?
                    const matchingMidiEvent = track.findMatch(point.tickNotation, note.midi)
                    if (matchingMidiEvent) {
                        point.midiEvents.push(matchingMidiEvent)
                        matchingMidiEvent.playbackPoint = point
                        matchingMidiEvent.pitchClass = plainToGlyph(note.pitchClass)
                    }
                    else {
                        unmatched.push({ tick: point.tickNotation, midi: note.midi })
                        if (noteCount > 40 && unmatched.length / noteCount > 0.8) {
                            break  // this is going very badly so early exit..
                        }
                    }
                }
            }
            return { noteCount, unmatched }
        }

        const { noteCount, unmatched } = doMatching()
        const tooManyUnmatched = noteCount > 40 && unmatched.length / noteCount > 0.8
        if (tooManyUnmatched) {
            console.log('⚠️ Many unmatched points - try flipping the midi tracks...')
            midiComposition.clearPlaybackPoints()
            midiComposition.flipTracks()
            doMatching()
        }

        midiComposition.findPitchesForUnmatchedEvents(this)
    }

    calcTimesAndTicks(midiComposition) {
        this.points.forEach(point => {
            point.timeNotationMs = this.getTimeAtBeatMs(point.beat)
            point.tickNotation = point.beat * this.ppq
        })

        this.matchWithMidiNotes(midiComposition)

        this.points.forEach(point => {
            if (point.type === 'note') {
                point.tickPerformance = point.midiEvents.length > 0
                    ? point.midiEvents.reduce((sum, e) => (sum + e.ticks), 0) / point.midiEvents.length
                    : point.tickNotation
            }
            else {
                point.tickPerformance = point.tickNotation
            }
            point.timePerformanceMs = this.getTimeAtTick(point.tickPerformance)
        })

        this.setExpectedPitches(midiComposition)
    }

    setExpectedPitches(midiComposition) {

        function continuingPitchesForTrack(midiTrack, playbackPoint) {
            const midiPitchesContinuing = midiTrack.events
                .filter(e => e.ticks < playbackPoint.tick && e.ticks + e.durationTicks > playbackPoint.tick)
                .map(e => e.midi)
            return midiPitchesContinuing
        }

        this.points
            .filter(point => point.type === 'note')
            .forEach(point => {
                point.expectedPitchesRH = point.pitchesRH.filter(n => !n.tiedTo)
                point.continuingPitchesRH = continuingPitchesForTrack(midiComposition.trackRH, point)
                point.expectedPitchesLH = point.pitchesLH.filter(n => !n.tiedTo)
                point.continuingPitchesLH = continuingPitchesForTrack(midiComposition.trackLH, point)
                point.expectedPitches = point.expectedPitchesRH.concat(point.expectedPitchesLH)
                point.continuingPitches = point.continuingPitchesRH.concat(point.continuingPitchesLH)
            })
    }

    getTimeAtBeatMs(beat) {
        // Convert a qn-in-composition location to milliseconds-in-composition using the tempo_map.

        // Nb. to convert a duration_qn, qn_to_secs(note.duration_qn) will not always work correctly as it will not be
        // using the correct point in the tempo map (i.e. if there are any tempo changes between start of composition and
        // end of the note). Instead you should use:
        // duration_in_secs = qn_to_secs(note.start_qn + note.duration_qn) - qn_to_secs(note.start_qn)

        let secs = 0.0
        let prevPosition = 0.0
        let currBpm = this.tempoMap[0].bpm
        for (let tempo of this.tempoMap) {
            const beats_at_curr_tempo = Math.min(tempo.beat, beat) - prevPosition
            const secs_at_curr_tempo = beats_at_curr_tempo * 60 / currBpm
            secs += secs_at_curr_tempo
            if (tempo.beat >= beat) {
                return secs * 1000
            }
            prevPosition = tempo.beat
            currBpm = tempo.bpm
        }

        // # still here so the given beat is after the final tempo change
        const beats_at_curr_tempo = beat - prevPosition
        const secs_at_curr_tempo = beats_at_curr_tempo * 60 / currBpm
        secs += secs_at_curr_tempo
        return secs * 1000
    }

    // Convert a milliseconds-in-composition to beats-in-composition using the tempo map.
    getBeatAtTimeMs(timeInCompositionMs) {

        const timeInCompositionSecs = timeInCompositionMs / 1000
        let currTempo = { beat: 0.0, bpm: this.tempoMap[0].bpm }  // always regard first tempo as starting at zero
        let currTempoPosSecs = 0.0

        const subsequentTempos = this.tempoMap.slice(1)
        for (let nextTempo of subsequentTempos) {
            const nextTempoPosSecs = currTempoPosSecs + (nextTempo.beat - currTempo.beat) / currTempo.bpm * 60
            if (nextTempoPosSecs > timeInCompositionSecs) {
                // the 'target' time falls within the span of the CURRENT tempo, so quit the loop
                break
            }
            currTempo = nextTempo
            currTempoPosSecs = nextTempoPosSecs
        }
        const secs_at_curr_tempo = timeInCompositionSecs - currTempoPosSecs
        const beats_at_curr_tempo = secs_at_curr_tempo * currTempo.bpm / 60
        const beat = currTempo.beat + beats_at_curr_tempo
        return beat
    }

    getTickAtTimeMs(timeInCompositionMs) {
        return this.getTickAtBeat(this.getBeatAtTimeMs(timeInCompositionMs))
    }

    getTimeAtTick(tick) {
        return this.getTimeAtBeatMs(this.getBeatAtTick(tick))
    }

    getTickAtBeat(beat) {
        return Math.round(beat * this.ppq)
    }

    getBeatAtTick(tick) {
        return tick / this.ppq
    }

    // add a count-in point at the start - based on tempo, time sig and if there's a pick-up bar
    addCountInPointAtStart(minCountInMs) {
        const firstTempo = this.tempoMap[0].bpm
        const minCountInBeats = minCountInMs / 1000 * firstTempo / 60
        const firstBar = this.points[0].bar
        const firstBarLength = firstBar.lengthInBeats
        const fullBarLength = firstBar.timeSig_.barLengthQn
        let countInBeats = 0
        countInBeats += (fullBarLength - firstBarLength)

        const shortByBeats = minCountInBeats - countInBeats
        const numBarsMore = Math.ceil(shortByBeats / fullBarLength)
        countInBeats += numBarsMore * fullBarLength

        const firstPoint = this.points[0]
        const countInPoint = new PlaybackPointCountIn(-countInBeats, firstPoint.noteX, firstPoint.bar)
        this.addPoints([countInPoint])
        return countInPoint
    }

    // Returns start beat for a count-in from an arbitrary point in the piece (used in constant mode)
    calcCountInBeat(playFromPoint, minCountInMs) {
        const countInBeat = this.getBeatAtTimeMs(playFromPoint.timeNotationMs - minCountInMs)
        return Math.round(countInBeat)
    }

    get length() {
        return this.points.length
    }

    pointAtIndex(i) {
        return this.points[i]
    }

    get pointAtEnd() {
        return this.points.at(-1)
    }

    // 'Overlay' means the clickable area which user clicks to move cursor to this playback point.
    setOverlayWidths() {
        this.points.forEach((point, i, pMap) => {
            if (point.type === 'barline') {
                return
            }
            const nextPoint = pMap[i + 1]
            if (nextPoint.bar !== point.bar) {
                // if new bar then just go to right barline
                point.overlayW = point.bar.pos.x + point.bar.pos.w - point.overlayX
            }
            else {
                // same bar so go up to next note
                point.overlayW = nextPoint.noteX - point.overlayX
            }
        })
    }

    // Returns the index of the playback point which contains the given tick
    // i.e. the latest point where "point's tick" <= given tick
    // "point's tick" :- pass in param to base on notation or performance tick
    getPointIndexForTick(tick, { byNotationOrPerformance = 'performance' } = {}) {
        let i = 0, j = this.points.length - 1
        let t = 0

        while (i <= j && t < 999999) {
            const mid = Math.floor((j + i) / 2)
            const point = this.points[mid]
            const nextPoint = this.points[mid + 1]
            if (!nextPoint) {
                return mid  // we're looking at last point in playback map, so it 'contains' given tick (success)
            }

            const tickPoint = byNotationOrPerformance === 'performance' ? point.tickPerformance : point.tickNotation
            const tickNextPoint = byNotationOrPerformance === 'performance' ? nextPoint.tickPerformance : nextPoint.tickNotation
            if (tickPoint <= tick && tickNextPoint > tick) {
                return mid  // success
            }
            else if (tickPoint > tick) {
                j = mid - 1
            }
            else {
                i = mid + 1
            }
            t++
        }

        return -1  // failure (given tick was before first point's tick)
    }

    // Pass in a beat (number, zero based and over the whole piece),
    // returns the index of the event from the playback map which contains the given beat.
    getPointIndexForBeat(beat, { byNotationOrPerformance = 'performance' } = {}) {
        const tick = this.getTickAtBeat(beat)
        const i = this.getPointIndexForTick(tick, { byNotationOrPerformance })
        if (i < 0) {
            console.log(`❌ beat (${beat}) out of range (piece goes from beat ${this.points[0].beat})`)
        }
        return i
    }

    getPointForBeat(beat, { byNotationOrPerformance = 'performance' } = {}) {
        const i = this.getPointIndexForBeat(beat, { byNotationOrPerformance })
        return this.points[i]
    }

    // Try to match up a midi note with note in the playbackMap (the playback map has correct spellings, whereas midi
    // may have incorrect spellings).
    // 
    // Most midi events are now carefully matched to playback points, but some may not be - e.g. notes which continue
    // a trill (obviously such notes are not notated, so not in the playback map which is based on musicXML).
    // 
    // Search for a matching pitch on or before the given tick, then search for a matching pitch after. 
    // Return whichever is closer in position to the given tick.

    findPitchClass(tick, midi) {
        if (!this.ppq) {
            throw new Error(`Can't find playbackMap point by tick when playbackMap.ppq is not set.`)
        }

        const tryPoint = pointIndex => {
            const point = this.points[pointIndex]
            const note = point.pitches?.find(n => n.midi === midi)
            return { note, point }
        }

        const beat = tick / this.ppq
        const i = this.getPointIndexForBeat(beat, { byNotationOrPerformance: 'performance' })

        let matchBeforeNote, matchBeforePoint
        for (let j = i; j >= 0; j--) {
            const { note, point } = tryPoint(j)
            if (note) {
                matchBeforeNote = note
                matchBeforePoint = point
                break
            }
        }

        if (matchBeforePoint?.tick === tick) {
            // Found exact tick (and pitch) match so no need to continue
            return matchBeforeNote.pitchClass
        }

        let matchAfterNote, matchAfterPoint
        for (let k = i + 1; k < this.points.length; k++) {
            const { note, point } = tryPoint(k)
            if (note) {
                matchAfterNote = note
                matchAfterPoint = point
                break
            }
        }

        // If found both then return the closest tick-wise
        if (matchBeforeNote && matchAfterNote) {
            if (tick - matchBeforePoint.tick < matchAfterPoint.tick - tick) {
                return matchBeforeNote.pitchClass
            }
            else {
                return matchAfterNote.pitchClass
            }
        }

        // Else return whichever we found
        return matchBeforeNote?.pitchClass || matchAfterNote?.pitchClass
    }

}