import { SvgLayerUser } from "./SvgLayerUser";

const COLOR_CORRECT_NOTE = 'hsl(130deg, 100%, 40%)'
const COLOR_MISSING_NOTE = 'red'
const HIGHLIGHT_X_OFFSET = -1
const HIGHLIGHT_WIDTH_MULTIPLIER = 4.5
const ADD_H_SPACE = 2
const ADD_H_LINE = 3

class NotationHighlighter extends SvgLayerUser {

    constructor(playbackManager) {
        super(playbackManager, 'svg-notation-highlight')
    }

    highlightNotes(point, staveIndex, correctNotes, missingNotes) {
        for (const n of correctNotes) {
            this.highlightNoteHead(point, staveIndex, n, COLOR_CORRECT_NOTE)
        }
        for (const n of missingNotes) {
            this.highlightNoteHead(point, staveIndex, n, COLOR_MISSING_NOTE)
        }
    }

    async highlightNoteHead(point, staveIndex, note, color) {
        this.currPageId = point.bar.page
        if (!this.svgElts.length) {
            await this.setupSvgElts()
        }
        const svgns = "http://www.w3.org/2000/svg"

        const stave = staveIndex === 0 ? point.bar.staves['P1/1'] : point.bar.staves['P1/2'] // ???phys awkward way to get stave
        const stepHeight = stave.pos.height / 8  // ??? calc once per stave? i.e. not here
        const isInASpace = !!(note.stepsBelowTopLine % 2)
        const cy = stave.pos.y + note.stepsBelowTopLine * stepHeight
        const noteHeadElt = document.createElementNS(svgns, "rect")
        noteHeadElt.setAttribute("x", point.noteX + HIGHLIGHT_X_OFFSET)
        noteHeadElt.setAttribute("y", cy - stepHeight + (isInASpace ? -0.5 : -1))
        noteHeadElt.setAttribute("width", stepHeight * HIGHLIGHT_WIDTH_MULTIPLIER)
        noteHeadElt.setAttribute("height", stepHeight * 2 + (isInASpace ? ADD_H_SPACE : ADD_H_LINE))
        noteHeadElt.setAttribute("fill", color)

        this.svgElts[this.currPageId].appendChild(noteHeadElt)

    }

}


export { NotationHighlighter }
