import { Chord } from './Chord'
import { Bar } from './Bar'
import { StaveBar } from './StaveBar'
import { round } from '../logic/util'
import { Piece } from './Piece'
import { Voice } from './Voice'
import { PlaybackMap } from '../playback/PlaybackMap'
import { PlaybackPoint, PlaybackPointBarline } from '../playback/PlaybackPoint'

export const prepPiece = (pieceJson, midiLengthQn) => {

    let timeSig
    let pages = [], barsOnPage = [], pageIndex = 0
    let barQnIndex = 0

    const piece = new Piece(pieceJson)

    pieceJson.bars.forEach((barJson, barIndex) => {

        if (barJson.newPage && barIndex > 0) {
            pages.push(barsOnPage)
            barsOnPage = []
            pageIndex++
        }
        const bar = new Bar(barJson, pageIndex)
        piece.appendBar(bar)
        barsOnPage.push(bar)

        timeSig = bar.timeSig || timeSig
        let barCompositeRhythm = new Map()  // todo: put more functionality in the classes (Bar, etc.)
        let beatsInBar = 0

        Object.entries(barJson.staves).forEach(([staveId, staveJson]) => {

            // if clef not in staveJson then get the most recent one for this stave Id
            let { clef } = staveJson
            for (let i = piece.bars.length - 2; i >= 0 && !clef; i--) {
                clef = piece.bars[i].staves[staveId].clef
            }

            const staveBar = new StaveBar(staveId, staveJson, bar)
            bar.appendStave(staveId, staveBar)

            staveJson.voices.forEach(voiceJson => {
                const voice = new Voice(voiceJson, staveBar)
                staveBar.appendVoice(voice)
                let startsOnBeat = 0  // within bar - need to reset for each voice

                voiceJson.forEach((chordJson) => {
                    let chord = new Chord({
                        ...chordJson,
                        clef,
                        timeSig,
                        startsOnBeat,
                        qnIndex: barQnIndex + startsOnBeat,  // qn index in *notation* (i.e. only a single value for each note, whereas each note-on-the-page might have >1 qn position in the playback map if there are repeats)
                        staveBar
                    })
                    voice.appendChord(chord)

                    if (chord.x !== undefined) {     // rests from musescore xml might not have an x - ignore
                        let playbackPoint = barCompositeRhythm.get(chord.startsOnBeat)
                        if (!playbackPoint) {
                            playbackPoint = new PlaybackPoint(
                                chord.startsOnBeat,          // qn in piece performance (i.e. accounting for repeats/jumps)
                                barQnIndex + startsOnBeat,   // qn 'on paper' (i.e. ignoring repeats/jumps)
                                bar.pos.x + chord.x,
                                bar
                            )
                            barCompositeRhythm.set(chord.startsOnBeat, playbackPoint)
                        }
                        playbackPoint.addPitches(chord.pitches)
                    }
                    startsOnBeat = round(startsOnBeat + chord.durationInBeats)
                })
                // All voices *should* have the same number of beats, but just in case we'll track
                // the one with most.
                beatsInBar = Math.max(beatsInBar, startsOnBeat)
            })
        })

        bar.lengthInBeats = beatsInBar
        barQnIndex += beatsInBar

        const barCompositeRhythmSorted = [...barCompositeRhythm.values()]
            .sort((a, b) => a.beat - b.beat)

        bar.compositeRhythm = barCompositeRhythmSorted
    })

    piece.playbackMap = setupPlaybackMap(piece.bars, midiLengthQn)
    // ..a map of beat-in-piece (zero-based) to x position for every note/rest in the piece
    // (in all voices)

    pages.push(barsOnPage)
    piece.pages = pages         // each item is an array of the bars on that page

    return piece
}



function setupPlaybackMap(bars, midiLengthQn) {

    // Neither Sibelius (8.5) nor MuseScore (3.6) indicate in musicxml whether repeats should be observed on DS/DC.
    // We'll try to infer this by comparing the length of the map with the length of the midi. (Not a very nice way
    // but I can't see any alternative.)
    // First, do a map WITH repeats on DS/DC (because this is Sibelius's default, and BollyPiano use Sibelius):
    let map = mapBars({ bars, ignoreRepeatsOnDcDs: false })

    // If there's a significant discrepancy in the number of beats then try again WITHOUT repeats in DC/DS:
    const beatsError = map.nextBeat - midiLengthQn
    if (beatsError > 4) {
        const map2 = mapBars({ bars, ignoreRepeatsOnDcDs: true })

        // Use the result which is closest to the midi length:
        const beatsError2 = map2.nextBeat - midiLengthQn
        if (Math.abs(beatsError2) < Math.abs(beatsError)) {
            map = map2
        }
    }
    const { playbackMap, nextBeat } = map
    // console.log(`Using map with ${map.ignoreRepeatsOnDcDs ? 'NO' : 'YES'} repeats on DC/DS (map length: ${nextBeat}; midi length: ${midiLengthQn})`)

    playbackMap.addPoints([calcPointAtRightBarline(playbackMap.pointAtEnd.bar, nextBeat)])  // cursor should go to barline at end of piece
    playbackMap.setOverlayWidths()
    return playbackMap

    function mapBars({
        bars,
        fromBeat = 0,
        inRepeatNum,
        ignoreRepeats = false,
        ignoreRepeatsOnDcDs = false,
        barAfterRepeat,    // when mapping repeated bars, we need *following* bar (is it new system?)
        dcOrDs = false
    }) {

        let playbackMap = new PlaybackMap()
        let nextBeat = fromBeat

        let repeatFromBarIndex = 0
        let repeatNum = inRepeatNum
        let firstTimeEndingBarIndex = null

        let segnoBarIndex = 0
        let toCodaBarIndex = null
        let fineBarIndex = null

        bars.forEach((bar, barIndex) => {

            if (bar.leftBarLine === 'repeat' && !inRepeatNum && !ignoreRepeats) {
                repeatFromBarIndex = barIndex
                repeatNum = 1
            }
            if (bar.ending === '1') {
                firstTimeEndingBarIndex = barIndex
            }
            if (bar.segno && !dcOrDs) {
                segnoBarIndex = barIndex
            }
            if (bar.toCoda && !dcOrDs) {
                // nb. in musicxml 'to coda' is attached to the bar at the END of which you should jump to the coda.
                // (At least, this is how Sibelius handles it - I can't see anything in the musicxml docs which state this one
                // way or the other.)
                toCodaBarIndex = barIndex
            }
            if (bar.fine && !dcOrDs) {
                fineBarIndex = barIndex
            }
            const isFinalRepeat = inRepeatNum > 1  // ??? atm only handles 'normal' 2x repeats
            playbackMap.addPoints(
                bar.compositeRhythm.map(p => {
                    let newPoint = p.clone()
                    newPoint.beat = nextBeat + p.beat
                    newPoint.overlayX = p.beat === 0 ? bar.pos.x : p.noteX   // 1st beat in bar -> overlay starts at left barline
                    if (repeatNum) {
                        newPoint.repeatNum = repeatNum
                    }
                    if (dcOrDs) {
                        newPoint.dcOrDs = dcOrDs
                    }
                    return newPoint
                })
            )

            nextBeat += bar.lengthInBeats

            const nextBarOnPage = bars[barIndex + 1] || barAfterRepeat
            // If next bar to play is not immediately to the right of current bar then must ensure cursor
            // goes to barline before jumping. So add a 'dummy' playback point for the end of the bar in
            // the following circumstances:
            const isEndOfSystem = nextBarOnPage?.newSystem
            const aboutToRepeat = bar.rightBarLine === 'repeat' && !isFinalRepeat
            const aboutToDcOrDs = bar.daCapo || bar.dalSegno
            if (isEndOfSystem || aboutToRepeat || aboutToDcOrDs) {
                playbackMap.addPoints([calcPointAtRightBarline(bar, nextBeat)])
            }

            // If we're at a right-hand repeat bar then will need to add the repeated bars to the map...
            if (bar.rightBarLine === 'repeat' && (repeatFromBarIndex !== null) && !ignoreRepeats) {
                const repeatToBar = firstTimeEndingBarIndex ? firstTimeEndingBarIndex - 1 : barIndex
                const barsToRepeat = bars.slice(repeatFromBarIndex, repeatToBar + 1)
                repeatNum++
                const res = mapBars({
                    bars: barsToRepeat,
                    fromBeat: nextBeat,
                    inRepeatNum: repeatNum,
                    ignoreRepeats: true,
                    ignoreRepeatsOnDcDs,
                    barAfterRepeat: nextBarOnPage,
                    dcOrDs
                })
                playbackMap.addPoints(res.playbackMap.points)

                nextBeat = res.nextBeat
                const nextBarToPlay = bars[repeatToBar + 1]
                if (nextBarToPlay?.ending === '1') {
                    // put a point at the barline where the first ending starts (so cursor will skip it cleanly)
                    playbackMap.addPoints([calcPointAtRightBarline(bars[repeatToBar], nextBeat)])
                }

                repeatNum = null
                repeatFromBarIndex = null
                firstTimeEndingBarIndex = null
            }

            // Handle DC or DS - but only if we are not already *in* a DC/DS, nor in a repeat (e.g. if DS is on a bar
            // which is also the end of a repeated section).
            if ((bar.daCapo || bar.dalSegno) && !dcOrDs && !ignoreRepeats) {
                const barsToRepeat = bars.slice(segnoBarIndex, (toCodaBarIndex || fineBarIndex) + 1)
                const res = mapBars({
                    bars: barsToRepeat,
                    fromBeat: nextBeat,
                    ignoreRepeats: ignoreRepeatsOnDcDs,
                    dcOrDs: bar.dalSegno ? 'DS' : 'DC'
                })
                playbackMap.addPoints(res.playbackMap.points)

                nextBeat = res.nextBeat
                if (toCodaBarIndex) {  // don't need to do dummy point if it's the Fine
                    playbackMap.addPoints([calcPointAtRightBarline(bars[toCodaBarIndex], nextBeat)])
                }
            }

        })

        return { playbackMap, nextBeat, ignoreRepeatsOnDcDs }
    }

    function calcPointAtRightBarline(bar, beat) {
        return new PlaybackPointBarline(beat, bar.pos.x + bar.pos.w, bar)
    }

}